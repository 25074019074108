@charset "UTF-8";
.icon-ss {
    font-size: 0.5rem !important; }

.icon-xs {
    font-size: 0.65rem !important; }

.icon-sm {
    font-size: 0.75rem !important; }

.icon-nm {
    font-size: 0.9rem !important; }

.icon-ms {
    font-size: 1.15rem !important; }

.icon-md {
    font-size: 1.25rem !important; }

.icon-lg {
    font-size: 1.5rem !important; }

.icon-xl {
    font-size: 1.75rem !important; }

.icon-xxl {
    font-size: 2.25rem !important; }

.icon-1x {
    font-size: 1rem !important; }

.icon-2x {
    font-size: 2rem !important; }

.icon-3x {
    font-size: 3rem !important; }

.icon-4x {
    font-size: 4rem !important; }

.icon-5x {
    font-size: 5rem !important; }

.icon-6x {
    font-size: 6rem !important; }

.icon-7x {
    font-size: 7rem !important; }

.icon-8x {
    font-size: 8rem !important; }

.icon-9x {
    font-size: 9rem !important; }

.icon-10x {
    font-size: 10rem !important; }

@media (min-width: 576px) {
    .icon-sm-ss {
        font-size: 0.5rem !important; }
    .icon-sm-xs {
        font-size: 0.65rem !important; }
    .icon-sm-sm {
        font-size: 0.75rem !important; }
    .icon-sm-nm {
        font-size: 0.9rem !important; }
    .icon-sm-ms {
        font-size: 1.15rem !important; }
    .icon-sm-md {
        font-size: 1.25rem !important; }
    .icon-sm-lg {
        font-size: 1.5rem !important; }
    .icon-sm-xl {
        font-size: 1.75rem !important; }
    .icon-sm-xxl {
        font-size: 2.25rem !important; }
    .icon-sm-1x {
        font-size: 1rem !important; }
    .icon-sm-2x {
        font-size: 2rem !important; }
    .icon-sm-3x {
        font-size: 3rem !important; }
    .icon-sm-4x {
        font-size: 4rem !important; }
    .icon-sm-5x {
        font-size: 5rem !important; }
    .icon-sm-6x {
        font-size: 6rem !important; }
    .icon-sm-7x {
        font-size: 7rem !important; }
    .icon-sm-8x {
        font-size: 8rem !important; }
    .icon-sm-9x {
        font-size: 9rem !important; }
    .icon-sm-10x {
        font-size: 10rem !important; } }

@media (min-width: 768px) {
    .icon-md-ss {
        font-size: 0.5rem !important; }
    .icon-md-xs {
        font-size: 0.65rem !important; }
    .icon-md-sm {
        font-size: 0.75rem !important; }
    .icon-md-nm {
        font-size: 0.9rem !important; }
    .icon-md-ms {
        font-size: 1.15rem !important; }
    .icon-md-md {
        font-size: 1.25rem !important; }
    .icon-md-lg {
        font-size: 1.5rem !important; }
    .icon-md-xl {
        font-size: 1.75rem !important; }
    .icon-md-xxl {
        font-size: 2.25rem !important; }
    .icon-md-1x {
        font-size: 1rem !important; }
    .icon-md-2x {
        font-size: 2rem !important; }
    .icon-md-3x {
        font-size: 3rem !important; }
    .icon-md-4x {
        font-size: 4rem !important; }
    .icon-md-5x {
        font-size: 5rem !important; }
    .icon-md-6x {
        font-size: 6rem !important; }
    .icon-md-7x {
        font-size: 7rem !important; }
    .icon-md-8x {
        font-size: 8rem !important; }
    .icon-md-9x {
        font-size: 9rem !important; }
    .icon-md-10x {
        font-size: 10rem !important; } }

@media (min-width: 992px) {
    .icon-lg-ss {
        font-size: 0.5rem !important; }
    .icon-lg-xs {
        font-size: 0.65rem !important; }
    .icon-lg-sm {
        font-size: 0.75rem !important; }
    .icon-lg-nm {
        font-size: 0.9rem !important; }
    .icon-lg-ms {
        font-size: 1.15rem !important; }
    .icon-lg-md {
        font-size: 1.25rem !important; }
    .icon-lg-lg {
        font-size: 1.5rem !important; }
    .icon-lg-xl {
        font-size: 1.75rem !important; }
    .icon-lg-xxl {
        font-size: 2.25rem !important; }
    .icon-lg-1x {
        font-size: 1rem !important; }
    .icon-lg-2x {
        font-size: 2rem !important; }
    .icon-lg-3x {
        font-size: 3rem !important; }
    .icon-lg-4x {
        font-size: 4rem !important; }
    .icon-lg-5x {
        font-size: 5rem !important; }
    .icon-lg-6x {
        font-size: 6rem !important; }
    .icon-lg-7x {
        font-size: 7rem !important; }
    .icon-lg-8x {
        font-size: 8rem !important; }
    .icon-lg-9x {
        font-size: 9rem !important; }
    .icon-lg-10x {
        font-size: 10rem !important; } }

@media (min-width: 1200px) {
    .icon-xl-ss {
        font-size: 0.5rem !important; }
    .icon-xl-xs {
        font-size: 0.65rem !important; }
    .icon-xl-sm {
        font-size: 0.75rem !important; }
    .icon-xl-nm {
        font-size: 0.9rem !important; }
    .icon-xl-ms {
        font-size: 1.15rem !important; }
    .icon-xl-md {
        font-size: 1.25rem !important; }
    .icon-xl-lg {
        font-size: 1.5rem !important; }
    .icon-xl-xl {
        font-size: 1.75rem !important; }
    .icon-xl-xxl {
        font-size: 2.25rem !important; }
    .icon-xl-1x {
        font-size: 1rem !important; }
    .icon-xl-2x {
        font-size: 2rem !important; }
    .icon-xl-3x {
        font-size: 3rem !important; }
    .icon-xl-4x {
        font-size: 4rem !important; }
    .icon-xl-5x {
        font-size: 5rem !important; }
    .icon-xl-6x {
        font-size: 6rem !important; }
    .icon-xl-7x {
        font-size: 7rem !important; }
    .icon-xl-8x {
        font-size: 8rem !important; }
    .icon-xl-9x {
        font-size: 9rem !important; }
    .icon-xl-10x {
        font-size: 10rem !important; } }

@media (min-width: 1400px) {
    .icon-xxl-ss {
        font-size: 0.5rem !important; }
    .icon-xxl-xs {
        font-size: 0.65rem !important; }
    .icon-xxl-sm {
        font-size: 0.75rem !important; }
    .icon-xxl-nm {
        font-size: 0.9rem !important; }
    .icon-xxl-ms {
        font-size: 1.15rem !important; }
    .icon-xxl-md {
        font-size: 1.25rem !important; }
    .icon-xxl-lg {
        font-size: 1.5rem !important; }
    .icon-xxl-xl {
        font-size: 1.75rem !important; }
    .icon-xxl-xxl {
        font-size: 2.25rem !important; }
    .icon-xxl-1x {
        font-size: 1rem !important; }
    .icon-xxl-2x {
        font-size: 2rem !important; }
    .icon-xxl-3x {
        font-size: 3rem !important; }
    .icon-xxl-4x {
        font-size: 4rem !important; }
    .icon-xxl-5x {
        font-size: 5rem !important; }
    .icon-xxl-6x {
        font-size: 6rem !important; }
    .icon-xxl-7x {
        font-size: 7rem !important; }
    .icon-xxl-8x {
        font-size: 8rem !important; }
    .icon-xxl-9x {
        font-size: 9rem !important; }
    .icon-xxl-10x {
        font-size: 10rem !important; } }

.burger-icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none  !important;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background-color: none;
    outline: none !important;
    width: 24px;
    height: 24px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.burger-icon span {
    display: block;
    position: absolute;
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    top: 10px;
    height: 3px;
    min-height: 3px;
    width: 100%; }
.burger-icon span::before, .burger-icon span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    min-height: 3px;
    content: "";
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 3px; }
.burger-icon span::before {
    top: -8px; }
.burger-icon span::after {
    bottom: -8px; }
.burger-icon-left span:before {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: auto;
    right: 0px;
    width: 50%; }
.burger-icon-left span:after {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: auto;
    right: 0px;
    width: 75%; }
.burger-icon-left-active span:before {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: 0px;
    right: auto;
    width: 50%; }
.burger-icon-left-active span:after {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    left: 0px;
    right: auto;
    width: 75%; }
.burger-icon-right span:before {
    left: 0px;
    right: auto;
    width: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.burger-icon-right span:after {
    left: 0px;
    right: auto;
    width: 75%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.burger-icon-right-active span:before {
    left: auto;
    right: 0px;
    width: 50%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.burger-icon-right-active span:after {
    left: auto;
    right: 0px;
    width: 75%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
.burger-icon span {
    background-color: #B5B5C3; }
.burger-icon span::before, .burger-icon span::after {
    background-color: #B5B5C3; }
.burger-icon:hover span {
    background-color: var(--success); }
.burger-icon:hover span::before, .burger-icon:hover span::after {
    background-color: var(--success); }
.burger-icon-active span {
    background-color: var(--success); }
.burger-icon-active span::before, .burger-icon-active span::after {
    background-color: var(--success); }

.svg-icon svg {
    height: 1.5rem;
    width: 1.5rem; }

.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }

.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-icon-white svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }

.svg-icon.svg-icon-white svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-white svg path {
    fill: white !important; }

.svg-icon.svg-icon-primary svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.svg-icon.svg-icon-primary svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-primary svg path {
    fill: var(--success) !important; }

.svg-icon.svg-icon-secondary svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.svg-icon.svg-icon-secondary svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-secondary svg path {
    fill: #181C32 !important; }

.svg-icon.svg-icon-success svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.svg-icon.svg-icon-success svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-success svg path {
    fill: var(--success) !important; }

.svg-icon.svg-icon-info svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF !important; }

.svg-icon.svg-icon-info svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-info svg path {
    fill: #6993FF !important; }

.svg-icon.svg-icon-warning svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800 !important; }

.svg-icon.svg-icon-warning svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-warning svg path {
    fill: #FFA800 !important; }

.svg-icon.svg-icon-danger svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60 !important; }

.svg-icon.svg-icon-danger svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-danger svg path {
    fill: #F64E60 !important; }

.svg-icon.svg-icon-light svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9 !important; }

.svg-icon.svg-icon-light svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-light svg path {
    fill: #F3F6F9 !important; }

.svg-icon.svg-icon-dark svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.svg-icon.svg-icon-dark svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-dark svg path {
    fill: #181C32 !important; }

.svg-icon.svg-icon-dark-75 svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }

.svg-icon.svg-icon-dark-75 svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-dark-75 svg path {
    fill: #3F4254 !important; }

.svg-icon.svg-icon-dark-65 svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278 !important; }

.svg-icon.svg-icon-dark-65 svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-dark-65 svg path {
    fill: #5E6278 !important; }

.svg-icon.svg-icon-dark-50 svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }

.svg-icon.svg-icon-dark-50 svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-dark-50 svg path {
    fill: #7E8299 !important; }

.svg-icon.svg-icon-dark-25 svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0 !important; }

.svg-icon.svg-icon-dark-25 svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-dark-25 svg path {
    fill: #D1D3E0 !important; }

.svg-icon.svg-icon-muted svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3 !important; }

.svg-icon.svg-icon-muted svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.svg-icon.svg-logo-muted svg path {
    fill: #B5B5C3 !important; }

.svg-icon.svg-icon-sm svg {
    height: 1.25rem !important;
    width: 1.25rem !important; }

.svg-icon.svg-icon-md svg {
    height: 1.5rem !important;
    width: 1.5rem !important; }

.svg-icon.svg-icon-lg svg {
    height: 1.75rem !important;
    width: 1.75rem !important; }

.svg-icon.svg-icon-xl svg {
    height: 2rem !important;
    width: 2rem !important; }

.svg-icon.svg-icon-xxl svg {
    height: 2.25rem !important;
    width: 2.25rem !important; }

.svg-icon.svg-icon-1x svg {
    height: 1rem !important;
    width: 1rem !important; }

.svg-icon.svg-icon-2x svg {
    height: 2rem !important;
    width: 2rem !important; }

.svg-icon.svg-icon-3x svg {
    height: 3rem !important;
    width: 3rem !important; }

.svg-icon.svg-icon-4x svg {
    height: 4rem !important;
    width: 4rem !important; }

.svg-icon.svg-icon-5x svg {
    height: 5rem !important;
    width: 5rem !important; }

.svg-icon.svg-icon-6x svg {
    height: 6rem !important;
    width: 6rem !important; }

.svg-icon.svg-icon-7x svg {
    height: 7rem !important;
    width: 7rem !important; }

.svg-icon.svg-icon-8x svg {
    height: 8rem !important;
    width: 8rem !important; }

.svg-icon.svg-icon-9x svg {
    height: 9rem !important;
    width: 9rem !important; }

.svg-icon.svg-icon-10x svg {
    height: 10rem !important;
    width: 10rem !important; }

.svg-icon.svg-icon-full svg {
    height: 100% !important;
    width: 100% !important; }

@media (min-width: 576px) {
    .svg-icon.svg-icon-sm-sm svg {
        height: 1.25rem !important;
        width: 1.25rem !important; }
    .svg-icon.svg-icon-sm-md svg {
        height: 1.5rem !important;
        width: 1.5rem !important; }
    .svg-icon.svg-icon-sm-lg svg {
        height: 1.75rem !important;
        width: 1.75rem !important; }
    .svg-icon.svg-icon-sm-xl svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-sm-xxl svg {
        height: 2.25rem !important;
        width: 2.25rem !important; }
    .svg-icon.svg-icon-sm-1x svg {
        height: 1rem !important;
        width: 1rem !important; }
    .svg-icon.svg-icon-sm-2x svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-sm-3x svg {
        height: 3rem !important;
        width: 3rem !important; }
    .svg-icon.svg-icon-sm-4x svg {
        height: 4rem !important;
        width: 4rem !important; }
    .svg-icon.svg-icon-sm-5x svg {
        height: 5rem !important;
        width: 5rem !important; }
    .svg-icon.svg-icon-sm-6x svg {
        height: 6rem !important;
        width: 6rem !important; }
    .svg-icon.svg-icon-sm-7x svg {
        height: 7rem !important;
        width: 7rem !important; }
    .svg-icon.svg-icon-sm-8x svg {
        height: 8rem !important;
        width: 8rem !important; }
    .svg-icon.svg-icon-sm-9x svg {
        height: 9rem !important;
        width: 9rem !important; }
    .svg-icon.svg-icon-sm-10x svg {
        height: 10rem !important;
        width: 10rem !important; }
    .svg-icon.svg-icon-sm-full svg {
        height: 100% !important;
        width: 100% !important; } }

@media (min-width: 768px) {
    .svg-icon.svg-icon-md-sm svg {
        height: 1.25rem !important;
        width: 1.25rem !important; }
    .svg-icon.svg-icon-md-md svg {
        height: 1.5rem !important;
        width: 1.5rem !important; }
    .svg-icon.svg-icon-md-lg svg {
        height: 1.75rem !important;
        width: 1.75rem !important; }
    .svg-icon.svg-icon-md-xl svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-md-xxl svg {
        height: 2.25rem !important;
        width: 2.25rem !important; }
    .svg-icon.svg-icon-md-1x svg {
        height: 1rem !important;
        width: 1rem !important; }
    .svg-icon.svg-icon-md-2x svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-md-3x svg {
        height: 3rem !important;
        width: 3rem !important; }
    .svg-icon.svg-icon-md-4x svg {
        height: 4rem !important;
        width: 4rem !important; }
    .svg-icon.svg-icon-md-5x svg {
        height: 5rem !important;
        width: 5rem !important; }
    .svg-icon.svg-icon-md-6x svg {
        height: 6rem !important;
        width: 6rem !important; }
    .svg-icon.svg-icon-md-7x svg {
        height: 7rem !important;
        width: 7rem !important; }
    .svg-icon.svg-icon-md-8x svg {
        height: 8rem !important;
        width: 8rem !important; }
    .svg-icon.svg-icon-md-9x svg {
        height: 9rem !important;
        width: 9rem !important; }
    .svg-icon.svg-icon-md-10x svg {
        height: 10rem !important;
        width: 10rem !important; }
    .svg-icon.svg-icon-md-full svg {
        height: 100% !important;
        width: 100% !important; } }

@media (min-width: 992px) {
    .svg-icon.svg-icon-lg-sm svg {
        height: 1.25rem !important;
        width: 1.25rem !important; }
    .svg-icon.svg-icon-lg-md svg {
        height: 1.5rem !important;
        width: 1.5rem !important; }
    .svg-icon.svg-icon-lg-lg svg {
        height: 1.75rem !important;
        width: 1.75rem !important; }
    .svg-icon.svg-icon-lg-xl svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-lg-xxl svg {
        height: 2.25rem !important;
        width: 2.25rem !important; }
    .svg-icon.svg-icon-lg-1x svg {
        height: 1rem !important;
        width: 1rem !important; }
    .svg-icon.svg-icon-lg-2x svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-lg-3x svg {
        height: 3rem !important;
        width: 3rem !important; }
    .svg-icon.svg-icon-lg-4x svg {
        height: 4rem !important;
        width: 4rem !important; }
    .svg-icon.svg-icon-lg-5x svg {
        height: 5rem !important;
        width: 5rem !important; }
    .svg-icon.svg-icon-lg-6x svg {
        height: 6rem !important;
        width: 6rem !important; }
    .svg-icon.svg-icon-lg-7x svg {
        height: 7rem !important;
        width: 7rem !important; }
    .svg-icon.svg-icon-lg-8x svg {
        height: 8rem !important;
        width: 8rem !important; }
    .svg-icon.svg-icon-lg-9x svg {
        height: 9rem !important;
        width: 9rem !important; }
    .svg-icon.svg-icon-lg-10x svg {
        height: 10rem !important;
        width: 10rem !important; }
    .svg-icon.svg-icon-lg-full svg {
        height: 100% !important;
        width: 100% !important; } }

@media (min-width: 1200px) {
    .svg-icon.svg-icon-xl-sm svg {
        height: 1.25rem !important;
        width: 1.25rem !important; }
    .svg-icon.svg-icon-xl-md svg {
        height: 1.5rem !important;
        width: 1.5rem !important; }
    .svg-icon.svg-icon-xl-lg svg {
        height: 1.75rem !important;
        width: 1.75rem !important; }
    .svg-icon.svg-icon-xl-xl svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-xl-xxl svg {
        height: 2.25rem !important;
        width: 2.25rem !important; }
    .svg-icon.svg-icon-xl-1x svg {
        height: 1rem !important;
        width: 1rem !important; }
    .svg-icon.svg-icon-xl-2x svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-xl-3x svg {
        height: 3rem !important;
        width: 3rem !important; }
    .svg-icon.svg-icon-xl-4x svg {
        height: 4rem !important;
        width: 4rem !important; }
    .svg-icon.svg-icon-xl-5x svg {
        height: 5rem !important;
        width: 5rem !important; }
    .svg-icon.svg-icon-xl-6x svg {
        height: 6rem !important;
        width: 6rem !important; }
    .svg-icon.svg-icon-xl-7x svg {
        height: 7rem !important;
        width: 7rem !important; }
    .svg-icon.svg-icon-xl-8x svg {
        height: 8rem !important;
        width: 8rem !important; }
    .svg-icon.svg-icon-xl-9x svg {
        height: 9rem !important;
        width: 9rem !important; }
    .svg-icon.svg-icon-xl-10x svg {
        height: 10rem !important;
        width: 10rem !important; }
    .svg-icon.svg-icon-xl-full svg {
        height: 100% !important;
        width: 100% !important; } }

@media (min-width: 1400px) {
    .svg-icon.svg-icon-xxl-sm svg {
        height: 1.25rem !important;
        width: 1.25rem !important; }
    .svg-icon.svg-icon-xxl-md svg {
        height: 1.5rem !important;
        width: 1.5rem !important; }
    .svg-icon.svg-icon-xxl-lg svg {
        height: 1.75rem !important;
        width: 1.75rem !important; }
    .svg-icon.svg-icon-xxl-xl svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-xxl-xxl svg {
        height: 2.25rem !important;
        width: 2.25rem !important; }
    .svg-icon.svg-icon-xxl-1x svg {
        height: 1rem !important;
        width: 1rem !important; }
    .svg-icon.svg-icon-xxl-2x svg {
        height: 2rem !important;
        width: 2rem !important; }
    .svg-icon.svg-icon-xxl-3x svg {
        height: 3rem !important;
        width: 3rem !important; }
    .svg-icon.svg-icon-xxl-4x svg {
        height: 4rem !important;
        width: 4rem !important; }
    .svg-icon.svg-icon-xxl-5x svg {
        height: 5rem !important;
        width: 5rem !important; }
    .svg-icon.svg-icon-xxl-6x svg {
        height: 6rem !important;
        width: 6rem !important; }
    .svg-icon.svg-icon-xxl-7x svg {
        height: 7rem !important;
        width: 7rem !important; }
    .svg-icon.svg-icon-xxl-8x svg {
        height: 8rem !important;
        width: 8rem !important; }
    .svg-icon.svg-icon-xxl-9x svg {
        height: 9rem !important;
        width: 9rem !important; }
    .svg-icon.svg-icon-xxl-10x svg {
        height: 10rem !important;
        width: 10rem !important; }
    .svg-icon.svg-icon-xxl-full svg {
        height: 100% !important;
        width: 100% !important; } }

.svg-icon.svg-icon-flip svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }

.svg.svg-fill-white {
    fill: white !important; }

.svg.svg-fill-light-white {
    fill: white !important; }

.svg.svg-fill-primary {
    fill: var(--success) !important; }

.svg.svg-fill-light-primary {
    fill: #C9F7F5 !important; }

.svg.svg-fill-secondary {
    fill: #181C32 !important; }

.svg.svg-fill-light-secondary {
    fill: #EBEDF3 !important; }

.svg.svg-fill-success {
    fill: var(--success) !important; }

.svg.svg-fill-light-success {
    fill: #E1F0FF !important; }

.svg.svg-fill-info {
    fill: #6993FF !important; }

.svg.svg-fill-light-info {
    fill: #E1E9FF !important; }

.svg.svg-fill-warning {
    fill: #FFA800 !important; }

.svg.svg-fill-light-warning {
    fill: #FFF4DE !important; }

.svg.svg-fill-danger {
    fill: #F64E60 !important; }

.svg.svg-fill-light-danger {
    fill: #FFE2E5 !important; }

.svg.svg-fill-light {
    fill: #F3F6F9 !important; }

.svg.svg-fill-light-light {
    fill: #F3F6F9 !important; }

.svg.svg-fill-dark {
    fill: #181C32 !important; }

.svg.svg-fill-light-dark {
    fill: #D1D3E0 !important; }

.svg.svg-fill-dark-75 {
    fill: #3F4254 !important; }



.svg.svg-fill-dark-65 {
    fill: #5E6278 !important; }



.svg.svg-fill-dark-50 {
    fill: #7E8299 !important; }



.svg.svg-fill-dark-25 {
    fill: #D1D3E0 !important; }



.svg.svg-fill-muted {
    fill: #B5B5C3 !important; }


.mrimp {
    margin-right: auto !important;
}

.icon-color-gray {
    color: var(--gray) !important;
}

.icon-info-decor:hover i:before {
    color: var(--originaltwo);
}