@font-face {
  font-family: 'all-font';
  src:  url('all-font.eot?x2vkc7');
  src:  url('all-font.eot?x2vkc7#iefix') format('embedded-opentype'),
    url('all-font.ttf?x2vkc7') format('truetype'),
    url('all-font.woff?x2vkc7') format('woff'),
    url('all-font.svg?x2vkc7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'all-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Signed1:before {
  content: "\e914";
}
.icon-dok_pac2:before {
  content: "\ea11";
}
.icon-dok_pac1:before {
  content: "\ea12";
}
.icon-dok_pac:before {
  content: "\ea13";
}
.icon-roll_up_1:before {
  content: "\e900";
}
.icon-roll_up_2:before {
  content: "\e901";
}
.icon-roll_up_3:before {
  content: "\e902";
}
.icon-view_window_2:before {
  content: "\e903";
}
.icon-view_window_3:before {
  content: "\e904";
}
.icon-view_window_4:before {
  content: "\e905";
}
.icon-viewing_from_mobile_1:before {
  content: "\e906";
}
.icon-rules_selection_documents_1:before {
  content: "\e907";
}
.icon-rules_selection_documents_2:before {
  content: "\e908";
}
.icon-saved_reports_1:before {
  content: "\e909";
}
.icon-system_files_1:before {
  content: "\e90a";
}
.icon-view_window_1:before {
  content: "\e90b";
}
.icon-pdf_1:before {
  content: "\e90c";
}
.icon-Quwstion_1:before {
  content: "\e90d";
}
.icon-Quwstion_2:before {
  content: "\e90e";
}
.icon-reports_1:before {
  content: "\e90f";
}
.icon-rghts_to_divisions_1:before {
  content: "\e910";
}
.icon-configurator_1:before {
  content: "\e911";
}
.icon-disclose_1:before {
  content: "\e912";
}
.icon-groups_of_branches_1:before {
  content: "\e913";
}
.icon-info_1:before {
  content: "\e915";
}
.icon-config_history_1:before {
  content: "\e916";
}
.icon-classifiers_1:before {
  content: "\e917";
}
.icon-branches_2:before {
  content: "\e918";
}
.icon-branches_1:before {
  content: "\e919";
}
.icon-attention_1:before {
  content: "\e91a";
}
.icon-Doc_view_5:before {
  content: "\e91b";
}
.icon-branch_1:before {
  content: "\e91c";
}
.icon-change_password:before {
  content: "\e91d";
}
.icon-copy_1:before {
  content: "\e91e";
}
.icon-division_1:before {
  content: "\e91f";
}
.icon-expand_1:before {
  content: "\e920";
}
.icon-group_1:before {
  content: "\e921";
}
.icon-reset_password:before {
  content: "\e922";
}
.icon-Konfigurator:before {
  content: "\e9ea";
}
.icon-Cpravohniki:before {
  content: "\e9eb";
}
.icon-Audit:before {
  content: "\e9ec";
}
.icon-Spravka:before {
  content: "\e9ed";
}
.icon-Otheti:before {
  content: "\e9ee";
}
.icon-c_16:before {
  content: "\e99c";
}
.icon-add_16-1:before {
  content: "\e99d";
}
.icon-add_16:before {
  content: "\e99e";
}
.icon-arm_master_16:before {
  content: "\e99f";
}
.icon-arrow_curve_16:before {
  content: "\e9a0";
}
.icon-attach:before {
  content: "\e9a1";
}
.icon-attribute:before {
  content: "\e9a2";
}
.icon-check_16:before {
  content: "\e9a3";
}
.icon-checked:before {
  content: "\e9a4";
}
.icon-classifier_16:before {
  content: "\e9a5";
}
.icon-classifier_rights_16:before {
  content: "\e9a6";
}
.icon-classifiers_16:before {
  content: "\e9a7";
}
.icon-client_settings_16:before {
  content: "\e9a8";
}
.icon-clock_16:before {
  content: "\e9a9";
}
.icon-collapse_16:before {
  content: "\e9aa";
}
.icon-collapse_17:before {
  content: "\e994";
}
.icon-collapse-expand_16:before {
  content: "\e9ab";
}
.icon-configurator_16:before {
  content: "\e9ac";
}
.icon-console_16:before {
  content: "\e9ad";
}
.icon-cross:before {
  content: "\e9ae";
}
.icon-delete_16:before {
  content: "\e9af";
}
.icon-department:before {
  content: "\e9b0";
}
.icon-dict_history_16:before {
  content: "\e9b1";
}
.icon-doc_16:before {
  content: "\e9b2";
}
.icon-doc_history_16:before {
  content: "\e9b3";
}
.icon-doc_lock_16:before {
  content: "\e9b4";
}
.icon-doc_refs_16:before {
  content: "\e9b5";
}
.icon-docfiletask_16:before {
  content: "\e9b6";
}
.icon-edit_16:before {
  content: "\e9b7";
}
.icon-endsWith_16-1:before {
  content: "\e9b8";
}
.icon-endsWith_16:before {
  content: "\e9b9";
}
.icon-equals_16:before {
  content: "\e9ba";
}
.icon-excel:before {
  content: "\e9bb";
}
.icon-exclamation-shield-frame:before {
  content: "\e9bc";
}
.icon-execute_16:before {
  content: "\e9bd";
}
.icon-exit:before {
  content: "\e9be";
}
.icon-expand_16:before {
  content: "\e9bf";
}
.icon-filial_24:before {
  content: "\e9c0";
}
.icon-filialGroup_16:before {
  content: "\e9c1";
}
.icon-filter_delete:before {
  content: "\e9c2";
}
.icon-filter_value_16:before {
  content: "\e9c3";
}
.icon-filter:before {
  content: "\e9c4";
}
.icon-folder_16:before {
  content: "\e9c5";
}
.icon-folder_17:before {
  content: "\e9a5";
}
.icon-folder_document_16:before {
  content: "\e9c6";
}
.icon-folder_share_16:before {
  content: "\e9c7";
}
.icon-groups:before {
  content: "\e9c8";
}
.icon-guide_16:before {
  content: "\e9c9";
}
.icon-help:before {
  content: "\e9ca";
}
.icon-hmenu-asc:before {
  content: "\e9cb";
}
.icon-hmenu-desc:before {
  content: "\e9cc";
}
.icon-jump_configure_16:before {
  content: "\e9cd";
}
.icon-lessOrEqual-1:before {
  content: "\e9ce";
}
.icon-lessOrEqual:before {
  content: "\e9cf";
}
.icon-like_16:before {
  content: "\e9d0";
}
.icon-log:before {
  content: "\e9d1";
}
.icon-logo16:before {
  content: "\e9d2";
}
.icon-more:before {
  content: "\e9d3";
}
.icon-package_util_16:before {
  content: "\e9d4";
}
.icon-page-first:before {
  content: "\e9d5";
}
.icon-page-last:before {
  content: "\e9d6";
}
.icon-page-next:before {
  content: "\e9d7";
}
.icon-page-prev:before {
  content: "\e9d8";
}
.icon-password_change:before {
  content: "\e9d9";
}
.icon-puzzle_16:before {
  content: "\e9da";
}
.icon-refresh_16:before {
  content: "\e9db";
}
.icon-rights:before {
  content: "\e9dc";
}
.icon-save_16:before {
  content: "\e9dd";
}
.icon-search:before {
  content: "\e9de";
}
.icon-size_height_16-1:before {
  content: "\e9df";
}
.icon-size_height_16:before {
  content: "\e9e0";
}
.icon-size_original_16:before {
  content: "\e9e1";
}
.icon-storedReport_16:before {
  content: "\e9e2";
}
.icon-Subtract1:before {
  content: "\e9e3";
}
.icon-template_16:before {
  content: "\e9e4";
}
.icon-undo_16:before {
  content: "\e9e5";
}
.icon-user:before {
  content: "\e9e6";
}
.icon-users_add:before {
  content: "\e9e7";
}
.icon-users:before {
  content: "\e9e8";
}
.icon-web_client_16:before {
  content: "\e9e9";
}
.icon-Document_upload1:before {
  content: "\e996";
}
.icon-Print:before {
  content: "\e997";
}
.icon-Document_comment:before {
  content: "\e998";
}
.icon-barcode:before {
  content: "\e999";
}
.icon-eye_open:before {
  content: "\e99a";
}
.icon-eye_closed:before {
  content: "\e99b";
}
.icon-Remove_table:before {
  content: "\e994";
}
.icon-Add_table:before {
  content: "\e995";
}
.icon-Packet_full_no:before {
  content: "\e990";
}
.icon-Packet_zero_no:before {
  content: "\e991";
}
.icon-Packet_zero:before {
  content: "\e992";
}
.icon-Packet_Full:before {
  content: "\e993";
}
.icon-option_filial3:before {
  content: "\e98f";
}
.icon-option_filial4:before {
  content: "\e97d";
}
.icon-window_out_up:before {
  content: "\e97e";
}
.icon-window_out_left:before {
  content: "\e97f";
}
.icon-window_out_right:before {
  content: "\e97f";
  rotate: 180deg;
}
.icon-window_out_down:before {
  content: "\e980";
}
.icon-window_down:before {
  content: "\e981";
}
.icon-Subtract:before {
  content: "\e982";
}
.icon-second_window:before {
  content: "\e983";
}
.icon-reports:before {
  content: "\e984";
}
.icon-packet_window:before {
  content: "\e985";
}
.icon-packet_down:before {
  content: "\e986";
}
.icon-main_filial:before {
  content: "\e987";
}
.icon-left_window:before {
  content: "\e988";
}
.icon-filial_user_main:before {
  content: "\e989";
}
.icon-filial_user_add:before {
  content: "\e98a";
}
.icon-down_window:before {
  content: "\e98b";
}
.icon-demo_filial:before {
  content: "\e98c";
}
.icon-demo_filial_2:before {
  content: "\e98d";
}
.icon-demo_filial_1:before {
  content: "\e98e";
}
.icon-name_search:before {
  content: "\e976";
}
.icon-atributes_search:before {
  content: "\e977";
}
.icon-calendar_search:before {
  content: "\e978";
}
.icon-down_calendar:before {
  content: "\e979";
}
.icon-fulltext_search:before {
  content: "\e97a";
}
.icon-number_search:before {
  content: "\e97b";
}
.icon-up_calendar:before {
  content: "\e97c";
}
.icon-on1:before {
  content: "\e96a";
}
.icon-on_look1:before {
  content: "\e96b";
}
.icon-off1:before {
  content: "\e96c";
}
.icon-ob1:before {
  content: "\e96d";
}
.icon-look1:before {
  content: "\e96e";
}
.icon-fill1:before {
  content: "\e96f";
}
.icon-pak_on:before {
  content: "\e970";
}
.icon-pak_on_look:before {
  content: "\e971";
}
.icon-pak_off:before {
  content: "\e972";
}
.icon-pak_ob:before {
  content: "\e973";
}
.icon-pak_look:before {
  content: "\e974";
}
.icon-pac_fill:before {
  content: "\e975";
}
.icon-Open_folder_metka:before {
  content: "\e966";
}
.icon-Open_folder_bookmark:before {
  content: "\e967";
}
.icon-Open_folder_close:before {
  content: "\e968";
}
.icon-Open_folder:before {
  content: "\e969";
}
.icon-C:before {
  content: "\e923";
}
.icon-Email:before {
  content: "\e924";
}
.icon-Facebook:before {
  content: "\e925";
}
.icon-Instagram:before {
  content: "\e926";
}
.icon-Telephone:before {
  content: "\e927";
}
.icon-Viber:before {
  content: "\e928";
}
.icon-Vkontakte:before {
  content: "\e929";
}
.icon-WhatsApp:before {
  content: "\e92a";
}
.icon-ITB:before {
  content: "\e92b";
}
.icon-YandexDzen:before {
  content: "\e92c";
}
.icon-Dot:before {
  content: "\e92d";
}
.icon-Dot1:before {
  content: "\e92e";
}
.icon-Grid_card:before {
  content: "\e92f";
}
.icon-Grid_list:before {
  content: "\e930";
}
.icon-Warning:before {
  content: "\e931";
}
.icon-Add_user:before {
  content: "\e932";
}
.icon-Arrows_1:before {
  content: "\e933";
}
.icon-Arrows_2:before {
  content: "\e934";
}
.icon-Arrows_4:before {
  content: "\e935";
}
.icon-Filter_data:before {
  content: "\e936";
}
.icon-Filter_down:before {
  content: "\e937";
}
.icon-Filter_math1:before {
  content: "\e938";
}
.icon-Filter_math3:before {
  content: "\e939";
}
.icon-Filter_up:before {
  content: "\e93a";
}
.icon-Filter:before {
  content: "\e93b";
}
.icon-Question:before {
  content: "\e93c";
}
.icon-User:before {
  content: "\e93d";
}
.icon-Users:before {
  content: "\e93e";
}
.icon-Okey:before {
  content: "\e93f";
}
.icon-Arrows_3:before {
  content: "\e940";
}
.icon-Filter_math2:before {
  content: "\e941";
}
.icon-Remove_user:before {
  content: "\e942";
}
.icon-Line_text:before {
  content: "\e943";
}
.icon-Text:before {
  content: "\e944";
}
.icon-Sub:before {
  content: "\e945";
}
.icon-Delete_line:before {
  content: "\e946";
}
.icon-Open_packet:before {
  content: "\e947";
}
.icon-Search:before {
  content: "\e948";
}
.icon-Documents:before {
  content: "\e949";
}
.icon-Info:before {
  content: "\e94a";
}
.icon-Packet:before {
  content: "\e94b";
}
.icon-Search_menu:before {
  content: "\e94c";
}
.icon-Settings:before {
  content: "\e94d";
}
.icon-Close_reestr:before {
  content: "\e94e";
}
.icon-Delete:before {
  content: "\e94f";
}
.icon-Main_reestr:before {
  content: "\e950";
}
.icon-Menu_arrow:before {
  content: "\e951";
}
.icon-Notification:before {
  content: "\e952";
}
.icon-Pack_bookmark:before {
  content: "\e953";
}
.icon-Pack_close:before {
  content: "\e954";
}
.icon-Pack_metka:before {
  content: "\e955";
}
.icon-Paper:before {
  content: "\e956";
}
.icon-Plus:before {
  content: "\e957";
}
.icon-Reload:before {
  content: "\e958";
}
.icon-Rename:before {
  content: "\e959";
}
.icon-Subadd:before {
  content: "\e95a";
}
.icon-Edit:before {
  content: "\e95b";
}
.icon-Home:before {
  content: "\e95c";
}
.icon-Bookmark_reestr:before {
  content: "\e95d";
}
.icon-Menu_pack:before {
  content: "\e95e";
}
.icon-Paper_bookmark:before {
  content: "\e95f";
}
.icon-Sub_reestr:before {
  content: "\e960";
}
.icon-Table_grid:before {
  content: "\e961";
}
.icon-Table_view:before {
  content: "\e962";
}
.icon-Doc_view_1:before {
  content: "\e963";
}
.icon-Combine_into_packages:before {
  content: "\e964";
}
.icon-Doc_view_2:before {
  content: "\e965";
}
.icon-Doc_view_3:before {
  content: "\e9ef";
}
.icon-Doc_view_4:before {
  content: "\e9f0";
}
.icon-Doc_view_7:before {
  content: "\e9f1";
}
.icon-Doc_view_8:before {
  content: "\e9f2";
}
.icon-Doc_view_9:before {
  content: "\e9f3";
}
.icon-Doc_view_10:before {
  content: "\e9f4";
}
.icon-Doc_view_12:before {
  content: "\e9f5";
}
.icon-Doc_view_13:before {
  content: "\e9f6";
}
.icon-Doc_view_14:before {
  content: "\e9f7";
}
.icon-Doc_view_15:before {
  content: "\e9f8";
}
.icon-Last_page:before {
  content: "\e9f9";
}
.icon-Next_page:before {
  content: "\e9fa";
}
.icon-Organization:before {
  content: "\e9fb";
}
.icon-Previous_page:before {
  content: "\e9fc";
}
.icon-Table_edit_2:before {
  content: "\e9fd";
}
.icon-Table_edit_3:before {
  content: "\e9fe";
}
.icon-Table_edit_4:before {
  content: "\e9ff";
}
.icon-Table_edit_5:before {
  content: "\ea00";
}
.icon-Table_edit_7:before {
  content: "\ea01";
}
.icon-Table_edit_8:before {
  content: "\ea02";
}
.icon-Table_edit_9:before {
  content: "\ea03";
}
.icon-Table_edit_10:before {
  content: "\ea04";
}
.icon-Table_edit_12:before {
  content: "\ea05";
}
.icon-Table_edit_13:before {
  content: "\ea06";
}
.icon-Table_edit_14:before {
  content: "\ea07";
}
.icon-Upload_document:before {
  content: "\ea08";
}
.icon-User1:before {
  content: "\ea09";
}
.icon-Table_edit_11:before {
  content: "\ea0a";
}
.icon-Table_edit_6:before {
  content: "\ea0b";
}
.icon-Table_edit_1:before {
  content: "\ea0c";
}
.icon-First_page:before {
  content: "\ea0d";
}
.icon-Doc_view_11:before {
  content: "\ea0e";
}
.icon-Doc_view_6:before {
  content: "\ea0f";
}
.icon-Configure_archive:before {
  content: "\ea10";
}
