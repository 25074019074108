@charset "UTF-8";
html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 13px !important;
    font-weight: 400;
    font-family: Montserrat !important;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
@media (max-width: 1199.98px) {
    html,
    body {
        font-size: 12px !important; } }
@media (max-width: 991.98px) {
    html,
    body {
        font-size: 12px !important; } }
html a:hover,
html a:active,
html a:focus,
body a:hover,
body a:active,
body a:focus {
    text-decoration: none !important; }

body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #3F4254; }

router-outlet {
    display: none; }

canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; }

@media print {
    .print-content-only {
        padding: 0 !important;
        background: none !important; }
    .print-content-only .wrapper,
    .print-content-only .page,
    .print-content-only .content,
    .print-content-only .container,
    .print-content-only .container-fluid,
    .print-content-only .container-sm,
    .print-content-only .container-md,
    .print-content-only .container-lg,
    .print-content-only .container-xl,
    .print-content-only .container-xxl {
        background: none !important;
        padding: 0 !important;
        margin: 0 !important; }
    .print-content-only .aside,
    .print-content-only .sidebar,
    .print-content-only .sticky-toolbar,
    .print-content-only .scrolltop,
    .print-content-only .header,
    .print-content-only .header-mobile,
    .print-content-only .footer,
    .print-content-only .subheader,
    .print-content-only .offcanvas {
        display: none !important; } }

body {
    background: var(--body-bg); }

@media (min-width: 992px) {
    .wrapper {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .aside-secondary-enabled.aside-fixed .wrapper {
        padding-left: 425px; }
    .aside-secondary-disabled.aside-fixed .wrapper {
        padding-left: 100px; }
    .aside-fixed.aside-minimize .wrapper {
        padding-left: 100px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .subheader-fixed.subheader-enabled .wrapper {
        padding-top: 55px; }
    .footer-fixed .wrapper {
        padding-bottom: 60px; }
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        padding: 0 25px; } }

@media (max-width: 991.98px) {
    .header-mobile-fixed .wrapper {
        padding-top: 55px; }
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl,
    .container-xxl {
        max-width: none;
        padding: 0 15px; } }

@media (min-width: 992px) {
    .header-mobile {
        display: none; } }

@media (max-width: 991.98px) {
    .header-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 15px;
        background-color: white;
        -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
        height: 55px;
        min-height: 55px;
        position: relative;
        z-index: 3; }
    .header-mobile-fixed .header-mobile {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 97;
        height: 55px;
        min-height: 55px; }
    .topbar-mobile-on .header-mobile {
        -webkit-box-shadow: none;
        box-shadow: none; } }

.aside {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: white;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.aside-secondary-enabled .aside {
    width: 425px; }

.aside-secondary-enabled .aside-p {
    width: 425px;  }

.aside-secondary-enabled .aside-p:hover {
    width: 525px;
    transition: 10ms;
    transition-delay: 300ms;}

.aside-secondary-disabled .aside {
    width: 100px; }
.aside .aside-primary {
    width: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
.aside-secondary-enabled .aside .aside-primary {
    border-right: 1px solid #EBEDF3;
    background: var(--success)}
.aside .aside-secondary {
    height: 100vh; }
.aside .aside-secondary::-webkit-scrollbar {
    border-radius: 0.42rem !important;
}
.aside .aside-secondary .aside-workspace {
    width: 315px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }

.aside .aside-secondary .aside-workspace-p {
    width: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    -moz-box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    background: #fff;}

.aside .aside-secondary .aside-workspace-p:hover {
    width: 525px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    transition: 10ms;
    transition-delay: 300ms;}


.aside .aside-secondary .aside-menu.ps > .ps__rail-y {
    right: 4px; }

@media (min-width: 992px) {
    .aside-fixed .aside {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 98; }
    .aside-static .aside {
        position: relative;
        z-index: 1; }
    .aside-minimize .aside {
        width: 100px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .aside-minimize .aside .aside-primary {
        border-color: transparent; }
    .aside .aside-toggle {
        position: absolute;
        right: 0;
        bottom: 40px;
        height: 36px;
        width: 36px;
        margin-right: -18px;
        z-index: 99;
        transition: all 300ms ease 0s;}
    .aside:hover .aside-toggle {
        transition: all 300ms ease 0s;
    }
    .aside .aside-toggle i {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease; }
    .aside-minimize .aside .aside-toggle i, .aside .aside-toggle.aside-toggle-active i {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        -webkit-transform: rotateZ(180deg)/*rtl:ignore*/;
        transform: rotateZ(180deg)/*rtl:ignore*/; }
    [direction="rtl"] .aside-minimize .aside .aside-toggle i, [direction="rtl"] .aside .aside-toggle.aside-toggle-active i {
        -webkit-transform: rotateZ(-180deg)/*rtl:ignore*/;
        transform: rotateZ(-180deg)/*rtl:ignore*/; } }

@media (max-width: 991.98px) {
    .aside .aside-primary {
        width: 70px; }
    .aside .aside-primary .btn.btn-icon.btn-lg, .aside .aside-primary .btn-group-lg > .btn.btn-icon {
        width: 40px;
        height: 40px; }
    .aside .aside-primary .btn.btn-icon.btn-lg .svg-icon svg, .aside .aside-primary .btn-group-lg > .btn.btn-icon .svg-icon svg {
        height: 26px;
        width: 26px; }
    .aside .aside-secondary .aside-workspace {
        width: 100%;
        -ms-flex-negative: 1;
        flex-shrink: 1; }
    .aside .aside-toggle {
        display: none; }
    .aside-secondary-enabled .aside {
        width: 295px;
        left: -315px; }
    .aside-secondary-enabled .aside.aside-on {
        left: 0; }
    .aside-secondary-enabled .aside.aside-right {
        right: -315px;
        left: auto; }
    .aside-secondary-enabled .aside.aside-right.aside-on {
        right: 0;
        left: auto; }
    .aside-secondary-disabled .aside {
        width: 70px;
        left: -90px; }
    .aside-secondary-disabled .aside.aside-on {
        left: 0; }
    .aside-secondary-disabled .aside.aside-right {
        right: -90px;
        left: auto; }
    .aside-secondary-disabled .aside.aside-right.aside-on {
        right: 0;
        left: auto; } }

.aside-menu .menu-nav {
    margin: 0;
    list-style: none;
    padding: 15px 0; }
.aside-menu .menu-nav .menu-scroll {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
.aside-menu .menu-nav .menu-subnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    list-style: none !important; }
.aside-menu .menu-nav .menu-inner,
.aside-menu .menu-nav .menu-submenu {
    margin: 0;
    padding: 0;
}

.aside-menu .menu-nav .menu-submenu-p {
    border-radius: 20px;
    /*background: rgb(63,140,255,0.03);*/
    background: var(--light-primary);
}

.aside-menu .menu-nav .menu-inner .menu-content,
.aside-menu .menu-nav .menu-submenu .menu-content {
    padding: 0;
    margin: 0; }
.aside-menu .menu-nav .menu-inner .menu-content > .menu-item,
.aside-menu .menu-nav .menu-submenu .menu-content > .menu-item {
    padding: 0;
    margin: 0;
    list-style: none; }
.aside-menu .menu-nav .menu-inner .menu-content > .menu-item.menu-headingless > .menu-inner,
.aside-menu .menu-nav .menu-submenu .menu-content > .menu-item.menu-headingless > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
.aside-menu .menu-nav .menu-inner .menu-content > .menu-item.menu-headingless > .menu-inner > li > .menu-link,
.aside-menu .menu-nav .menu-submenu .menu-content > .menu-item.menu-headingless > .menu-inner > li > .menu-link {
    margin: 0; }
.aside-menu .menu-nav .menu-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    float: none;
    padding: 0; }
.aside-menu .menu-nav .menu-item > .menu-heading,
.aside-menu .menu-nav .menu-item > .menu-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    outline: none; }
.aside-menu .menu-nav .menu-item > .menu-heading:hover,
.aside-menu .menu-nav .menu-item > .menu-link:hover {
    text-decoration: none;
    cursor: pointer; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav .menu-item > .menu-link .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-label,
.aside-menu .menu-nav .menu-item > .menu-link .menu-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav .menu-item > .menu-link .menu-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-bullet,
.aside-menu .menu-nav .menu-item > .menu-link .menu-bullet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-arrow,
.aside-menu .menu-nav .menu-item > .menu-link .menu-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 0; }
.aside-menu .menu-nav .menu-item > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav .menu-item > .menu-link .menu-arrow:before {
    -webkit-transform: translate3d(0, 0, 0); }
.aside-menu .menu-nav .menu-item.menu-item-open > .menu-heading > .menu-arrow:before,
.aside-menu .menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }

.aside-menu .menu-nav .menu-item-gray.menu-item-open > .menu-heading > .menu-arrow-gray:before,
.aside-menu .menu-nav .menu-item-gray.menu-item-open > .menu-link > .menu-arrow-gray:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }

.aside-menu .menu-nav .menu-item.menu-item-select > .menu-heading > .menu-arrow:before,
.aside-menu .menu-nav .menu-item.menu-item-select > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }


[direction="rtl"] .aside-menu .menu-nav .menu-item.menu-item-open > .menu-heading > .menu-arrow:before, [direction="rtl"]
.aside-menu .menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(-90deg)/*rtl:ignore*/;
    transform: rotateZ(-90deg)/*rtl:ignore*/; }
.aside-menu .menu-nav .menu-item.menu-item-open > .menu-submenu,
.aside-menu .menu-nav .menu-item.menu-item-open > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

.aside-menu .menu-nav .menu-item.menu-item-select > .menu-submenu,
.aside-menu .menu-nav .menu-item.menu-item-select > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }


.aside-menu .menu-nav .menu-item .menu-submenu .menu-item-parent {
    display: none; }
.aside-menu .menu-nav .menu-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 25px; }
.aside-menu .menu-nav .menu-section.menu-section-first {
    margin-top: 0 !important; }
.aside-menu .menu-nav .menu-section .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.aside-menu .menu-nav .menu-section .menu-icon {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
.aside-menu .menu-nav .menu-separator {
    height: 0;
    overflow: hidden; }
.aside-menu .menu-nav .menu-separator.menu-separator-marginless {
    margin: 0; }
.aside-menu .menu-nav > .menu-item {
    position: relative;
    margin: 0 ; }
.aside-menu .menu-nav > .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item > .menu-link {
    cursor: pointer;
    border-radius: 0.42rem;
    min-height: 44px; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: initial;
    line-height: 1.45;}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 35px;
    font-size: 1.3rem; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon svg,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon svg {
    height: 23px ;
    width: 23px ;
    margin-left: -2px; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-bullet,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-bullet {
    vertical-align: middle;
    text-align: left;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-bullet > span,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-bullet > span {
    vertical-align: middle;
    display: inline-block; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
    width: 4px;
    height: 4px;
    border-radius: 100%; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
    width: 5px;
    height: 1px; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-label,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-label {
    padding: 0px 0px 0px 5px; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 0.6rem; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }
.aside-menu .menu-nav > .menu-item-gray > .menu-heading .menu-arrow-gray:before,
.aside-menu .menu-nav > .menu-item-gray > .menu-link .menu-arrow-gray:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }

[direction="rtl"] .aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow:before {
    content: "" !important; }
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-item-here,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-item-here {
    display: none; }
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.aside-menu .menu-nav > .menu-item.menu-item-select > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item.menu-item-select > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }


.aside-menu .menu-nav > .menu-item .menu-submenu .menu-subnav {
    margin: 0 ;
    border-radius: 20px;}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item {
    margin: 0 ;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link {
    cursor: pointer;
    border-radius: 0.42rem;
    min-height: 40px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: initial;
    line-height: 1.45;}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 35px;
    font-size: 1.3rem; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-icon svg,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon svg {
    height: 24px ;
    width: 24px ;
    margin-left: -2px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet {
    vertical-align: middle;
    text-align: left;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet > span,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet > span {
    vertical-align: middle;
    display: inline-block; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
    width: 4px;
    height: 4px;
    border-radius: 100%; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
    width: 5px;
    height: 1px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-label,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-label {
    padding: 0px 0px 0px 5px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 0.6rem; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }
[direction="rtl"] .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow:before {
    content: "" !important; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-item-here,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-item-here {
    display: none; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-select > .menu-heading .menu-arrow:before,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-select > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-section {
    margin: 20px 0 0 0;
    height: 40px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-section .menu-text {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3px; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-separator {
    margin: 15px 0; }
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-separator .menu-separator-marginless {
    margin: 0; }
.aside-menu .menu-nav > .menu-section {
    margin: 20px 0 0 0;
    height: 40px; }
.aside-menu .menu-nav > .menu-section .menu-text {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px; }
.aside-menu .menu-nav > .menu-separator {
    margin: 15px 0; }
.aside-menu .menu-nav > .menu-separator .menu-separator-marginless {
    margin: 0; }
.aside-menu .menu-nav > .menu-item > .menu-link {
    padding: 0 10px;
    font-family: 'Montserrat';}
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-heading {
    padding: 0 25px;
    padding-left: 40px; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner {
    padding: 0;
    margin: 0; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner .menu-link {
    padding: 0 25px;
    padding-left: 55px;}
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 25px;
    padding-right: 10px;}

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link-p {
    padding-left: 25px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav {
    padding: 0; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 35px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link-p {
    padding-left: 35px; }


.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 45px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 55px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 65px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 73px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 80px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 86px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 91px; }

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 95px; }



.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 40px; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 35px; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 50px; }

@media (min-width: 992px) {
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-link > .menu-arrow:before,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-link > .menu-arrow:before {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-submenu,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-submenu {
        display: none !important;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover {
        position: relative; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu {
        top: -10px;
        position: absolute;
        left: 0;
        z-index: 98;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 265px;
        margin-left: 265px;
        border-radius: 4px;
        -webkit-animation: aside-menu-submenu-fade-in 0.3s ease 1, aside-menu-submenu-move-up 0.3s ease-out 1;
        animation: aside-menu-submenu-fade-in 0.3s ease 1, aside-menu-submenu-move-up 0.3s ease-out 1; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        bottom: 0;
        top: auto; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        top: auto;
        bottom: -10px;
        -webkit-animation: aside-menu-submenu-fade-in 0.3s ease 1, aside-menu-submenu-move-down 0.3s ease-out 1;
        animation: aside-menu-submenu-fade-in 0.3s ease 1, aside-menu-submenu-move-down 0.3s ease-out 1; } }
@media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        -webkit-animation: none;
        animation: none; } }

@media (min-width: 992px) {
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item {
        margin: 0 ; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 7px 30px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .link-title,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .link-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-text {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-bullet,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-bullet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-label,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link {
        padding: 7px 30px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link {
        padding: 7px 30px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-section,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-section {
        padding: 0 25px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link {
        cursor: pointer;
        min-height: 40px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-text,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35px;
        flex: 0 0 35px;
        font-size: 1.35rem; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon svg,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon svg,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon svg,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon svg {
        margin-left: -2px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet {
        vertical-align: middle;
        text-align: left;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 15px;
        flex: 0 0 15px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet > span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet > span {
        vertical-align: middle;
        display: inline-block; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
        width: 4px;
        height: 4px;
        border-radius: 100%; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
        width: 5px;
        height: 1px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-label,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-label,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-label,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-label {
        padding: 0px 0px 0px 5px;
        text-align: right; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
        font-size: 0.6rem; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        font-family: Ki;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        content: ""; }
    [direction="rtl"] .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before, [direction="rtl"]
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before {
        content: "" !important; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-item-here,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-item-here,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-item-here,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-item-here {
        display: none; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-link .menu-arrow:before,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-link .menu-arrow:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section {
        margin: 20px 0 0 0;
        height: 40px; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section .menu-text {
        font-size: 0.8rem;
        font-weight: 300;
        text-transform: uppercase; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator {
        margin: 15px 0; }
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator .menu-separator-marginless,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator .menu-separator-marginless {
        margin: 0; }
    .aside-minimize .aside-menu .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu,
    .aside-menu.aside-menu-dropdown .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu {
        margin-left: 265px;
        top: -5px; }
    .aside-minimize .aside-menu .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .aside-menu.aside-menu-dropdown .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        bottom: -10px; } }

@-webkit-keyframes aside-menu-submenu-fade-out {
    from {
        opacity: 1; }
    to {
        opacity: 0; } }

@keyframes aside-menu-submenu-fade-out {
    from {
        opacity: 1; }
    to {
        opacity: 0; } }

@-webkit-keyframes aside-menu-submenu-fade-in {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@keyframes aside-menu-submenu-fade-in {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@-webkit-keyframes aside-menu-submenu-move-up {
    from {
        margin-top: 10px; }
    to {
        margin-top: 0; } }

@keyframes aside-menu-submenu-move-up {
    from {
        margin-top: 10px; }
    to {
        margin-top: 0; } }

@-webkit-keyframes aside-menu-submenu-move-down {
    from {
        margin-bottom: 10px; }
    to {
        margin-bottom: 0; } }

@keyframes aside-menu-submenu-move-down {
    from {
        margin-bottom: 10px; }
    to {
        margin-bottom: 0; } }


/*.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {*/
/*    padding: 0 5px !important;*/
/*    padding-left: 20px !important;*/
/*}*/

/*.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open {*/
/*    padding-left: 1.5em !important;*/
/*}*/


.asidef {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: white;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.asidef-secondary-enabled .asidef {
    width: 425px; }

.asidef-secondary-enabled .asidef-p {
    width: 425px;  }

.asidef-secondary-enabled .asidef-p:hover {
    width: 525px;
    transition: 10ms;
    transition-delay: 300ms;}

.asidef-secondary-disabled .asidef {
    width: 100px; }
.asidef .asidef-primary {
    width: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
.asidef-secondary-enabled .asidef .asidef-primary {
    border-right: 1px solid #EBEDF3;
    background: var(--success);}
.asidef .asidef-secondary {
    height: 100vh; }
.asidef .asidef-secondary::-webkit-scrollbar {
    border-radius: 0.42rem !important;
}
.asidef .asidef-secondary .asidef-workspace {
    width: 315px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }

.asidef .asidef-secondary .asidef-workspace-p {
    width: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    -moz-box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    box-shadow: 12px 0px 11px -5px rgba(32, 75, 160, 0.08);
    background: #fff;}

.asidef .asidef-secondary .asidef-workspace-p:hover {
    width: 525px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    transition: 10ms;
    transition-delay: 300ms;}


.asidef .asidef-secondary .asidef-menu.ps > .ps__rail-y {
    right: 4px; }

@media (min-width: 992px) {
    .asidef-fixed .asidef {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 98; }
    .asidef-static .asidef {
        position: relative;
        z-index: 1; }
    .asidef-minimize .asidef {
        width: 100px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .asidef-minimize .asidef .asidef-primary {
        border-color: transparent; }
    .asidef .asidef-toggle {
        position: absolute;
        right: 0;
        bottom: 40px;
        height: 36px;
        width: 36px;
        margin-right: -18px;
        z-index: 99;
        transition: all 300ms ease 0s;}
    .asidef:hover .asidef-toggle {
        transition: all 300ms ease 0s;
    }
    .asidef .asidef-toggle i {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease; }
    .asidef-minimize .asidef .asidef-toggle i, .asidef .asidef-toggle.asidef-toggle-active i {
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        -webkit-transform: rotateZ(180deg)/*rtl:ignore*/;
        transform: rotateZ(180deg)/*rtl:ignore*/; }
    [direction="rtl"] .asidef-minimize .asidef .asidef-toggle i, [direction="rtl"] .asidef .asidef-toggle.asidef-toggle-active i {
        -webkit-transform: rotateZ(-180deg)/*rtl:ignore*/;
        transform: rotateZ(-180deg)/*rtl:ignore*/; } }

@media (max-width: 991.98px) {
    .asidef .asidef-primary {
        width: 70px; }
    .asidef .asidef-primary .btn.btn-icon.btn-lg, .asidef .asidef-primary .btn-group-lg > .btn.btn-icon {
        width: 40px;
        height: 40px; }
    .asidef .asidef-primary .btn.btn-icon.btn-lg .svg-icon svg, .asidef .asidef-primary .btn-group-lg > .btn.btn-icon .svg-icon svg {
        height: 26px;
        width: 26px; }
    .asidef .asidef-secondary .asidef-workspace {
        width: 100%;
        -ms-flex-negative: 1;
        flex-shrink: 1; }
    .asidef .asidef-toggle {
        display: none; }
    .asidef-secondary-enabled .asidef {
        width: 295px;
        left: -315px; }
    .asidef-secondary-enabled .asidef.asidef-on {
        left: 0; }
    .asidef-secondary-enabled .asidef.asidef-right {
        right: -315px;
        left: auto; }
    .asidef-secondary-enabled .asidef.asidef-right.asidef-on {
        right: 0;
        left: auto; }
    .asidef-secondary-disabled .asidef {
        width: 70px;
        left: -90px; }
    .asidef-secondary-disabled .asidef.asidef-on {
        left: 0; }
    .asidef-secondary-disabled .asidef.asidef-right {
        right: -90px;
        left: auto; }
    .asidef-secondary-disabled .asidef.asidef-right.asidef-on {
        right: 0;
        left: auto; } }

.asidef-menu .menu-nav {
    margin: 0;
    list-style: none;
    padding: 15px 0; }
.asidef-menu .menu-nav .menu-scroll {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
.asidef-menu .menu-nav .menu-subnav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    list-style: none !important; }
.asidef-menu .menu-nav .menu-inner,
.asidef-menu .menu-nav .menu-submenu {
    margin: 0;
    padding: 0;
}

.asidef-menu .menu-nav .menu-submenu-p {
    border-radius: 20px;
    background: rgb(63,140,255,0.03);
}

.asidef-menu .menu-nav .menu-inner .menu-content,
.asidef-menu .menu-nav .menu-submenu .menu-content {
    padding: 0;
    margin: 0; }
.asidef-menu .menu-nav .menu-inner .menu-content > .menu-item,
.asidef-menu .menu-nav .menu-submenu .menu-content > .menu-item {
    padding: 0;
    margin: 0;
    list-style: none; }
.asidef-menu .menu-nav .menu-inner .menu-content > .menu-item.menu-headingless > .menu-inner,
.asidef-menu .menu-nav .menu-submenu .menu-content > .menu-item.menu-headingless > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
.asidef-menu .menu-nav .menu-inner .menu-content > .menu-item.menu-headingless > .menu-inner > li > .menu-link,
.asidef-menu .menu-nav .menu-submenu .menu-content > .menu-item.menu-headingless > .menu-inner > li > .menu-link {
    margin: 0; }
.asidef-menu .menu-nav .menu-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    float: none;
    padding: 0; }
.asidef-menu .menu-nav .menu-item > .menu-heading,
.asidef-menu .menu-nav .menu-item > .menu-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    outline: none; }
.asidef-menu .menu-nav .menu-item > .menu-heading:hover,
.asidef-menu .menu-nav .menu-item > .menu-link:hover {
    text-decoration: none;
    cursor: pointer; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-text,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-label,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-icon,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-bullet,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-bullet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 0; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-arrow,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 0; }
.asidef-menu .menu-nav .menu-item > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav .menu-item > .menu-link .menu-arrow:before {
    -webkit-transform: translate3d(0, 0, 0); }
.asidef-menu .menu-nav .menu-item.menu-item-open > .menu-heading > .menu-arrow:before,
.asidef-menu .menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }

.asidef-menu .menu-nav .menu-item-gray.menu-item-open > .menu-heading > .menu-arrow-gray:before,
.asidef-menu .menu-nav .menu-item-gray.menu-item-open > .menu-link > .menu-arrow-gray:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }

.asidef-menu .menu-nav .menu-item.menu-item-select > .menu-heading > .menu-arrow:before,
.asidef-menu .menu-nav .menu-item.menu-item-select > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(90deg)/*rtl:ignore*/;
    transform: rotateZ(90deg)/*rtl:ignore*/; }


[direction="rtl"] .asidef-menu .menu-nav .menu-item.menu-item-open > .menu-heading > .menu-arrow:before, [direction="rtl"]
.asidef-menu .menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow:before {
    -webkit-transform: rotateZ(-90deg)/*rtl:ignore*/;
    transform: rotateZ(-90deg)/*rtl:ignore*/; }
.asidef-menu .menu-nav .menu-item.menu-item-open > .menu-submenu,
.asidef-menu .menu-nav .menu-item.menu-item-open > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

.asidef-menu .menu-nav .menu-item.menu-item-select > .menu-submenu,
.asidef-menu .menu-nav .menu-item.menu-item-select > .menu-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }


.asidef-menu .menu-nav .menu-item .menu-submenu .menu-item-parent {
    display: none; }
.asidef-menu .menu-nav .menu-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 25px; }
.asidef-menu .menu-nav .menu-section.menu-section-first {
    margin-top: 0 !important; }
.asidef-menu .menu-nav .menu-section .menu-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.asidef-menu .menu-nav .menu-section .menu-icon {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
.asidef-menu .menu-nav .menu-separator {
    height: 0;
    overflow: hidden; }
.asidef-menu .menu-nav .menu-separator.menu-separator-marginless {
    margin: 0; }
.asidef-menu .menu-nav > .menu-item {
    position: relative;
    margin: 0 ; }
.asidef-menu .menu-nav > .menu-item > .menu-heading,
.asidef-menu .menu-nav > .menu-item > .menu-link {
    cursor: pointer;
    border-radius: 0.42rem;
    min-height: 44px; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: initial;
    line-height: 1.45;}
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 35px;
    font-size: 1.3rem; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-icon svg,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-icon svg {
    height: 23px ;
    width: 23px ;
    margin-left: -2px; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-bullet,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-bullet {
    vertical-align: middle;
    text-align: left;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-bullet > span,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-bullet > span {
    vertical-align: middle;
    display: inline-block; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
    width: 4px;
    height: 4px;
    border-radius: 100%; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
    width: 5px;
    height: 1px; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-label,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-label {
    padding: 0px 0px 0px 5px; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-arrow,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 0.6rem; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }
.asidef-menu .menu-nav > .menu-item-gray > .menu-heading .menu-arrow-gray:before,
.asidef-menu .menu-nav > .menu-item-gray > .menu-link .menu-arrow-gray:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }

[direction="rtl"] .asidef-menu .menu-nav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-arrow:before {
    content: "" !important; }
.asidef-menu .menu-nav > .menu-item > .menu-heading .menu-item-here,
.asidef-menu .menu-nav > .menu-item > .menu-link .menu-item-here {
    display: none; }
.asidef-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.asidef-menu .menu-nav > .menu-item.menu-item-select > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item.menu-item-select > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }


.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-subnav {
    margin: 0 ;
    border-radius: 20px;}
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item {
    margin: 0 ;
}
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link {
    cursor: pointer;
    border-radius: 0.42rem;
    min-height: 40px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-text,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
    font-weight: 400;
    font-size: 1rem;
    text-transform: initial;
    line-height: 1.45;}
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-icon,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 35px;
    font-size: 1.3rem; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-icon svg,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon svg {
    height: 24px ;
    width: 24px ;
    margin-left: -2px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet {
    vertical-align: middle;
    text-align: left;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet > span,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet > span {
    vertical-align: middle;
    display: inline-block; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
    width: 4px;
    height: 4px;
    border-radius: 100%; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
    width: 5px;
    height: 1px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-label,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-label {
    padding: 0px 0px 0px 5px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 0.6rem; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: asv-font;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e95b"; }
[direction="rtl"] .asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow:before {
    content: "" !important; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-heading .menu-item-here,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-item-here {
    display: none; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-select > .menu-heading .menu-arrow:before,
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-select > .menu-link .menu-arrow:before {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-section {
    margin: 20px 0 0 0;
    height: 40px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-section .menu-text {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3px; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-separator {
    margin: 15px 0; }
.asidef-menu .menu-nav > .menu-item .menu-submenu .menu-separator .menu-separator-marginless {
    margin: 0; }
.asidef-menu .menu-nav > .menu-section {
    margin: 20px 0 0 0;
    height: 40px; }
.asidef-menu .menu-nav > .menu-section .menu-text {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px; }
.asidef-menu .menu-nav > .menu-separator {
    margin: 15px 0; }
.asidef-menu .menu-nav > .menu-separator .menu-separator-marginless {
    margin: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-link {
    padding: 0 10px;
    font-family: 'Montserrat';}
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-heading {
    padding: 0 25px;
    padding-left: 40px; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner {
    padding: 0;
    margin: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner .menu-link {
    padding: 0 25px;
    padding-left: 55px;}
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 25px;
    padding-right: 10px;}

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link-p {
    padding-left: 25px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav {
    padding: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 35px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link-p {
    padding-left: 35px; }


.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 45px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 55px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 65px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 73px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 80px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 86px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 91px; }

.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item >  .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding-left: 95px; }



.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 40px; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 35px; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
    padding: 0; }
.asidef-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
    padding-left: 50px; }

@media (min-width: 992px) {
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-link > .menu-arrow:before,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-link > .menu-arrow:before {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-submenu,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]) > .menu-submenu {
        display: none !important;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover {
        position: relative; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu {
        top: -10px;
        position: absolute;
        left: 0;
        z-index: 98;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 265px;
        margin-left: 265px;
        border-radius: 4px;
        -webkit-animation: asidef-menu-submenu-fade-in 0.3s ease 1, asidef-menu-submenu-move-up 0.3s ease-out 1;
        animation: asidef-menu-submenu-fade-in 0.3s ease 1, asidef-menu-submenu-move-up 0.3s ease-out 1; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        bottom: 0;
        top: auto; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        top: auto;
        bottom: -10px;
        -webkit-animation: asidef-menu-submenu-fade-in 0.3s ease 1, asidef-menu-submenu-move-down 0.3s ease-out 1;
        animation: asidef-menu-submenu-fade-in 0.3s ease 1, asidef-menu-submenu-move-down 0.3s ease-out 1; } }
@media screen and (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        -webkit-animation: none;
        animation: none; } }

@media (min-width: 992px) {
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item {
        margin: 0 ; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 7px 30px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .link-title,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .link-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-text,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-text {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-icon,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-bullet,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-bullet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-label,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-arrow,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-link .menu-arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link {
        padding: 7px 30px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav {
        padding: 20px 0; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-item > .menu-link {
        padding: 7px 30px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-section,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item .menu-submenu .menu-subnav > .menu-section {
        padding: 0 25px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link {
        cursor: pointer;
        min-height: 40px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-text,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-text,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-text,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-text {
        font-weight: 400;
        font-size: 1rem;
        text-transform: initial; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35px;
        flex: 0 0 35px;
        font-size: 1.35rem; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon svg,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon svg,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-icon svg,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-icon svg {
        margin-left: -2px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet {
        vertical-align: middle;
        text-align: left;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 15px;
        flex: 0 0 15px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet > span,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet > span {
        vertical-align: middle;
        display: inline-block; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-dot > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
        width: 4px;
        height: 4px;
        border-radius: 100%; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-bullet.menu-bullet-line > span,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-bullet.menu-bullet-line > span {
        width: 5px;
        height: 1px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-label,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-label,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-label,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-label {
        padding: 0px 0px 0px 5px;
        text-align: right; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
        flex: 0 0 20px;
        font-size: 0.6rem; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        font-family: Ki;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
        content: ""; }
    [direction="rtl"] .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before, [direction="rtl"]
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-arrow:before, [direction="rtl"]
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-arrow:before {
        content: "" !important; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-item-here,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-item-here,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-heading .menu-item-here,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item > .menu-link .menu-item-here {
        display: none; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-link .menu-arrow:before,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-heading .menu-arrow:before,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-item.menu-item-open > .menu-link .menu-arrow:before {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section {
        margin: 20px 0 0 0;
        height: 40px; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section .menu-text,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-section .menu-text {
        font-size: 0.8rem;
        font-weight: 300;
        text-transform: uppercase; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator {
        margin: 15px 0; }
    .asidef-minimize .asidef-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator .menu-separator-marginless,
    .asidef-menu.asidef-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu .menu-subnav > .menu-separator .menu-separator-marginless {
        margin: 0; }
    .asidef-minimize .asidef-menu .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu,
    .asidef-menu.asidef-menu-dropdown .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu {
        margin-left: 265px;
        top: -5px; }
    .asidef-minimize .asidef-menu .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up,
    .asidef-menu.asidef-menu-dropdown .menu-nav > .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover > .menu-submenu.menu-submenu-up {
        bottom: -10px; } }

@-webkit-keyframes asidef-menu-submenu-fade-out {
    from {
        opacity: 1; }
    to {
        opacity: 0; } }

@keyframes asidef-menu-submenu-fade-out {
    from {
        opacity: 1; }
    to {
        opacity: 0; } }

@-webkit-keyframes asidef-menu-submenu-fade-in {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@keyframes asidef-menu-submenu-fade-in {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

@-webkit-keyframes asidef-menu-submenu-move-up {
    from {
        margin-top: 10px; }
    to {
        margin-top: 0; } }

@keyframes asidef-menu-submenu-move-up {
    from {
        margin-top: 10px; }
    to {
        margin-top: 0; } }

@-webkit-keyframes asidef-menu-submenu-move-down {
    from {
        margin-bottom: 10px; }
    to {
        margin-bottom: 0; } }

@keyframes asidef-menu-submenu-move-down {
    from {
        margin-bottom: 10px; }
    to {
        margin-bottom: 0; } }
