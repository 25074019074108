@charset "UTF-8";
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #3F4254;
    background-color: transparent;
    text-align: center;}
.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid var(--hover-primary);
    vertical-align: middle;}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #EBEDF3; }
.table tbody + tbody {
    border-top: 2px solid #EBEDF3; }

.height2 {
    height: 2em;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem; }

.table-bordered {
    border: 1px solid #EBEDF3; }
.table-bordered th,
.table-bordered td {
    border: 1px solid #EBEDF3; }
.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--light-primary-transp); }

.table-hover tbody tr:hover {
    color: #3F4254;
    background-color: var(--hover-primary-transp) ; }

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #bfefed; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: #88e1dd; }

.table-hover .table-primary:hover {
    background-color: #abeae7; }
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #abeae7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: #f7f8fb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: #f1f2f7; }

.table-hover .table-secondary:hover {
    background-color: #e6e9f3; }
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #e6e9f3; }

.table-success,
.table-success > th,
.table-success > td {
    background-color: #c7e2ff; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: #96caff; }

.table-hover .table-success:hover {
    background-color: #aed5ff; }
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #aed5ff; }

.table-info,
.table-info > th,
.table-info > td {
    background-color: #d5e1ff; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: #b1c7ff; }

.table-hover .table-info:hover {
    background-color: #bccfff; }
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #bccfff; }

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #ffe7b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: #ffd27a; }

.table-hover .table-warning:hover {
    background-color: #ffde9f; }
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffde9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #fccdd2; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: #faa3ac; }

.table-hover .table-danger:hover {
    background-color: #fbb5bc; }
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #fbb5bc; }

.table-light,
.table-light > th,
.table-light > td {
    background-color: #fcfcfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: #f9fafc; }

.table-hover .table-light:hover {
    background-color: #ededf3; }
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ededf3; }

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #bebfc6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #878994; }

.table-hover .table-dark:hover {
    background-color: #b0b2ba; }
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b0b2ba; }

.table-white,
.table-white > th,
.table-white > td {
    background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
    border-color: white; }

.table-hover .table-white:hover {
    background-color: #f2f2f2; }
.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-active,
.table-active > th,
.table-active > td {
    background-color: #E4E6EF; }

.table-hover .table-active:hover {
    background-color: #d4d7e6; }
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: #d4d7e6; }

.table .thead-dark th {
    color: white;
    background-color: #181C32;
    border-color: #242a4c; }

.table .thead-light th {
    color: #3F4254;
    background-color: var(--elem-bg);
    border-color: #EBEDF3; }

.table-dark {
    color: white;
    background-color: #181C32; }
.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #242a4c; }
.table-dark.table-bordered {
    border: 0; }
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
.table-dark.table-hover tbody tr:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
        border: 0; } }

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
        border: 0; } }

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
        border: 0; } }

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
        border: 0; } }

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
        border: 0; } }

.table-responsive {

    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    max-height: 70vh;
    scroll-behavior: smooth;}

.table-responsive::-webkit-scrollbar {

    width: 10px;
    padding-top:15px;

    height: 10px;
    /*background-color: rgba(255, 255, 255, 0.3);*/
}

.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*background-color: rgb(239 137 137 / 20%);*/
    background-color: var(--background-colorBS);
}

/*.table-responsive::-webkit-scrollbar-thumb:hover {*/
/*    border-radius: 10px;*/
/*    background-color: rgba(137, 178, 239, 0.25);*/
/*}*/

.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;
    /*background-color: rgb(255 234 234 / 30%);*/
    /*background-color: var(--background-colorBS);*/
    margin-top: 50px;
}
.table-responsive:hover::-webkit-scrollbar{
    /*overflow-y: auto;*/
    /*overflow-x: auto;*/
    /*width:10px;*/
    /*height:10px;*/
}
.table-responsive > .table-bordered {
    border: 0; }

.table-responsive .tree-pack-search {
    margin: 1rem;
}

.table thead th, .table thead td {
    font-weight: 600;
    font-size: 1rem;
    border-bottom-width: 1px;
    padding-top: 1rem;
    padding-bottom: 1rem; }

.table.table-head-borderless thead th, .table.table-head-borderless thead td {
    border-top: 0; }

.table.table-head-solid thead th, .table.table-head-solid thead td {
    background-color: var(--elem-bg); }

.table.table-light-primary thead tr {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1); }

.table.table-light-secondary thead tr {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1); }

.table.table-light-success thead tr {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1); }

.table.table-light-info thead tr {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1); }

.table.table-light-warning thead tr {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1); }

.table.table-light-danger thead tr {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1); }

.table.table-light-light thead tr {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1); }

.table.table-light-dark thead tr {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1); }

.table.table-light-white thead tr {
    color: white;
    background-color: rgba(255, 255, 255, 0.1); }

.table.table-head-custom thead tr, .table.table-head-custom thead th {
    font-weight: 600;
    color: var(--originaltwo) !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    position: sticky;
    top: 0;
    background: #fff;}

.table.table-foot-custom tfoot th, .table.table-foot-custom tfoot td {
    font-weight: 600;
    color: #89B2EF !important;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

.table.table-head-bg thead tr, .table.table-head-bg thead th {
    background-color: var(--elem-bg);
    border-bottom: 0;
    letter-spacing: 1px; }
.table.table-head-bg thead tr:first-child, .table.table-head-bg thead th:first-child {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem; }
.table.table-head-bg thead tr:last-child, .table.table-head-bg thead th:last-child {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }

.table.table-foot-bg tfoot th, .table.table-foot-bg tfoot td {
    border-bottom: 0;
    background-color: var(--elem-bg); }
.table.table-foot-bg tfoot th:first-child, .table.table-foot-bg tfoot td:first-child {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem; }
.table.table-foot-bg tfoot th:last-child, .table.table-foot-bg tfoot td:last-child {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }

.table.table-separate th, .table.table-separate td {
    border-top: 0;
    border-bottom: 1px solid #EBEDF3; }
.table.table-separate th:first-child, .table.table-separate td:first-child {
    padding-left: 0 !important; }
.table.table-separate th:last-child, .table.table-separate td:last-child {
    padding-right: 0 !important; }

.table.table-separate tfoot th, .table.table-separate tfoot td {
    border-bottom: 0;
    border-top: 1px solid #EBEDF3; }

.table.table-separate tbody tr:last-child td {
    border-bottom: 0; }

.table.table-bordered tfoot th, .table.table-bordered tfoot td {
    border-bottom: 0; }
/*TODO закоментить text-align: left;*/
.table.table-vertical-center th, .table.table-vertical-center td {
    vertical-align: middle;
    /*text-align: left;*/
}

.table:not(.table-bordered) thead th, .table:not(.table-bordered) thead td {
    border-top: 0; }

.tablez1 {
    z-index: 1;
}
.tablez2 {
    z-index: 2;
}

/*TODO Черная рамка на пакете при открытии из Истории Задача № 19776*/
.noneFocus:focus {
    outline: none;
}

.tr-active {
    background: var(--active-primary) !important;
}

.content-section {
    max-height: 200px;
    overflow-x: auto;
}

.content-section::-webkit-scrollbar {

    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.3);
}

.content-section::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--background-colorBS);
}

.content-section::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.35);
}

.content-text {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-family: 'Montserrat';
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
}

.table_font_size_85 {
    font-size: 0.85em;
}

.change_table_text_1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.change_table_text_2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
}

.change_table_text_3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.package-active {
    cursor: pointer;
    background-color: var(--active-primary);
    border-radius: 1.5em;
    /*pointer-events: none;*/
}

.package-active .packet-body {
    background-color: var(--active-primary) !important;
    border-radius: 1.5em;
}

.table>:not(caption)>*>* {
    box-shadow: none !important;
}
@media (max-width: 1450px ) {
    .d-none-1450px {
        display: none;
    }
}
@media (max-width: 1700px ) {
    .d-none-1700px {
        display: none;
    }
}
