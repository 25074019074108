.link {
    text-decoration: none;
}

.item {
    list-style-type: none;
    display: inline;
    margin-right: 5px;
    padding: 3px;
}

.activeLink {
    text-decoration: none;
    color: red;
}

