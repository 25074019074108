@charset "UTF-8";
.checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0; }
.checkbox > span {
    background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 0.42rem; }
.checkbox > span.row-checked:after {
    content: '';
    border-color: transparent;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(45deg)/*rtl:ignore*/;
    transform: rotate(45deg)/*rtl:ignore*/;
    margin-top: -2px; }
.checkbox > span.main-table-delete:before {
    content: '';
    border-color: white;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(-45deg)/*rtl:ignore*/;
    transform: rotate(-45deg)/*rtl:ignore*/;
    margin-top: -1px; }
.checkbox > span.main-table-delete:after {
    content: '';
    border-color: white;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    transform: rotate(45deg)/*rtl:ignore*/;
    margin-top: -1px;
    margin-left: -2px;}
.checkbox > input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
.checkbox:hover > input:not([disabled]) ~ span,
.checkbox > input:focus ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.checkbox.checkbox-disabled {
    opacity: 0.6;
    cursor: not-allowed; }
.checkbox > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: none; }
.checkbox > input:checked ~ span:after {
    display: block; }
.checkbox.checkbox-square > span {
    border-radius: 0; }
.checkbox.checkbox-circle > span {
    border-radius: 50%; }
.checkbox.checkbox-outline > span {
    border-width: 1px;
    border-style: solid; }
.checkbox.checkbox-outline-2x > span {
    border-width: 2px !important; }
.checkbox.checkbox-accent > span {
    /*border-width: !important;*/
    border-style: solid !important;
    background-color: transparent !important; }
.checkbox.checkbox-accent > span:after {
    display: block; }

.checkbox > span {
    height: 18px;
    width: 18px; }
.checkbox > span:after {
    width: 5px;
    height: 10px; }

.checkbox.checkbox-lg > span {
    height: 24px;
    width: 24px; }
.checkbox.checkbox-lg > span:after {
    width: 6px;
    height: 12px; }
.checkbox.checkbox-lg > span.main-table-delete:before,
.checkbox.checkbox-lg > span.main-table-delete:after{
    width: 0;
    height: 18px; }
.checkbox.checkbox-outline > span {
    height: 18px;
    width: 18px; }
.checkbox.checkbox-outline > span:after {
    width: 5px;
    height: 10px; }

.checkbox.checkbox-outline.checkbox-lg > span {
    height: 24px;
    width: 24px; }
.checkbox.checkbox-outline.checkbox-lg > span:after {
    width: 6px;
    height: 12px; }

.checkbox > span {
    background-color: #ffffff;
    border: 1px solid #b9c2e0;
}

/*TODO чтобы не исчезал чекбокс*/
/*.checkbox > input:focus ~ span {*/
/*    border: 1px solid transparent; }*/
.checkbox > input:focus ~ span {
    border: 1px solid #b9c2e0; }
.checkbox > input:checked ~ span {
    background-color: var(--success); }
.checkbox > input:checked ~ span.main-table-delete {
    background-color: var(--danger);}
.checkbox > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.checkbox.checkbox-outline > input:checked ~ span:after {
    border-color: var(--success); }

.checkbox.checkbox-primary > span {
    border: 1px solid transparent; }

.checkbox.checkbox-primary > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-primary > input:checked ~ span {
    background-color: var(--success); }
.checkbox.checkbox-primary > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-primary > span {
    background-color: #C9F7F5;
    border: 1px solid transparent; }

.checkbox.checkbox-light-primary > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-primary > input:checked ~ span {
    background-color: var(--success); }
.checkbox.checkbox-light-primary > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-primary > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-primary > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span:after {
    border-color: var(--success); }

.checkbox.checkbox-secondary > span {
    border: 1px solid transparent; }

.checkbox.checkbox-secondary > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-secondary > input:checked ~ span {
    background-color: #E4E6EF; }
.checkbox.checkbox-secondary > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-secondary > span {
    background-color: #EBEDF3;
    border: 1px solid transparent; }

.checkbox.checkbox-light-secondary > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-secondary > input:checked ~ span {
    background-color: #E4E6EF; }
.checkbox.checkbox-light-secondary > input:checked ~ span:after {
    border-color: #3F4254; }

.checkbox.checkbox-outline.checkbox-secondary > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-secondary > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-secondary > input:checked ~ span {
    background-color: transparent;
    border-color: #E4E6EF; }
.checkbox.checkbox-outline.checkbox-secondary > input:checked ~ span:after {
    border-color: #E4E6EF; }

.checkbox.checkbox-success > span {
    border: 1px solid transparent; }

.checkbox.checkbox-success > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-success > input:checked ~ span {
    background-color: var(--success); }
.checkbox.checkbox-success > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-success > span {
    background-color: #E1F0FF;
    border: 1px solid transparent; }

.checkbox.checkbox-light-success > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-success > input:checked ~ span {
    background-color: var(--success); }
.checkbox.checkbox-light-success > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-success > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-success > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-success > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.checkbox.checkbox-outline.checkbox-success > input:checked ~ span:after {
    border-color: var(--success); }

.checkbox.checkbox-info > span {
    border: 1px solid transparent; }

.checkbox.checkbox-info > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-info > input:checked ~ span {
    background-color: #6993FF; }
.checkbox.checkbox-info > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-info > span {
    background-color: #E1E9FF;
    border: 1px solid transparent; }

.checkbox.checkbox-light-info > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-info > input:checked ~ span {
    background-color: #6993FF; }
.checkbox.checkbox-light-info > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-info > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-info > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-info > input:checked ~ span {
    background-color: transparent;
    border-color: #6993FF; }
.checkbox.checkbox-outline.checkbox-info > input:checked ~ span:after {
    border-color: #6993FF; }

.checkbox.checkbox-warning > span {
    border: 1px solid transparent; }

.checkbox.checkbox-warning > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-warning > input:checked ~ span {
    background-color: #FFA800; }
.checkbox.checkbox-warning > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-warning > span {
    background-color: #FFF4DE;
    border: 1px solid transparent; }

.checkbox.checkbox-light-warning > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-warning > input:checked ~ span {
    background-color: #FFA800; }
.checkbox.checkbox-light-warning > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-warning > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-warning > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-warning > input:checked ~ span {
    background-color: transparent;
    border-color: #FFA800; }
.checkbox.checkbox-outline.checkbox-warning > input:checked ~ span:after {
    border-color: #FFA800; }

.checkbox.checkbox-danger > span {
    border: 1px solid transparent; }

.checkbox.checkbox-danger > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-danger > input:checked ~ span {
    background-color: #F64E60; }
.checkbox.checkbox-danger > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-danger > span {
    background-color: #FFE2E5;
    border: 1px solid transparent; }

.checkbox.checkbox-light-danger > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-danger > input:checked ~ span {
    background-color: #F64E60; }
.checkbox.checkbox-light-danger > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-danger > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-danger > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-danger > input:checked ~ span {
    background-color: transparent;
    border-color: #F64E60; }
.checkbox.checkbox-outline.checkbox-danger > input:checked ~ span:after {
    border-color: #F64E60; }

.checkbox.checkbox-light > span {
    border: 1px solid transparent; }

.checkbox.checkbox-light > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light > input:checked ~ span {
    background-color: var(--elem-bg); }
.checkbox.checkbox-light > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-light > span {
    background-color: var(--elem-bg);
    border: 1px solid transparent; }

.checkbox.checkbox-light-light > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-light > input:checked ~ span {
    background-color: var(--elem-bg); }
.checkbox.checkbox-light-light > input:checked ~ span:after {
    border-color: #7E8299; }

.checkbox.checkbox-outline.checkbox-light > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-light > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-light > input:checked ~ span {
    background-color: transparent;
    border-color: var(--light-primary); }
.checkbox.checkbox-outline.checkbox-light > input:checked ~ span:after {
    border-color: var(--light-primary); }

.checkbox.checkbox-dark > span {
    border: 1px solid transparent; }

.checkbox.checkbox-dark > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-dark > input:checked ~ span {
    background-color: #181C32; }
.checkbox.checkbox-dark > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-dark > span {
    background-color: #D1D3E0;
    border: 1px solid transparent; }

.checkbox.checkbox-light-dark > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-dark > input:checked ~ span {
    background-color: #181C32; }
.checkbox.checkbox-light-dark > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-outline.checkbox-dark > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-dark > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-dark > input:checked ~ span {
    background-color: transparent;
    border-color: #181C32; }
.checkbox.checkbox-outline.checkbox-dark > input:checked ~ span:after {
    border-color: #181C32; }

.checkbox.checkbox-white > span {
    border: 1px solid transparent; }

.checkbox.checkbox-white > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-white > input:checked ~ span {
    background-color: white; }
.checkbox.checkbox-white > input:checked ~ span:after {
    border-color: white; }

.checkbox.checkbox-light-white > span {
    background-color: white;
    border: 1px solid transparent; }

.checkbox.checkbox-light-white > input:focus ~ span {
    border: 1px solid transparent; }

.checkbox.checkbox-light-white > input:checked ~ span {
    background-color: white; }
.checkbox.checkbox-light-white > input:checked ~ span:after {
    border-color: #3F4254; }

.checkbox.checkbox-outline.checkbox-white > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-white > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.checkbox.checkbox-outline.checkbox-white > input:checked ~ span {
    background-color: transparent;
    border-color: white; }
.checkbox.checkbox-outline.checkbox-white > input:checked ~ span:after {
    border-color: white; }

.checkbox-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.checkbox-inline .checkbox {
    margin-right: 1rem;
    margin-bottom: 0.35rem; }
.checkbox-inline .checkbox span {
    margin-right: 0.75rem; }
.checkbox-inline .checkbox:last-child {
    margin-right: 0; }

.checkbox-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
.checkbox-list .checkbox {
    margin-bottom: 1rem; }
.checkbox-list .checkbox span {
    margin-right: 0.75rem; }
.checkbox-list .checkbox:last-child {
    margin-bottom: 0; }

.input-group .checkbox {
    margin-bottom: 0 !important;
    padding-left: 0; }

.form-inline .checkbox {
    margin-left: 15px;
    margin-right: 15px; }

.radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0; }
.radio > span {
    border-radius: 50%;
    background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
.radio > span:after {
    content: '';
    display: none;
    border-radius: 100% !important; }
.radio > input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
.radio:hover > input:not([disabled]) ~ span,
.radio > input:focus ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
.radio.radio-disabled {
    opacity: 0.6;
    cursor: not-allowed; }
.radio > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: none; }
.radio > input:checked ~ span:after {
    display: block; }
.radio.radio-square > span {
    border-radius: 0; }
.radio.radio-rounded > span {
    border-radius: 0.42rem; }
.radio.radio-outline > span {
    border-width: 1px;
    border-style: solid; }
.radio.radio-outline-2x > span {
    border-width: 2px !important; }
.radio.radio-accent > span {
    border-width: 3px !important;
    border-style: solid !important;
    background-color: transparent !important; }
.radio.radio-accent > span:after {
    display: block; }

.radio > span {
    height: 18px;
    width: 18px; }
.radio > span:after {
    width: 6px;
    height: 6px; }

.radio.radio-lg > span {
    height: 24px;
    width: 24px; }
.radio.radio-lg > span:after {
    width: 8px;
    height: 8px; }

.radio.radio-outline > span {
    height: 18px;
    width: 18px; }
.radio.radio-outline > span:after {
    width: 6px;
    height: 6px; }

.radio.radio-outline.radio-lg > span {
    height: 24px;
    width: 24px; }
.radio.radio-outline.radio-lg > span:after {
    width: 8px;
    height: 8px; }

.radio.radio-accent > span {
    height: 24px;
    width: 24px; }
.radio.radio-accent > span:after {
    width: 14px;
    height: 14px; }

.radio.radio-accent.radio-lg > span {
    height: 30px;
    width: 30px; }
.radio.radio-accent.radio-lg > span:after {
    width: 10px;
    height: 10px; }

.radio > span {
    background-color: #EBEDF3;
    border: 1px solid transparent; }

.radio > input:focus ~ span {
    border: 1px solid transparent; }

.radio > input:checked ~ span {
    background-color: var(--success); }
.radio > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.radio-outline > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.radio.radio-outline > input:checked ~ span:after {
    background-color: var(--success);
    border-color: var(--success); }

.radio.radio-primary > span {
    border: 1px solid transparent; }

.radio.radio-primary > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-primary > input:checked ~ span {
    background-color: var(--success); }
.radio.radio-primary > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-primary > span {
    background-color: #C9F7F5;
    border: 1px solid transparent; }

.radio.checkbox-light-primary > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-primary > input:checked ~ span {
    background-color: var(--success); }
.radio.checkbox-light-primary > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-primary > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-primary > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-primary > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.radio.radio-outline.radio-primary > input:checked ~ span:after {
    background-color: var(--success);
    border-color: var(--success); }

.radio.radio-accent.radio-primary > span:after {
    background-color: var(--success); }

.radio.radio-accent.radio-primary > input:focus ~ span {
    border-color: #C9F7F5; }
.radio.radio-accent.radio-primary > input:focus ~ span:after {
    background-color: var(--success); }

.radio.radio-accent.radio-primary > input:checked ~ span {
    border-color: #C9F7F5; }
.radio.radio-accent.radio-primary > input:checked ~ span:after {
    background-color: var(--success); }

.radio.radio-secondary > span {
    border: 1px solid transparent; }

.radio.radio-secondary > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-secondary > input:checked ~ span {
    background-color: #E4E6EF; }
.radio.radio-secondary > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-secondary > span {
    background-color: #EBEDF3;
    border: 1px solid transparent; }

.radio.checkbox-light-secondary > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-secondary > input:checked ~ span {
    background-color: #E4E6EF; }
.radio.checkbox-light-secondary > input:checked ~ span:after {
    border-color: #3F4254; }

.radio.radio-outline.radio-secondary > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-secondary > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-secondary > input:checked ~ span {
    background-color: transparent;
    border-color: #E4E6EF; }
.radio.radio-outline.radio-secondary > input:checked ~ span:after {
    background-color: #E4E6EF;
    border-color: #E4E6EF; }

.radio.radio-accent.radio-secondary > span:after {
    background-color: #E4E6EF; }

.radio.radio-accent.radio-secondary > input:focus ~ span {
    border-color: #EBEDF3; }
.radio.radio-accent.radio-secondary > input:focus ~ span:after {
    background-color: #E4E6EF; }

.radio.radio-accent.radio-secondary > input:checked ~ span {
    border-color: #EBEDF3; }
.radio.radio-accent.radio-secondary > input:checked ~ span:after {
    background-color: #E4E6EF; }

.radio.radio-success > span {
    border: 1px solid transparent; }

.radio.radio-success > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-success > input:checked ~ span {
    background-color: var(--success); }
.radio.radio-success > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-success > span {
    background-color: #E1F0FF;
    border: 1px solid transparent; }

.radio.checkbox-light-success > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-success > input:checked ~ span {
    background-color: var(--success); }
.radio.checkbox-light-success > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-success > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-success > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-success > input:checked ~ span {
    background-color: transparent;
    border-color: var(--success); }
.radio.radio-outline.radio-success > input:checked ~ span:after {
    background-color: var(--success);
    border-color: var(--success); }

.radio.radio-accent.radio-success > span:after {
    background-color: var(--success); }

.radio.radio-accent.radio-success > input:focus ~ span {
    border-color: #E1F0FF; }
.radio.radio-accent.radio-success > input:focus ~ span:after {
    background-color: var(--success); }

.radio.radio-accent.radio-success > input:checked ~ span {
    border-color: #E1F0FF; }
.radio.radio-accent.radio-success > input:checked ~ span:after {
    background-color: var(--success); }

.radio.radio-info > span {
    border: 1px solid transparent; }

.radio.radio-info > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-info > input:checked ~ span {
    background-color: #6993FF; }
.radio.radio-info > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-info > span {
    background-color: #E1E9FF;
    border: 1px solid transparent; }

.radio.checkbox-light-info > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-info > input:checked ~ span {
    background-color: #6993FF; }
.radio.checkbox-light-info > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-info > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-info > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-info > input:checked ~ span {
    background-color: transparent;
    border-color: #6993FF; }
.radio.radio-outline.radio-info > input:checked ~ span:after {
    background-color: #6993FF;
    border-color: #6993FF; }

.radio.radio-accent.radio-info > span:after {
    background-color: #6993FF; }

.radio.radio-accent.radio-info > input:focus ~ span {
    border-color: #E1E9FF; }
.radio.radio-accent.radio-info > input:focus ~ span:after {
    background-color: #6993FF; }

.radio.radio-accent.radio-info > input:checked ~ span {
    border-color: #E1E9FF; }
.radio.radio-accent.radio-info > input:checked ~ span:after {
    background-color: #6993FF; }

.radio.radio-warning > span {
    border: 1px solid transparent; }

.radio.radio-warning > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-warning > input:checked ~ span {
    background-color: #FFA800; }
.radio.radio-warning > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-warning > span {
    background-color: #FFF4DE;
    border: 1px solid transparent; }

.radio.checkbox-light-warning > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-warning > input:checked ~ span {
    background-color: #FFA800; }
.radio.checkbox-light-warning > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-warning > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-warning > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-warning > input:checked ~ span {
    background-color: transparent;
    border-color: #FFA800; }
.radio.radio-outline.radio-warning > input:checked ~ span:after {
    background-color: #FFA800;
    border-color: #FFA800; }

.radio.radio-accent.radio-warning > span:after {
    background-color: #FFA800; }

.radio.radio-accent.radio-warning > input:focus ~ span {
    border-color: #FFF4DE; }
.radio.radio-accent.radio-warning > input:focus ~ span:after {
    background-color: #FFA800; }

.radio.radio-accent.radio-warning > input:checked ~ span {
    border-color: #FFF4DE; }
.radio.radio-accent.radio-warning > input:checked ~ span:after {
    background-color: #FFA800; }

.radio.radio-danger > span {
    border: 1px solid transparent; }

.radio.radio-danger > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-danger > input:checked ~ span {
    background-color: #F64E60; }
.radio.radio-danger > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-danger > span {
    background-color: #FFE2E5;
    border: 1px solid transparent; }

.radio.checkbox-light-danger > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-danger > input:checked ~ span {
    background-color: #F64E60; }
.radio.checkbox-light-danger > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-danger > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-danger > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-danger > input:checked ~ span {
    background-color: transparent;
    border-color: #F64E60; }
.radio.radio-outline.radio-danger > input:checked ~ span:after {
    background-color: #F64E60;
    border-color: #F64E60; }

.radio.radio-accent.radio-danger > span:after {
    background-color: #F64E60; }

.radio.radio-accent.radio-danger > input:focus ~ span {
    border-color: #FFE2E5; }
.radio.radio-accent.radio-danger > input:focus ~ span:after {
    background-color: #F64E60; }

.radio.radio-accent.radio-danger > input:checked ~ span {
    border-color: #FFE2E5; }
.radio.radio-accent.radio-danger > input:checked ~ span:after {
    background-color: #F64E60; }

.radio.radio-light > span {
    border: 1px solid transparent; }

.radio.radio-light > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-light > input:checked ~ span {
    background-color: var(--elem-bg); }
.radio.radio-light > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-light > span {
    background-color: var(--elem-bg);
    border: 1px solid transparent; }

.radio.checkbox-light-light > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-light > input:checked ~ span {
    background-color: var(--elem-bg); }
.radio.checkbox-light-light > input:checked ~ span:after {
    border-color: #7E8299; }

.radio.radio-outline.radio-light > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-light > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-light > input:checked ~ span {
    background-color: transparent;
    border-color: var(--light-primary); }
.radio.radio-outline.radio-light > input:checked ~ span:after {
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }

.radio.radio-accent.radio-light > span:after {
    background-color: var(--elem-bg); }

.radio.radio-accent.radio-light > input:focus ~ span {
    border-color: var(--elem-bg); }
.radio.radio-accent.radio-light > input:focus ~ span:after {
    background-color: var(--elem-bg); }

.radio.radio-accent.radio-light > input:checked ~ span {
    border-color: var(--elem-bg); }
.radio.radio-accent.radio-light > input:checked ~ span:after {
    background-color: var(--elem-bg); }

.radio.radio-dark > span {
    border: 1px solid transparent; }

.radio.radio-dark > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-dark > input:checked ~ span {
    background-color: #181C32; }
.radio.radio-dark > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-dark > span {
    background-color: #D1D3E0;
    border: 1px solid transparent; }

.radio.checkbox-light-dark > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-dark > input:checked ~ span {
    background-color: #181C32; }
.radio.checkbox-light-dark > input:checked ~ span:after {
    border-color: white; }

.radio.radio-outline.radio-dark > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-dark > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-dark > input:checked ~ span {
    background-color: transparent;
    border-color: #181C32; }
.radio.radio-outline.radio-dark > input:checked ~ span:after {
    background-color: #181C32;
    border-color: #181C32; }

.radio.radio-accent.radio-dark > span:after {
    background-color: #181C32; }

.radio.radio-accent.radio-dark > input:focus ~ span {
    border-color: #D1D3E0; }
.radio.radio-accent.radio-dark > input:focus ~ span:after {
    background-color: #181C32; }

.radio.radio-accent.radio-dark > input:checked ~ span {
    border-color: #D1D3E0; }
.radio.radio-accent.radio-dark > input:checked ~ span:after {
    background-color: #181C32; }

.radio.radio-white > span {
    border: 1px solid transparent; }

.radio.radio-white > input:focus ~ span {
    border: 1px solid transparent; }

.radio.radio-white > input:checked ~ span {
    background-color: white; }
.radio.radio-white > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.checkbox-light-white > span {
    background-color: white;
    border: 1px solid transparent; }

.radio.checkbox-light-white > input:focus ~ span {
    border: 1px solid transparent; }

.radio.checkbox-light-white > input:checked ~ span {
    background-color: white; }
.radio.checkbox-light-white > input:checked ~ span:after {
    border-color: #3F4254; }

.radio.radio-outline.radio-white > span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-white > input:focus ~ span {
    background-color: transparent;
    border-color: #D1D3E0; }

.radio.radio-outline.radio-white > input:checked ~ span {
    background-color: transparent;
    border-color: white; }
.radio.radio-outline.radio-white > input:checked ~ span:after {
    background-color: white;
    border-color: white; }

.radio.radio-accent.radio-white > span:after {
    background-color: white; }

.radio.radio-accent.radio-white > input:focus ~ span {
    border-color: white; }
.radio.radio-accent.radio-white > input:focus ~ span:after {
    background-color: white; }

.radio.radio-accent.radio-white > input:checked ~ span {
    border-color: white; }
.radio.radio-accent.radio-white > input:checked ~ span:after {
    background-color: white; }

.radio-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.radio-inline .radio {
    margin-right: 1rem;
    margin-bottom: 0.35rem; }
.radio-inline .radio span {
    margin-right: 0.75rem; }
.radio-inline .radio:last-child {
    margin-right: 0; }

.radio-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
.radio-list .radio {
    margin-bottom: 1rem; }
.radio-list .radio span {
    margin-right: 0.75rem; }
.radio-list .radio:last-child {
    margin-bottom: 0; }

.input-group .radio {
    margin-bottom: 0 !important;
    padding-left: 0; }

.form-inline .radio {
    margin-left: 15px;
    margin-right: 15px; }

.switch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem; }
.switch label {
    margin: 0; }
.switch input:empty {
    margin-left: -999px;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0; }
.switch input:empty ~ span {
    display: inline-block;
    position: relative;
    float: left;
    width: 1px;
    text-indent: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    content: ' ';
    -webkit-transition: all 100ms ease-in;
    transition: all 100ms ease-in; }
.switch input[disabled] {
    cursor: not-allowed; }
.switch input[disabled] ~ span:after,
.switch input[disabled] ~ span:before {
    cursor: not-allowed;
    opacity: 0.5; }
.switch.switch-icon input:checked ~ span:after {
    font-family: Ki;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "";
    line-height: 0; }

.switch input:empty ~ span {
    margin: 2px 0;
    height: 30px;
    width: 57px;
    border-radius: 15px; }

.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
    width: 54px;
    border-radius: 15px; }

.switch input:empty ~ span:after {
    height: 24px;
    width: 24px;
    top: 3px;
    bottom: 3px;
    margin-left: 3px;
    font-size: 0.65em;
    text-align: center;
    vertical-align: middle; }

.switch input:checked ~ span:after {
    margin-left: 26px; }

.switch.switch-sm input:empty ~ span {
    margin: 2px 0;
    height: 24px;
    width: 40px;
    border-radius: 12px; }

.switch.switch-sm input:empty ~ span:before,
.switch.switch-sm input:empty ~ span:after {
    width: 38px;
    border-radius: 12px; }

.switch.switch-sm input:empty ~ span:after {
    height: 20px;
    width: 20px;
    top: 2px;
    bottom: 2px;
    margin-left: 2px;
    font-size: 0.55em;
    text-align: center;
    vertical-align: middle; }

.switch.switch-sm input:checked ~ span:after {
    margin-left: 16px; }

.switch.switch-lg input:empty ~ span {
    margin: 2px 0;
    height: 40px;
    width: 75px;
    border-radius: 20px; }

.switch.switch-lg input:empty ~ span:before,
.switch.switch-lg input:empty ~ span:after {
    width: 72px;
    border-radius: 20px; }

.switch.switch-lg input:empty ~ span:after {
    height: 34px;
    width: 34px;
    top: 3px;
    bottom: 3px;
    margin-left: 3px;
    font-size: 0.75em;
    text-align: center;
    vertical-align: middle; }

.switch.switch-lg input:checked ~ span:after {
    margin-left: 34px; }

.switch input:empty ~ span:before {
    background-color: var(--elem-bg); }

.switch input:empty ~ span:after {
    background-color: var(--hover-primary);
    opacity: 0.7; }

.switch input:checked ~ span:before {
    background-color: var(--elem-bg); }

.switch input:checked ~ span:after {
    opacity: 1;
    color: white;
    background-color: var(--success); }

.switch.switch-primary:not(.switch-outline) input:empty ~ span:before {
    background-color: var(--success); }

.switch.switch-primary:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-primary:not(.switch-outline) input:checked ~ span:before {
    background-color: var(--success); }

.switch.switch-primary:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: var(--success);
    background-color: white; }

.switch.switch-outline.switch-primary input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-primary input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-primary input:checked ~ span:before {
    border: 2px solid var(--success);
    background-color: transparent; }

.switch.switch-outline.switch-primary input:checked ~ span:after {
    color: white;
    background-color: var(--success); }

.switch.switch-secondary:not(.switch-outline) input:empty ~ span:before {
    background-color: #E4E6EF; }

.switch.switch-secondary:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-secondary:not(.switch-outline) input:checked ~ span:before {
    background-color: #E4E6EF; }

.switch.switch-secondary:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #E4E6EF;
    background-color: white; }

.switch.switch-outline.switch-secondary input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-secondary input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-secondary input:checked ~ span:before {
    border: 2px solid #E4E6EF;
    background-color: transparent; }

.switch.switch-outline.switch-secondary input:checked ~ span:after {
    color: white;
    background-color: #E4E6EF; }

.switch.switch-success:not(.switch-outline) input:empty ~ span:before {
    background-color: var(--success); }

.switch.switch-success:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-success:not(.switch-outline) input:checked ~ span:before {
    background-color: var(--success); }

.switch.switch-success:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: var(--success);
    background-color: white; }

.switch.switch-outline.switch-success input:empty ~ span:before {
    border: 2px solid var(--hover-primary);
    background-color: transparent; }

.switch.switch-outline.switch-success input:empty ~ span:after {
    background-color: var(--hover-primary); }

.switch.switch-outline.switch-success input:checked ~ span:before {
    border: 2px solid var(--success);
    background-color: transparent; }

.switch.switch-outline.switch-success input:checked ~ span:after {
    color: white;
    background-color: var(--success); }

.switch.switch-info:not(.switch-outline) input:empty ~ span:before {
    background-color: #6993FF; }

.switch.switch-info:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-info:not(.switch-outline) input:checked ~ span:before {
    background-color: #6993FF; }

.switch.switch-info:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #6993FF;
    background-color: white; }

.switch.switch-outline.switch-info input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-info input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-info input:checked ~ span:before {
    border: 2px solid #6993FF;
    background-color: transparent; }

.switch.switch-outline.switch-info input:checked ~ span:after {
    color: white;
    background-color: #6993FF; }

.switch.switch-warning:not(.switch-outline) input:empty ~ span:before {
    background-color: #FFA800; }

.switch.switch-warning:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-warning:not(.switch-outline) input:checked ~ span:before {
    background-color: #FFA800; }

.switch.switch-warning:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #FFA800;
    background-color: white; }

.switch.switch-outline.switch-warning input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-warning input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-warning input:checked ~ span:before {
    border: 2px solid #FFA800;
    background-color: transparent; }

.switch.switch-outline.switch-warning input:checked ~ span:after {
    color: white;
    background-color: #FFA800; }

.switch.switch-danger:not(.switch-outline) input:empty ~ span:before {
    background-color: #F64E60; }

.switch.switch-danger:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-danger:not(.switch-outline) input:checked ~ span:before {
    background-color: #F64E60; }

.switch.switch-danger:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #F64E60;
    background-color: white; }

.switch.switch-outline.switch-danger input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-danger input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-danger input:checked ~ span:before {
    border: 2px solid #F64E60;
    background-color: transparent; }

.switch.switch-outline.switch-danger input:checked ~ span:after {
    color: white;
    background-color: #F64E60; }

.switch.switch-light:not(.switch-outline) input:empty ~ span:before {
    background-color: var(--elem-bg); }

.switch.switch-light:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-light:not(.switch-outline) input:checked ~ span:before {
    background-color: var(--elem-bg); }

.switch.switch-light:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #F3F6F9;
    background-color: white; }

.switch.switch-outline.switch-light input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-light input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-light input:checked ~ span:before {
    border: 2px solid var(--light-primary);
    background-color: transparent; }

.switch.switch-outline.switch-light input:checked ~ span:after {
    color: white;
    background-color: var(--elem-bg); }

.switch.switch-dark:not(.switch-outline) input:empty ~ span:before {
    background-color: #181C32; }

.switch.switch-dark:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-dark:not(.switch-outline) input:checked ~ span:before {
    background-color: #181C32; }

.switch.switch-dark:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: #181C32;
    background-color: white; }

.switch.switch-outline.switch-dark input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-dark input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-dark input:checked ~ span:before {
    border: 2px solid #181C32;
    background-color: transparent; }

.switch.switch-outline.switch-dark input:checked ~ span:after {
    color: white;
    background-color: #181C32; }

.switch.switch-white:not(.switch-outline) input:empty ~ span:before {
    background-color: white; }

.switch.switch-white:not(.switch-outline) input:empty ~ span:after {
    background-color: white;
    opacity: 0.7; }

.switch.switch-white:not(.switch-outline) input:checked ~ span:before {
    background-color: white; }

.switch.switch-white:not(.switch-outline) input:checked ~ span:after {
    opacity: 1;
    color: white;
    background-color: white; }

.switch.switch-outline.switch-white input:empty ~ span:before {
    border: 2px solid #EBEDF3;
    background-color: transparent; }

.switch.switch-outline.switch-white input:empty ~ span:after {
    background-color: #EBEDF3; }

.switch.switch-outline.switch-white input:checked ~ span:before {
    border: 2px solid white;
    background-color: transparent; }

.switch.switch-outline.switch-white input:checked ~ span:after {
    color: white;
    background-color: white; }

.option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 1.4em;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem; }
.option .option-control {
    width: 2.7rem;
    padding-top: 0.1rem; }
.option .option-control .kt-radio {
    display: block; }
.option .option-label {
    width: 100%; }
.option .option-label .option-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
.option .option-label .option-head .option-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #3F4254; }
.option .option-label .option-head .option-focus {
    font-size: 1.1rem;
    font-weight: 600;
    color: #181C32; }
.option .option-label .option-body {
    display: block;
    padding-top: 0.7rem;
    font-size: 0.9rem;
    color: #B5B5C3; }
.option.option-plain {
    border: 0;
    padding: 0;
    margin-top: -0.2rem;
    margin-bottom: 2rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.option.option-plain .option-control {
    vertical-align: middle;
    width: 2.7rem; }
.option.option-plain .option-control .radio {
    margin-bottom: 1.6rem; }
.option.option-plain .option-label .option-body {
    padding-top: 0.2rem; }

.dialog {
    width: 130px;
    margin: 0 auto;
    position: fixed;
    z-index: 1100;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
    -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 0.42rem; }
.dialog.dialog-top-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: -100px;
    left: 50%;
    margin-left: -65px;
    -webkit-transition: top 0.6s ease;
    transition: top 0.6s ease;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
.dialog.dialog-top-center.dialog-shown {
    top: 0px;
    -webkit-transition: top 0.6s ease;
    transition: top 0.6s ease; }
.dialog.dialog-loader {
    font-size: 1rem;
    padding: 0.75rem;
    font-weight: 400;
    color: #3F4254; }

.strikethrough {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; }
.strikethrough span {
    position: relative;
    display: inline-block;
    color: #B5B5C3;
    font-weight: 500; }
.strikethrough span:before, .strikethrough span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #EBEDF3; }
.strikethrough span:before {
    right: 100%;
    margin-right: 15px; }
.strikethrough span:after {
    left: 100%;
    margin-left: 15px; }

.separator {
    height: 0; }
.separator.separator-dashed {
    border-bottom: 1px dashed #EBEDF3; }
.separator.separator-solid {
    border-bottom: 1px solid #EBEDF3; }
.separator.separator-border-2 {
    border-bottom-width: 2px; }
.separator.separator-border-3 {
    border-bottom-width: 3px; }
.separator.separator-border-4 {
    border-bottom-width: 4px; }
.separator.separator-primary {
    border-bottom-color: var(--success); }
.separator.separator-secondary {
    border-bottom-color: #E4E6EF; }
.separator.separator-success {
    border-bottom-color: var(--success); }
.separator.separator-info {
    border-bottom-color: #6993FF; }
.separator.separator-warning {
    border-bottom-color: #FFA800; }
.separator.separator-danger {
    border-bottom-color: #F64E60; }
.separator.separator-light {
    border-bottom-color: var(--light-primary); }
.separator.separator-dark {
    border-bottom-color: #181C32; }
.separator.separator-white {
    border-bottom-color: white; }
