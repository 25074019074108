@charset "UTF-8";
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none; } }
.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #3F4254; }
.form-control:focus:not(textarea) {
    color: #3F4254;
    background-color: var(--elem-bg) !important;
    border-color: white;
    outline: 2px solid var(--originaltwo); }
.form-control::-webkit-input-placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control::-moz-placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control:-ms-input-placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control::-ms-input-placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control::placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control:disabled, .form-control[readonly] {
    background-color: var(--elem-bg);
    opacity: 1; }

input[type=date]::-webkit-calendar-picker-indicator {
    opacity: .3;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

.form-control-custom:focus {
    border: none;
}

select.form-control:focus::-ms-value {
    color: #3F4254;
    background-color: white; }

.form-control-file,
.form-control-range {
    display: block;
    width: 100%; }

.col-form-label {
    padding-top: calc(0.65rem + 1px);
    padding-bottom: calc(0.65rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }

.col-form-label-lg {
    padding-top: calc(0.825rem + 1px);
    padding-bottom: calc(0.825rem + 1px);
    font-size: 1.08rem;
    line-height: 1.5; }

.col-form-label-sm {
    padding-top: calc(0.55rem + 1px);
    padding-bottom: calc(0.55rem + 1px);
    font-size: 0.925rem;
    line-height: 1.35; }

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.65rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #3F4254;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem; }

.form-control-lg {
    height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem; }

select.form-control[size], select.form-control[multiple] {
    height: auto; }

textarea.form-control {
    height: auto;}

textarea.form-control:focus {
    border-color: var(--originaltwo);
}

.form-group {
    margin-bottom: 1.75rem; }

.form-text {
    display: block;
    margin-top: 0.25rem; }

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    color: #B5B5C3; }

.form-check-label {
    margin-bottom: 0; }

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.9rem;
    color: var(--success); }

.valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.75rem 1rem;
    margin-top: .1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: white;
    background-color: var(--success);
    border-radius: 3px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
    border-color: var(--success);
    padding-right: calc(1.5em + 1.3rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233699FF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem); }
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--success);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.3rem);
    background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
    border-color: var(--success);
    padding-right: calc(0.75em + 2.975rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233699FF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") white no-repeat center right 2rem/calc(0.75em + 0.65rem) calc(0.75em + 0.65rem); }
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: var(--success);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--success); }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: var(--success); }
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: var(--success); }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #69b3ff;
    background-color: #69b3ff; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--success); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: var(--success); }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: var(--success);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25); }

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.9rem;
    color: #F64E60; }

.invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.75rem 1rem;
    margin-top: .1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: white;
    background-color: #f64e60;
    border-radius: 3px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #F64E60;
    padding-right: calc(1.5em + 1.3rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.325rem) center;
    background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem); }
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #F64E60;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.3rem);
    background-position: top calc(0.375em + 0.325rem) right calc(0.375em + 0.325rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: #F64E60;
    padding-right: calc(0.75em + 2.975rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233F4254' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e") white no-repeat center right 2rem/calc(0.75em + 0.65rem) calc(0.75em + 0.65rem); }
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F64E60;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #F64E60; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #F64E60; }
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F64E60; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #f87f8c;
    background-color: #f87f8c; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #F64E60; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #F64E60; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #F64E60;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.25); }

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.form-inline .form-check {
    width: 100%; }
@media (min-width: 576px) {
    .form-inline label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0; }
    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0; }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle; }
    .form-inline .form-control-plaintext {
        display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto; }
    .form-inline .form-check {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0; }
    .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0; }
    .form-inline .custom-control {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
    .form-inline .custom-control-label {
        margin-bottom: 0; } }


.form-group label {
    font-size: 1rem;
    font-weight: 400;
    color: #3F4254; }

.form-group .invalid-feedback,
.form-group .valid-feedback {
    font-size: 0.9rem;
    font-weight: 400; }

.form-group .form-text {
    font-size: 0.9rem;
    font-weight: 400; }

.form-control[readonly] {
    background-color: white; }

.form-control.form-control-pill {
    border-radius: 1.5; }
.form-control.form-control-pill.form-control-sm {
    border-radius: 1.35; }
.form-control.form-control-pill.form-control-lg {
    border-radius: 1.5; }

.form-control:active, .form-control.active, .form-control:focus, .form-control.focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.form-control.form-control-solid {
    background-color: var(--light-primary);
    border-color: var(--light-primary);
    color: #3F4254;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }


.form-control.form-control-solid::-moz-placeholder {
    color: #B5B5C3;
    opacity: 1; }
.form-control.form-control-solid:-ms-input-placeholder {
    color: #B5B5C3; }
.form-control.form-control-solid::-webkit-input-placeholder {
    color: #B5B5C3; }
.form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus {
    background-color: #EBEDF3;
    border-color: #EBEDF3;
    color: #3F4254;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }

.form-control-iconless {
    background-image: none; }
.form-control-iconless .form-control {
    background-image: none; }

.placeholder-dark-50::-moz-placeholder {
    color: #7E8299;
    opacity: 1; }

.placeholder-dark-50:-ms-input-placeholder {
    color: #7E8299; }

.placeholder-dark-50::-webkit-input-placeholder {
    color: #7E8299; }

.placeholder-dark-75::-moz-placeholder {
    color: #3F4254;
    opacity: 1; }

.placeholder-dark-75:-ms-input-placeholder {
    color: #3F4254; }

.placeholder-dark-75::-webkit-input-placeholder {
    color: #3F4254; }

.placeholder-white::-moz-placeholder {
    color: white;
    opacity: 1; }

.placeholder-white:-ms-input-placeholder {
    color: white; }

.placeholder-white::-webkit-input-placeholder {
    color: white; }

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

.custom-file {
    width: 100%; }

.custom-file-input:focus ~ .custom-file-label {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.custom-file-label {
    text-align: left; }
.custom-file-label:after {
    float: left; }

.input-group .form-control.is-valid + .input-group-append,
.input-group .form-control.is-invalid + .input-group-append {
    margin-left: 0; }

.input-group .input-group-prepend + .form-control.is-valid,
.input-group .input-group-prepend + .form-control.is-invalid {
    margin-left: 1px; }

.input-group i {
    line-height: 0;
    font-size: 1.3rem; }
.input-group i:after, .input-group i:before {
    line-height: 0; }

.input-group .svg-icon {
    line-height: 0; }

.input-group.input-group-sm i {
    line-height: 0;
    font-size: 1rem; }

.input-group.input-group-lg i {
    line-height: 0;
    font-size: 1.4rem; }

.input-group.input-group-solid {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #F3F6F9;
    border-radius: 0.42rem; }
.input-group.input-group-solid.active, .input-group.input-group-solid.focus {
    background-color: #EBEDF3;
    border-color: #EBEDF3;
    color: #3F4254;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.input-group.input-group-solid .form-control {
    border: 0;
    background-color: transparent;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
.input-group.input-group-solid .form-control.form-control-solid:active, .input-group.input-group-solid .form-control.form-control-solid.active, .input-group.input-group-solid .form-control.form-control-solid:focus, .input-group.input-group-solid .form-control.form-control-solid.focus {
    background-color: #EBEDF3;
    border-color: #EBEDF3;
    color: #3F4254;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.input-group.input-group-solid.input-group-sm {
    border-radius: 0.28rem; }
.input-group.input-group-solid.input-group-lg {
    border-radius: 0.42rem; }
.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-text {
    background-color: transparent;
    border: 0;
    padding-top: 0;
    padding-bottom: 0; }
.input-group.input-group-solid .input-group-prepend .btn-secondary,
.input-group.input-group-solid .input-group-append .btn-secondary,
.input-group.input-group-solid .input-group-text .btn-secondary {
    border: 0 !important; }
.input-group.input-group-solid .input-group-prepend ~ .form-control {
    padding-left: 0 !important; }

.validated .valid-feedback,
.validated .invalid-feedback {
    display: block; }

.input-icon {
    position: relative; }
.input-icon span {
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(1.5em + 1.3rem + 2px); }
.input-icon span i {
    line-height: 0;
    color: #7E8299; }
.input-icon span i:after, .input-icon span i:before {
    line-height: 0; }
.input-icon span .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.input-icon span .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.input-icon span .svg-icon svg {
    height: 24px;
    width: 24px; }
.input-icon .form-control {
    margin: 2px;
    padding-left: calc(1.5em + 1.3rem + 2px); }
.input-icon.input-icon-right span {
    right: 0;
    left: auto; }
.input-icon.input-icon-right .form-control {
    padding-left: 1rem;
    padding-right: calc(1.5em + 1.3rem + 2px); }

.input-icon1 {
    position: relative; }
.input-icon1 span {
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(1.5em + 1.3rem + 2px); }
.input-icon1 span i {
    line-height: 0;
    color: #7E8299; }
.input-icon1 span i:after, .input-icon1 span i:before {
    line-height: 0; }
.input-icon1 span .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.input-icon1 span .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.input-icon1 span .svg-icon svg {
    height: 24px;
    width: 24px; }
.input-icon1 .form-control {
    padding-left: calc(1.5em + 1.3rem + 2px); }
.input-icon1.input-icon-right1 span {
    right: 0;
    left: auto; }
.input-icon1.input-icon-right1 .form-control {
    padding-left: 1rem;
    padding-right: calc(1.5em + 1.3rem + 2px); }


.resize-none {
    resize: none; }

.row.row-paddingless {
    margin-left: 0;
    margin-right: 0; }
.row.row-paddingless > [class*="col"] {
    padding-left: 0;
    padding-right: 0; }

.row.row-stretch {
    height: 100%; }

.row .col-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }

.row[class*="row-col-divider-"] > div {
    border-bottom: 1px solid #EBEDF3; }
.row[class*="row-col-divider-"] > div:last-child {
    border-bottom: 0; }

.row.row-col-divider > div {
    border-bottom: 0;
    border-right: 1px solid #EBEDF3; }
.row.row-col-divider > div:last-child {
    border-right: 0; }

@media (min-width: 576px) {
    .row.row-col-divider-sm > div {
        border-bottom: 0;
        border-right: 1px solid #EBEDF3; }
    .row.row-col-divider-sm > div:last-child {
        border-right: 0; } }

@media (min-width: 768px) {
    .row.row-col-divider-md > div {
        border-bottom: 0;
        border-right: 1px solid #EBEDF3; }
    .row.row-col-divider-md > div:last-child {
        border-right: 0; } }

@media (min-width: 992px) {
    .row.row-col-divider-lg > div {
        border-bottom: 0;
        border-right: 1px solid #EBEDF3; }
    .row.row-col-divider-lg > div:last-child {
        border-right: 0; } }

@media (min-width: 1200px) {
    .row.row-col-divider-xl > div {
        border-bottom: 0;
        border-right: 1px solid #EBEDF3; }
    .row.row-col-divider-xl > div:last-child {
        border-right: 0; } }

@media (min-width: 1400px) {
    .row.row-col-divider-xxl > div {
        border-bottom: 0;
        border-right: 1px solid #EBEDF3; }
    .row.row-col-divider-xxl > div:last-child {
        border-right: 0; } }

.gutter-b {
    margin-bottom: 25px; }

.gutter-t {
    margin-top: 25px; }

.form-control-file button {
    background-color: #7F9CCB;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px ridge black;
    font-size: 0.8rem;
    height: auto;
}
