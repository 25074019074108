@charset "UTF-8";

.arrow_m {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 0;
    flex: 0 0 20px;
    font-size: 0.6rem;
}

.arrow_m::before {
    transition: all 0.3s ease;
    font-family: all-font;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "\e940";
    transform: rotate(90deg);

}

.arrow-right {
    transition: all 0.3s ease;
}

.arrow-right::before {
    transform: rotateZ(1deg);
}

.arrow_m .arrow-top {
    transition: all 0.3s ease;
}

.arrow_m .arrow-top::before {
    transform: rotateZ(270deg);
}

.arrow_m .arrow-bottom {
    transition: all 0.3s ease;
}

.arrow_m .arrow-bottom::before {
    transform: rotateZ(90deg);
}

.arrow_m .arrow-left {
    transition: all 0.3s ease;
}

.arrow_m .arrow-left::before {
    transform: rotateZ(180deg);
}

