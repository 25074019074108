@charset "UTF-8";

.icon-color-dark {
    color: #000000 !important;
}

.icon-color-secondary {
    color: var(--icon-secondary) !important;
}

.icon-color-secondary-active {
    color: var(--icon-secondary-active) !important;
}

.icon-color-original {
    color: var(--originaltwo) !important
}

.icon-color-primary {
    color: var(--icon-primary)!important;
}

.icon-color-primary-light {
    color: var(--original-light)!important;
}

.icon-color-primary-dark {
    color: var(--success) !important;
}

.icon-color-white {
    color: #ffffff !important;
}

.icon-color-red {
    color: #ff3939 !important;
}

.icon-color-gold {
    color: #FFA800 !important;
}

.icon-color-danger {
    color: #f58a18 !important;
}

.icon-color-cyan {
    color: #2efb35 !important;
}

.text-color-dark {
    color: #000000 !important;
}

.text-color-secondary-active {
    color: var(--text-secondary-active) !important;
}

.text-color-primary-dark {
    color: var(--success) !important;
}

.text-color-primary-light {
    color: var(--original-light) !important;
}

.text-color-original {
    color: var(--originaltwo) !important;
}
.text-color-primary {
    color: var(--text-primary) !important;
}

.text-color-secondary {
    color: var(--text-secondary) !important;
}

.text-color-red {
    color: #ff3939 !important;
}

.text-color-danger {
    color: #f58a18 !important;
}

.text-color-cyan {
    color: #2efb35 !important;
}

.color-white-is-hover:hover{
    color : #fff !important;
}

.icon-color-white-is-hover span i:hover{
    color : #fff !important;
}

.icon-primary {
    color: var(--icon-primary) !important;
}
