@charset "UTF-8";
.toast {
    -ms-flex-preferred-size: 350px;
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #3F4254;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 1px solid #EBEDF3;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: white;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.42rem;
    -webkit-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.2;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 1.75rem;
    border-bottom: 1px solid #EBEDF3;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}

.modal-header .close {
    padding: 1.5rem 1.75rem;
    margin: -1.5rem -1.75rem -1.5rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.75rem;
}

.adaptive-ChangeVersionDocInPackage {
    height: 60vh;
}

@media (max-height: 1000px) {
    .adaptive-ChangeVersionDocInPackage {
        height: 70vh;
    }

}
@media (max-height: 850px) {
    .adaptive-ChangeVersionDocInPackage {
        height: 75vh;
    }

}
@media (max-height: 793px) {
    .adaptive-ChangeVersionDocInPackage {
        height: 90vh;
    }

}
.modal-body-departments {
    height: 30vw;
    padding: 0.8rem 1.75rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;

    padding: 1.5rem;
    border-top: 1px solid #EBEDF3;
    border-bottom-right-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem;
}

.modal-footer > * {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}


.modal-custom li {
    list-style-type: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content;
    }

    .modal-content {
        -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}


.modal .modal-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal .modal-header .modal-title {
    font-weight: 500;
    font-size: 1.3rem;
    color: #181C32;
}

.modal .modal-header .modal-title small {
    font-weight: 400;
    font-size: 0.9rem;
    color: #B5B5C3;
}

.modal .modal-header .close {
    outline: none !important;
    color: #B5B5C3;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    padding: 0;
    margin: 0;
    line-height: 0;
}

.modal .modal-header .close i,
.modal .modal-header .close .ki {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #3F4254;
    font-size: 0.8rem;
}

.modal .modal-header .close span {
    display: none;
}

.modal .modal-header .close:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #1BC5BD;
}

.modal .modal-header .close:hover i,
.modal .modal-header .close:hover .ki {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #1BC5BD;
}

.modal.modal-sticky.modal-sticky-bottom-right {
    padding: 0 !important;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: auto;
    bottom: 0px;
    right: 0px;
    margin: 0;
    -webkit-box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
    border-radius: 0.42rem;
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog {
    position: static;
    max-width: 100%;
    width: auto;
    margin: 0;
    border-radius: 0.42rem;
}

.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog .modal-content {
    border: 0;
    border-radius: 0.42rem;
}

.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg {
    max-width: 600px;
}

.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg .modal-dialog {
    max-width: 600px;
}

@media (max-width: 991.98px) {
    .modal.modal-sticky.modal-sticky-bottom-right {
        bottom: 10px;
        right: 10px;
    }
}

@media (max-width: 767.98px) {
    .modal.modal-sticky.modal-sticky-bottom-right {
        max-width: 90%;
    }
}

.modal-open {
    overflow: auto !important;
    padding: 0 !important;
}

@media (min-width: 1200px) {
    .modal-dialog.modal-xl {
        max-width: 1100px;
    }
}

.modal-packege1 {
    display: block !important;
    padding-left: 0px !important;
}

.modal-packege2 {
    min-height: 50px !important;
}

.modal-packege3 {
    height: auto !important;
    width: auto !important;
}

.modal-packege4 {
    padding: 1rem !important;
}

.modal-packege5 {
    min-width: 100% !important;
}

.modal-packege6 {
    min-width: 100% !important;
    min-height: 100% !important;
}

.change_table_text_3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.usermodal,
.usermodal-mobile {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.usermodal .usermodal-header.usermodal-header-navs,
.usermodal-mobile .usermodal-header.usermodal-header-navs {
    position: relative;
}

.usermodal .usermodal-header.usermodal-header-navs .usermodal-close,
.usermodal-mobile .usermodal-header.usermodal-header-navs .usermodal-close {
    position: absolute;
    top: 0;
    right: 0;
}

.usermodal .usermodal-content .usermodal-demo,
.usermodal-mobile .usermodal-content .usermodal-demo {
    border: 5px solid #EBEDF3;
}

.usermodal .usermodal-content .usermodal-demo.usermodal-demo-active,
.usermodal-mobile .usermodal-content .usermodal-demo.usermodal-demo-active {
    border: 5px solid #3699FF;
}

.usermodal {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
}

@media (max-width: 991.98px) {
    .usermodal-mobile {
        background-color: #ffffff;
        -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
        overflow: auto;
    }
}

.usermodal {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: -395px;
    width: 390px;
}

.usermodal.usermodal-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .usermodal {
        -webkit-transition: none !important;
        transition: none !important;
    }
}

.usermodal.usermodal-right {
    right: -395px;
    left: auto;
}

.usermodal.usermodal-right.usermodal-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: 0;
    left: auto;
    overflow: auto;
}

.usermodal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    -webkit-animation: animation-usermodal-fade-in .6s ease 1;
    animation: animation-usermodal-fade-in .6s ease 1;
}

@-webkit-keyframes animation-usermodal-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animation-usermodal-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.usermodal {
    background: #ffffff;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
}

.usermodal-overlay {
    background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 425px) {
    .usermodal {
        width: 90% !important;
    }
}

@media (max-width: 991.98px) {
    .usermodal-mobile {
        z-index: 1001;
        position: fixed;
        top: 0;
        bottom: 0;
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        left: -395px;
        width: 375px;
    }

    .usermodal-mobile.usermodal-mobile-on {
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        left: 0;
        overflow: auto;
    }
}

@media screen and (max-width: 991.98px) and (-ms-high-contrast: active), (max-width: 991.98px) and (-ms-high-contrast: none) {
    .usermodal-mobile {
        -webkit-transition: none !important;
        transition: none !important;
    }
}

@media (max-width: 991.98px) {
    .usermodal-mobile.usermodal-mobile-right {
        right: -395px;
        left: auto;
    }

    .usermodal-mobile.usermodal-mobile-right.usermodal-mobile-on {
        -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
        right: 0;
        left: auto;
        overflow: auto;
    }

    .usermodal-mobile-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        z-index: 1000;
        -webkit-animation: animation-usermodal-fade-in .6s ease 1;
        animation: animation-usermodal-fade-in .6s ease 1;
    }

    @-webkit-keyframes animation-usermodal-fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes animation-usermodal-fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .usermodal-mobile {
        background: #ffffff;
        -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
    }

    .usermodal-mobile-overlay {
        background: rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 425px) {
    .usermodal-mobile {
        width: 90% !important;
    }
}


@charset "UTF-8";
/*
 * Container style
 */
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    height: 15px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 15px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 6px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 6px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #999;
    height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #999;
    width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.icon-color-primary-exit {
    color: var(--success) !important;
}

.icon-color-primary-exit:hover {
    color: #ff3939 !important;
}

.no-scroll {
    position: absolute;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.li-podr {
    /*display: flex;*/
    align-items: center;
    padding: 5px 0;
}

.li-podr input {
    accent-color: var(--originaltwo);
}

ul.main-ul {
    padding-inline-start: 0;
    padding-left: 0;
}

