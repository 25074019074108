@charset "UTF-8";

.breadcrumb {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.breadcrumb .breadcrumb-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.breadcrumb .breadcrumb-item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.breadcrumb .breadcrumb-item.active i,
.breadcrumb .breadcrumb-item.active a, .breadcrumb .breadcrumb-item:hover i,
.breadcrumb .breadcrumb-item:hover a {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #1BC5BD !important;
}
.breadcrumb.breadcrumb-transparent {
    background-color: transparent;
    border-radius: 0;
}
.breadcrumb.breadcrumb-line .breadcrumb-item:before {
    display: none;
}
.breadcrumb.breadcrumb-line .breadcrumb-item:after {
    content: "-";
    padding-left: 0.5rem;
}
.breadcrumb.breadcrumb-line .breadcrumb-item:last-child:after {
    display: none;
}
.breadcrumb.breadcrumb-dot .breadcrumb-item:before {
    display: none;
}
.breadcrumb.breadcrumb-dot .breadcrumb-item:after {
    content: "\27A4";
    padding-left: 0.5rem;
}
.breadcrumb.breadcrumb-dot .breadcrumb-item:last-child:after {
    display: none;
}


  .packet.packet-custom {
      border: 0; }

.packet-body {
    padding: 0.25em;
}

.packet-body:hover {
    /*background: #f9f9f9;*/
    background-color: var(--elem-bg);
    border-radius: 1.5em; }

.packet.packet-custom > .packet-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent; }
.packet.packet-custom > .packet-header .packet-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0; }
.packet.packet-custom > .packet-header .packet-title .packet-icon {
    margin-right: 0.75rem;
    line-height: 0; }
.packet.packet-custom > .packet-header .packet-title .packet-icon i {
    font-size: 1.25rem;
    color: #7E8299;
    line-height: 0; }
.packet.packet-custom > .packet-header .packet-title .packet-icon i:after, .packet.packet-custom > .packet-header .packet-title .packet-icon i:before {
    line-height: 0; }
.packet.packet-custom > .packet-header .packet-title .packet-icon .svg-icon svg {
    height: 24px;
    width: 24px; }
.packet.packet-custom > .packet-header .packet-title .packet-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.packet.packet-custom > .packet-header .packet-title .packet-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.packet.packet-custom > .packet-header .packet-title,
.packet.packet-custom > .packet-header .packet-title .packet-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #181C32; }
.packet.packet-custom > .packet-header .packet-title .packet-label {
    margin: 0 0.75rem 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.packet.packet-custom > .packet-header .packet-title small {
    color: #B5B5C3;
    font-size: 1rem; }
.packet.packet-custom > .packet-header .packet-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.packet.packet-custom > .packet-header.packet-header-tabs-line {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
.packet.packet-custom > .packet-header.packet-header-tabs-line .packet-toolbar {
    margin: 0; }
.packet.packet-custom > .packet-header.packet-header-tabs-line .nav {
    border-bottom-color: transparent; }
.packet.packet-custom > .packet-header.packet-header-tabs-line .nav .nav-item {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
.packet.packet-custom > .packet-header.packet-header-tabs-line .nav .nav-link {
    padding-top: 2rem;
    padding-bottom: 2rem; }
.packet.packet-custom > .packet-header.packet-header-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
.packet.packet-custom > .packet-body {
    padding: 0.5rem 0.25rem;
}
.packet.packet-custom > .packet-footer {
    background-color: transparent; }
.packet.packet-custom .packet-scroll {
    position: relative;
    overflow: auto; }
.packet.packet-custom.packet-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%; }
.packet.packet-custom.packet-stretch.gutter-b {
    height: calc(100% - 25px); }
.packet.packet-custom.packet-stretch.packet-stretch-half {
    height: 50%; }
.packet.packet-custom.packet-stretch.packet-stretch-half.gutter-b {
    height: calc(50% - 25px); }
.packet.packet-custom.packet-stretch.packet-stretch-third {
    height: 33.33%; }
.packet.packet-custom.packet-stretch.packet-stretch-third.gutter-b {
    height: calc(33.33% - 25px); }
.packet.packet-custom.packet-stretch.packet-stretch-fourth {
    height: 25%; }
.packet.packet-custom.packet-stretch.packet-stretch-fourth.gutter-b {
    height: calc(25% - 25px); }
.packet.packet-custom.packet-fit > .packet-header {
    border-bottom: 0; }
.packet.packet-custom.packet-fit > .packet-footer {
    border-top: 0; }
.packet.packet-custom.packet-space {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
.packet.packet-custom.packet-space > .packet-header {
    padding-left: 0;
    padding-right: 0; }
.packet.packet-custom.packet-space > form > .packet-body,
.packet.packet-custom.packet-space > .packet-body {
    padding-left: 0;
    padding-right: 0; }
.packet.packet-custom.packet-space > form > .packet-footer,
.packet.packet-custom.packet-space > .packet-footer {
    padding-left: 0;
    padding-right: 0; }
.packet.packet-custom.packet-sticky > .packet-header {
    -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
    transition: left 0.3s, right 0.3s, height 0.3s;
    min-height: 70px; }
.packet-sticky-on .packet.packet-custom.packet-sticky > .packet-header {
    -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
    transition: left 0.3s, right 0.3s, height 0.3s;
    position: fixed;
    -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
    z-index: 101;
    background: white; }
.packet.packet-custom.packet-transparent {
    background-color: transparent; }
.packet.packet-custom.packet-shadowless {
    -webkit-box-shadow: none;
    box-shadow: none; }
.packet.packet-custom.packet-px-0 .packet-header,
.packet.packet-custom.packet-px-0 .packet-body,
.packet.packet-custom.packet-px-0 .packet-footer {
    padding-left: 0;
    padding-right: 0; }
.packet.packet-custom.packet-border {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #EBEDF3; }
.packet.packet-custom.packet-collapsed > form,
.packet.packet-custom.packet-collapsed > .packet-body {
    display: none; }
.packet.packet-custom .packet-header .packet-toolbar [data-packet-tool="toggle"] i {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease; }
.packet.packet-custom.packet-collapsed .packet-header .packet-toolbar [data-packet-tool="toggle"] i, .packet.packet-custom.packet-collapse .packet-header .packet-toolbar [data-packet-tool="toggle"] i {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }

.packet-spacer {
    padding: 2rem 2.25rem !important; }

.packet-spacer-x {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important; }

.packet-spacer-y {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }

.packet-rounded {
    border-radius: 0.42rem; }

.packet-rounded-top {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem; }

.packet-rounded-bottom {
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }

.packet-link {
    outline: none;
}


@media (max-width: 767.98px) {
    .packet.packet-custom > .packet-header:not(.flex-nowrap) {
        min-height: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; } }

.packet-img {
    height:60px !important;
    max-width: 60px !important;;
}
