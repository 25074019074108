@charset "UTF-8";
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf");
    src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf");
    src: url("../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Bold.ttf");
    src:url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Regular.ttf");
    src:   url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Italic.ttf");
    src: url("../fonts/montserrat/Montserrat-Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-ExtraLight.ttf");
    src: url("../fonts/montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat/Montserrat-Medium.ttf");
    src: url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

.text-primary {
    color: var(--text-primary) !important; }

a.text-primary:hover, a.text-primary:focus {
    color: #12827c !important; }

.text-secondary {
    color: #E4E6EF !important; }

a.text-secondary:hover, a.text-secondary:focus {
    color: #b4bad3 !important; }

.text-success {
    color: var(--success) !important; }

a.text-success:hover, a.text-success:focus {
    color: #0073e9 !important; }

.text-info {
    color: #6993FF !important; }

a.text-info:hover, a.text-info:focus {
    color: #1d5cff !important; }

.text-warning {
    color: #FFA800 !important; }

a.text-warning:hover, a.text-warning:focus {
    color: #b37600 !important; }

.text-danger {
    color: #F64E60 !important; }

a.text-danger:hover, a.text-danger:focus {
    color: #ec0c24 !important; }

.text-light {
    color: #F3F6F9 !important; }

a.text-light:hover, a.text-light:focus {
    color: #c0d0e0 !important; }

.text-dark {
    color: #181C32 !important; }

a.text-dark:hover, a.text-dark:focus {
    color: black !important; }

a.text-white:hover, a.text-white:focus {
    color: #d9d9d9 !important; }

.text-body {
    color: #3F4254 !important; }

.text-muted {
    color: #B5B5C3 !important; }

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }

.text-decoration-none {
    text-decoration: none !important; }

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }

.text-reset {
    color: inherit !important; }

.visible {
    visibility: visible !important; }

.invisible {
    visibility: hidden !important; }

.text-welcome {
    color: var(--text-welcome) !important; }

.text-inverse-white {
    color: #3F4254 !important; }

.text-light-white {
    color: white !important; }

a.text-hover-white,
.text-hover-white {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-white:hover,
.text-hover-white:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: white !important; }
a.text-hover-white:hover i,
.text-hover-white:hover i {
    color: white !important; }
a.text-hover-white:hover .svg-icon svg g [fill],
.text-hover-white:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
a.text-hover-white:hover .svg-icon svg:hover g [fill],
.text-hover-white:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-white,
.hoverable:hover .text-hover-white {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: white !important; }
.hoverable:hover a.text-hover-white i,
.hoverable:hover .text-hover-white i {
    color: white !important; }
.hoverable:hover a.text-hover-white .svg-icon svg g [fill],
.hoverable:hover .text-hover-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.hoverable:hover a.text-hover-white .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-inverse-primary {
    color: white !important; }

.text-light-primary {
    color: #C9F7F5 !important; }

a.text-hover-primary,
.text-hover-primary {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-primary:hover,
.text-hover-primary:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #013E9A !important; }
a.text-hover-primary:hover i,
.text-hover-primary:hover i {
    color: #013E9A !important; }
a.text-hover-primary:hover .svg-icon svg g [fill],
.text-hover-primary:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #013E9A; }
a.text-hover-primary:hover .svg-icon svg:hover g [fill],
.text-hover-primary:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-primary,
.hoverable:hover .text-hover-primary {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #013E9A !important; }
.hoverable:hover a.text-hover-primary i,
.hoverable:hover .text-hover-primary i {
    color: #013E9A !important; }
.hoverable:hover a.text-hover-primary .svg-icon svg g [fill],
.hoverable:hover .text-hover-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #013E9A !important; }
.hoverable:hover a.text-hover-primary .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-secondary {
    color: #181C32 !important; }

.text-inverse-secondary {
    color: #3F4254 !important; }

.text-light-secondary {
    color: #EBEDF3 !important; }

a.text-hover-secondary,
.text-hover-secondary {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-secondary:hover,
.text-hover-secondary:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #181C32 !important; }
a.text-hover-secondary:hover i,
.text-hover-secondary:hover i {
    color: #181C32 !important; }
a.text-hover-secondary:hover .svg-icon svg g [fill],
.text-hover-secondary:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
a.text-hover-secondary:hover .svg-icon svg:hover g [fill],
.text-hover-secondary:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-secondary,
.hoverable:hover .text-hover-secondary {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #181C32 !important; }
.hoverable:hover a.text-hover-secondary i,
.hoverable:hover .text-hover-secondary i {
    color: #181C32 !important; }
.hoverable:hover a.text-hover-secondary .svg-icon svg g [fill],
.hoverable:hover .text-hover-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }
.hoverable:hover a.text-hover-secondary .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-success {
    color: var(--success) !important; }

.text-inverse-success {
    color: white !important; }

.text-light-success {
    color: #E1F0FF !important; }

a.text-hover-success,
.text-hover-success {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-success:hover,
.text-hover-success:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success) !important; }
a.text-hover-success:hover i,
.text-hover-success:hover i {
    color: var(--success) !important; }
a.text-hover-success:hover .svg-icon svg g [fill],
.text-hover-success:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
a.text-hover-success:hover .svg-icon svg:hover g [fill],
.text-hover-success:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-success,
.hoverable:hover .text-hover-success {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success) !important; }
.hoverable:hover a.text-hover-success i,
.hoverable:hover .text-hover-success i {
    color: var(--success) !important; }
.hoverable:hover a.text-hover-success .svg-icon svg g [fill],
.hoverable:hover .text-hover-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }
.hoverable:hover a.text-hover-success .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-info {
    color: #6993FF !important; }

.text-inverse-info {
    color: white !important; }

.text-light-info {
    color: #E1E9FF !important; }

a.text-hover-info,
.text-hover-info {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-info:hover,
.text-hover-info:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #6993FF !important; }
a.text-hover-info:hover i,
.text-hover-info:hover i {
    color: #6993FF !important; }
a.text-hover-info:hover .svg-icon svg g [fill],
.text-hover-info:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
a.text-hover-info:hover .svg-icon svg:hover g [fill],
.text-hover-info:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-info,
.hoverable:hover .text-hover-info {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #6993FF !important; }
.hoverable:hover a.text-hover-info i,
.hoverable:hover .text-hover-info i {
    color: #6993FF !important; }
.hoverable:hover a.text-hover-info .svg-icon svg g [fill],
.hoverable:hover .text-hover-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF !important; }
.hoverable:hover a.text-hover-info .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-warning {
    color: #FFA800 !important; }

.text-inverse-warning {
    color: white !important; }

.text-light-warning {
    color: #FFF4DE !important; }

a.text-hover-warning,
.text-hover-warning {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-warning:hover,
.text-hover-warning:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #FFA800 !important; }
a.text-hover-warning:hover i,
.text-hover-warning:hover i {
    color: #FFA800 !important; }
a.text-hover-warning:hover .svg-icon svg g [fill],
.text-hover-warning:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
a.text-hover-warning:hover .svg-icon svg:hover g [fill],
.text-hover-warning:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-warning,
.hoverable:hover .text-hover-warning {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #FFA800 !important; }
.hoverable:hover a.text-hover-warning i,
.hoverable:hover .text-hover-warning i {
    color: #FFA800 !important; }
.hoverable:hover a.text-hover-warning .svg-icon svg g [fill],
.hoverable:hover .text-hover-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800 !important; }
.hoverable:hover a.text-hover-warning .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-danger {
    color: #F64E60 !important; }

.text-inverse-danger {
    color: white !important; }

.text-light-danger {
    color: #FFE2E5 !important; }

a.text-hover-danger,
.text-hover-danger {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-danger:hover,
.text-hover-danger:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #F64E60 !important; }
a.text-hover-danger:hover i,
.text-hover-danger:hover i {
    color: #F64E60 !important; }
a.text-hover-danger:hover .svg-icon svg g [fill],
.text-hover-danger:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
a.text-hover-danger:hover .svg-icon svg:hover g [fill],
.text-hover-danger:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-danger,
.hoverable:hover .text-hover-danger {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #F64E60 !important; }
.hoverable:hover a.text-hover-danger i,
.hoverable:hover .text-hover-danger i {
    color: #F64E60 !important; }
.hoverable:hover a.text-hover-danger .svg-icon svg g [fill],
.hoverable:hover .text-hover-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60 !important; }
.hoverable:hover a.text-hover-danger .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-light {
    color: #F3F6F9 !important; }

.text-inverse-light {
    color: #7E8299 !important; }

.text-light-light {
    color: #F3F6F9 !important; }

a.text-hover-light,
.text-hover-light {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-light:hover,
.text-hover-light:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #F3F6F9 !important; }
a.text-hover-light:hover i,
.text-hover-light:hover i {
    color: #F3F6F9 !important; }
a.text-hover-light:hover .svg-icon svg g [fill],
.text-hover-light:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
a.text-hover-light:hover .svg-icon svg:hover g [fill],
.text-hover-light:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-light,
.hoverable:hover .text-hover-light {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #F3F6F9 !important; }
.hoverable:hover a.text-hover-light i,
.hoverable:hover .text-hover-light i {
    color: #F3F6F9 !important; }
.hoverable:hover a.text-hover-light .svg-icon svg g [fill],
.hoverable:hover .text-hover-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9 !important; }
.hoverable:hover a.text-hover-light .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-dark {
    color: #181C32 !important; }

.text-inverse-dark {
    color: white !important; }

.text-light-dark {
    color: #D1D3E0 !important; }

a.text-hover-dark,
.text-hover-dark {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-dark:hover,
.text-hover-dark:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #181C32 !important; }
a.text-hover-dark:hover i,
.text-hover-dark:hover i {
    color: #181C32 !important; }
a.text-hover-dark:hover .svg-icon svg g [fill],
.text-hover-dark:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
a.text-hover-dark:hover .svg-icon svg:hover g [fill],
.text-hover-dark:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-dark,
.hoverable:hover .text-hover-dark {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #181C32 !important; }
.hoverable:hover a.text-hover-dark i,
.hoverable:hover .text-hover-dark i {
    color: #181C32 !important; }
.hoverable:hover a.text-hover-dark .svg-icon svg g [fill],
.hoverable:hover .text-hover-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }
.hoverable:hover a.text-hover-dark .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-dark-75 {
    color: #3F4254 !important; }




a.text-hover-dark-75,
.text-hover-dark-75 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-dark-75:hover,
.text-hover-dark-75:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #3F4254 !important; }
a.text-hover-dark-75:hover i,
.text-hover-dark-75:hover i {
    color: #3F4254 !important; }
a.text-hover-dark-75:hover .svg-icon svg g [fill],
.text-hover-dark-75:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
a.text-hover-dark-75:hover .svg-icon svg:hover g [fill],
.text-hover-dark-75:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-dark-75,
.hoverable:hover .text-hover-dark-75 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #3F4254 !important; }
.hoverable:hover a.text-hover-dark-75 i,
.hoverable:hover .text-hover-dark-75 i {
    color: #3F4254 !important; }
.hoverable:hover a.text-hover-dark-75 .svg-icon svg g [fill],
.hoverable:hover .text-hover-dark-75 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.hoverable:hover a.text-hover-dark-75 .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-dark-75 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-dark-65 {
    color: #5E6278 !important; }



a.text-hover-dark-65,
.text-hover-dark-65 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-dark-65:hover,
.text-hover-dark-65:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #5E6278 !important; }
a.text-hover-dark-65:hover i,
.text-hover-dark-65:hover i {
    color: #5E6278 !important; }
a.text-hover-dark-65:hover .svg-icon svg g [fill],
.text-hover-dark-65:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278; }
a.text-hover-dark-65:hover .svg-icon svg:hover g [fill],
.text-hover-dark-65:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-dark-65,
.hoverable:hover .text-hover-dark-65 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #5E6278 !important; }
.hoverable:hover a.text-hover-dark-65 i,
.hoverable:hover .text-hover-dark-65 i {
    color: #5E6278 !important; }
.hoverable:hover a.text-hover-dark-65 .svg-icon svg g [fill],
.hoverable:hover .text-hover-dark-65 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278 !important; }
.hoverable:hover a.text-hover-dark-65 .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-dark-65 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-dark-50 {
    color: #7E8299 !important; }



a.text-hover-dark-50,
.text-hover-dark-50 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-dark-50:hover,
.text-hover-dark-50:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #7E8299 !important; }
a.text-hover-dark-50:hover i,
.text-hover-dark-50:hover i {
    color: #7E8299 !important; }
a.text-hover-dark-50:hover .svg-icon svg g [fill],
.text-hover-dark-50:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
a.text-hover-dark-50:hover .svg-icon svg:hover g [fill],
.text-hover-dark-50:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-dark-50,
.hoverable:hover .text-hover-dark-50 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #7E8299 !important; }
.hoverable:hover a.text-hover-dark-50 i,
.hoverable:hover .text-hover-dark-50 i {
    color: #7E8299 !important; }
.hoverable:hover a.text-hover-dark-50 .svg-icon svg g [fill],
.hoverable:hover .text-hover-dark-50 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }
.hoverable:hover a.text-hover-dark-50 .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-dark-50 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-dark-25 {
    color: #D1D3E0 !important; }

a.text-hover-dark-25,
.text-hover-dark-25 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-dark-25:hover,
.text-hover-dark-25:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #D1D3E0 !important; }
a.text-hover-dark-25:hover i,
.text-hover-dark-25:hover i {
    color: #D1D3E0 !important; }
a.text-hover-dark-25:hover .svg-icon svg g [fill],
.text-hover-dark-25:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0; }
a.text-hover-dark-25:hover .svg-icon svg:hover g [fill],
.text-hover-dark-25:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-dark-25,
.hoverable:hover .text-hover-dark-25 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #D1D3E0 !important; }
.hoverable:hover a.text-hover-dark-25 i,
.hoverable:hover .text-hover-dark-25 i {
    color: #D1D3E0 !important; }
.hoverable:hover a.text-hover-dark-25 .svg-icon svg g [fill],
.hoverable:hover .text-hover-dark-25 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0 !important; }
.hoverable:hover a.text-hover-dark-25 .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-dark-25 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.text-muted {
    color: #B5B5C3 !important; }



a.text-hover-muted,
.text-hover-muted {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a.text-hover-muted:hover,
.text-hover-muted:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #B5B5C3 !important; }
a.text-hover-muted:hover i,
.text-hover-muted:hover i {
    color: #B5B5C3 !important; }
a.text-hover-muted:hover .svg-icon svg g [fill],
.text-hover-muted:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }
a.text-hover-muted:hover .svg-icon svg:hover g [fill],
.text-hover-muted:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.hoverable:hover a.text-hover-muted,
.hoverable:hover .text-hover-muted {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #B5B5C3 !important; }
.hoverable:hover a.text-hover-muted i,
.hoverable:hover .text-hover-muted i {
    color: #B5B5C3 !important; }
.hoverable:hover a.text-hover-muted .svg-icon svg g [fill],
.hoverable:hover .text-hover-muted .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3 !important; }
.hoverable:hover a.text-hover-muted .svg-icon svg:hover g [fill],
.hoverable:hover .text-hover-muted .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.font-size-base {
    font-size: 1rem; }

.font-size-sm {
    font-size: 0.925rem; }

.font-size-xs {
    font-size: 0.8rem; }

.font-size-lg {
    font-size: 1.08rem; }

.font-size-h1 {
    font-size: 2rem !important; }

.font-size-h2 {
    font-size: 1.75rem !important; }

.font-size-h3 {
    font-size: 1.5rem !important; }

.font-size-h4 {
    font-size: 1.35rem !important; }

.font-size-h5 {
    font-size: 1.25rem !important; }

.font-size-h6 {
    font-size: 1.175rem !important; }

.display1 {
    font-size: 5.5rem !important; }

.display2 {
    font-size: 4.5rem !important; }

.display3 {
    font-size: 3.5rem !important; }

.display4 {
    font-size: 2.5rem !important; }

.display5 {
    font-size: 2.25rem !important; }

@media (min-width: 576px) {
    .font-size-h1-sm {
        font-size: 2rem !important; }
    .font-size-h2-sm {
        font-size: 1.75rem !important; }
    .font-size-h3-sm {
        font-size: 1.5rem !important; }
    .font-size-h4-sm {
        font-size: 1.35rem !important; }
    .font-size-h5-sm {
        font-size: 1.25rem !important; }
    .font-size-h6-sm {
        font-size: 1.175rem !important; }
    .display1-sm {
        font-size: 5.5rem !important; }
    .display2-sm {
        font-size: 4.5rem !important; }
    .display3-sm {
        font-size: 3.5rem !important; }
    .display4-sm {
        font-size: 2.5rem !important; }
    .display5-sm {
        font-size: 2.25rem !important; } }

@media (min-width: 768px) {
    .font-size-h1-md {
        font-size: 2rem !important; }
    .font-size-h2-md {
        font-size: 1.75rem !important; }
    .font-size-h3-md {
        font-size: 1.5rem !important; }
    .font-size-h4-md {
        font-size: 1.35rem !important; }
    .font-size-h5-md {
        font-size: 1.25rem !important; }
    .font-size-h6-md {
        font-size: 1.175rem !important; }
    .display1-md {
        font-size: 5.5rem !important; }
    .display2-md {
        font-size: 4.5rem !important; }
    .display3-md {
        font-size: 3.5rem !important; }
    .display4-md {
        font-size: 2.5rem !important; }
    .display5-md {
        font-size: 2.25rem !important; } }

@media (min-width: 992px) {
    .font-size-h1-lg {
        font-size: 2rem !important; }
    .font-size-h2-lg {
        font-size: 1.75rem !important; }
    .font-size-h3-lg {
        font-size: 1.5rem !important; }
    .font-size-h4-lg {
        font-size: 1.35rem !important; }
    .font-size-h5-lg {
        font-size: 1.25rem !important; }
    .font-size-h6-lg {
        font-size: 1.175rem !important; }
    .display1-lg {
        font-size: 5.5rem !important; }
    .display2-lg {
        font-size: 4.5rem !important; }
    .display3-lg {
        font-size: 3.5rem !important; }
    .display4-lg {
        font-size: 2.5rem !important; }
    .display5-lg {
        font-size: 2.25rem !important; } }

@media (min-width: 1200px) {
    .font-size-h1-xl {
        font-size: 2rem !important; }
    .font-size-h2-xl {
        font-size: 1.75rem !important; }
    .font-size-h3-xl {
        font-size: 1.5rem !important; }
    .font-size-h4-xl {
        font-size: 1.35rem !important; }
    .font-size-h5-xl {
        font-size: 1.25rem !important; }
    .font-size-h6-xl {
        font-size: 1.175rem !important; }
    .display1-xl {
        font-size: 5.5rem !important; }
    .display2-xl {
        font-size: 4.5rem !important; }
    .display3-xl {
        font-size: 3.5rem !important; }
    .display4-xl {
        font-size: 2.5rem !important; }
    .display5-xl {
        font-size: 2.25rem !important; } }

@media (min-width: 1400px) {
    .font-size-h1-xxl {
        font-size: 2rem !important; }
    .font-size-h2-xxl {
        font-size: 1.75rem !important; }
    .font-size-h3-xxl {
        font-size: 1.5rem !important; }
    .font-size-h4-xxl {
        font-size: 1.35rem !important; }
    .font-size-h5-xxl {
        font-size: 1.25rem !important; }
    .font-size-h6-xxl {
        font-size: 1.175rem !important; }
    .display1-xxl {
        font-size: 5.5rem !important; }
    .display2-xxl {
        font-size: 4.5rem !important; }
    .display3-xxl {
        font-size: 3.5rem !important; }
    .display4-xxl {
        font-size: 2.5rem !important; }
    .display5-xxl {
        font-size: 2.25rem !important; } }

.font-weight-boldest {
    font-weight: 700; }

.line-height-0 {
    line-height: 0; }

.line-height-xs {
    line-height: 0.75; }

.line-height-sm {
    line-height: 1; }

.line-height-md {
    line-height: 1.25; }

.line-height-lg {
    line-height: 1.5; }

.line-height-xl {
    line-height: 1.75; }

.text-transparent {
    color: transparent; }

.text-stroke {
    -webkit-text-stroke-width: 0.35rem;
    -moz-text-stroke-width: 0.35rem;
    text-stroke-width: 0.35rem;
    -webkit-text-stroke-color: white;
    -moz-text-stroke-color: white;
    text-stroke-color: white; }

.cursor-pointer {
    cursor: pointer; }

.cursor-move {
    cursor: move; }

a {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
a:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }

i {
    font-size: 1.25rem;
    color: #B5B5C3; }

.h1 {
    font-size: 2rem !important; }
@media (max-width: 1200px) {
    .h1 {
        font-size: calc(1.325rem + 0.9vw)  !important; } }

.h2 {
    font-size: 1.75rem !important; }
@media (max-width: 1200px) {
    .h2 {
        font-size: calc(1.3rem + 0.6vw)  !important; } }

.h3 {
    font-size: 1.5rem !important; }
@media (max-width: 1200px) {
    .h3 {
        font-size: calc(1.275rem + 0.3vw)  !important; } }

.h4 {
    font-size: 1.35rem !important; }
@media (max-width: 1200px) {
    .h4 {
        font-size: calc(1.26rem + 0.12vw)  !important; } }

.h5 {
    font-size: 1.25rem !important; }

.h6 {
    font-size: 1.175rem !important; }

.lead {
    font-size: 1.25rem !important; }

.display-1 {
    font-size: 5.5rem !important; }
@media (max-width: 1200px) {
    .display-1 {
        font-size: calc(1.675rem + 5.1vw)  !important; } }

.display-2 {
    font-size: 4.5rem !important; }
@media (max-width: 1200px) {
    .display-2 {
        font-size: calc(1.575rem + 3.9vw)  !important; } }

.display-3 {
    font-size: 3.5rem !important; }
@media (max-width: 1200px) {
    .display-3 {
        font-size: calc(1.475rem + 2.7vw)  !important; } }

.display-4 {
    font-size: 2.5rem !important; }
@media (max-width: 1200px) {
    .display-4 {
        font-size: calc(1.375rem + 1.5vw)  !important; } }

a,
button {
    outline: 0 !important; }

.breadcrumb {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.breadcrumb .breadcrumb-item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.breadcrumb .breadcrumb-item.active i,
.breadcrumb .breadcrumb-item.active a, .breadcrumb .breadcrumb-item:hover i,
.breadcrumb .breadcrumb-item:hover a {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #013E9A !important; }
.breadcrumb.breadcrumb-transparent {
    background-color: transparent;
    border-radius: 0; }
.breadcrumb.breadcrumb-line .breadcrumb-item:before {
    display: none; }
.breadcrumb.breadcrumb-line .breadcrumb-item:after {
    content: "-";
    padding-left: 0.5rem; }
.breadcrumb.breadcrumb-line .breadcrumb-item:last-child:after {
    display: none; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:before {
    display: none; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:after {
    content: "\27A4";
    padding-left: 0.5rem; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:last-child:after {
    display: none; }

.fsic {
    font-size: 0.8rem;
}

.fsic2 {
    font-size: 1rem;
}

.fsic3 {
    font-size: 0.4rem !important;
}

.fsic4 {
    font-size: 1.5rem;
}

.icolor {
    color: var(--icon-color-table);
}

.i2color {
    color: var(--icon-color-table);
}

.packages_text_3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.packages_text_2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-color-gray {
    color: var(--gray) !important;
}
