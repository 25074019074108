.container {
    position: absolute;
    display: flex;
    width: 2%;
    height: 50%;
    transform: translate(-50%, 45%);
    z-index: 100;
}

@media (max-width: 1199px) {
    .container {
        display: none;
    }
}

.container:hover .btn{
    visibility: visible;
}

.btn {
    position: absolute;
    left: 50%;
    z-index: 0;
    cursor: pointer;
    transform: translate(-50%, 0);
    visibility: hidden;
}

.btn-prev {
    top: 40%;

 }

.btn-next {
    top: 50%;
}
