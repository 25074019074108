@charset "UTF-8";

.context-menu {
    /*display: none;*/
    position: absolute;
    z-index: 10;
    width: 240px;
    background-color: #fff;
    border: solid 1px var(--success);
    border-radius: 1rem;
}

.context-menu--active {
    display: block;
}

.context-menu__items {
    list-style: none;
    margin: 0;
    padding: 0;
}

.context-menu__item {
    display: block;
    margin-bottom: 4px;
}

.context-menu__item:last-child {
    margin-bottom: 0;
}

.context-menu__link {
    display: block;
    padding: 3% 3%;
    color: var(--success);
    text-decoration: none;
    border-radius: 0.92rem;
    vertical-align: middle;
    line-height: normal;
}

.context-menu__link i {
    color: var(--success) !important;
}

.context-menu__link:hover {
    color: #fff;
    background-color: var(--success);
}

.context-menu__link:hover i {
    color: #fff !important;
}

.dropdown-menu {
    padding: 0;
}
.dropdown-menu.show {
    background-color: #fff;
    border: solid 1px var(--icon-primary);
    border-radius: 1rem;
}
.dropdown-item {
    border-radius: 0.92rem;
    padding: .35rem 1.5rem;
    color: var(--text-primary);
}

a.dropdown-item.active {
    color: #fff;
    background-color: var(--success);
}

.dropdown-item:hover {
    color: var(--text-hover);
    background: var(--active-primary);
}

.dropdown-item:has(.active) i:not(.icon-Delete) {
    color: var(--success);
}

.dropdown-item:hover i:not(.icon-Delete) {
    color: var(--white) !important;
}
