.beautiful-scroll-5 {
    overflow: auto;
}

.beautiful-scroll-5::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: white;
}

.beautiful-scroll-5::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*background-color: rgb(239 137 137 / 20%);*/
    background-color: var(--background-colorBS);
}

.beautiful-scroll-5::-webkit-scrollbar-track {
    border-radius: 10px;
    /*background-color: red;*/
}

.beautiful-scroll-10 {
    overflow: auto;
}

.beautiful-scroll-10::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: white;
}

.beautiful-scroll-10::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*background-color: rgb(255 204 204 / 25%);*/
    background-color: var(--background-colorBS);
}

.beautiful-scroll-10::-webkit-scrollbar-track {
    border-radius: 10px;
    /*background-color: green;*/
}
