@charset "UTF-8";
.btn {
    display: inline-block;
    font-weight: normal;
    color: #3F4254;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.45rem 0.6rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        transition: none; } }
.btn:hover {
    color: #3F4254;
    text-decoration: none; }
.btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn.disabled, .btn:disabled {
    opacity: 0.6;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    -webkit-box-shadow: none;
    box-shadow: none; }

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none; }

.btn-primary {
    color: white;
    background-color: var(--success);
    border-color: var(--success);
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-primary:hover {
    color: white;
    background-color: #16a39d;
    border-color: #159892; }
.btn-primary:focus, .btn-primary.focus {
    color: white;
    background-color: #16a39d;
    border-color: #159892;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 206, 199, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(61, 206, 199, 0.5); }
.btn-primary.disabled, .btn-primary:disabled {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: white;
    background-color: #159892;
    border-color: #138d87; }
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 206, 199, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(61, 206, 199, 0.5); }

.btn-secondary {
    color: #181C32;
    background-color: #E4E6EF;
    border-color: #E4E6EF;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-secondary:hover {
    color: #181C32;
    background-color: #ccd0e1;
    border-color: #c4c8dc; }
.btn-secondary:focus, .btn-secondary.focus {
    color: #181C32;
    background-color: #ccd0e1;
    border-color: #c4c8dc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(197, 200, 211, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(197, 200, 211, 0.5); }
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #181C32;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #181C32;
    background-color: #c4c8dc;
    border-color: #bcc1d7; }
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(197, 200, 211, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(197, 200, 211, 0.5); }

.btn-success {
    color: white;
    background-color: var(--success);
    border-color: var(--success);
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-success:hover {
    color: white;
    background-color: #1086ff;
    border-color: #037fff; }
.btn-success:focus, .btn-success.focus {
    color: white;
    background-color: #1086ff;
    border-color: #037fff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(84, 168, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(84, 168, 255, 0.5); }
.btn-success.disabled, .btn-success:disabled {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: white;
    background-color: #037fff;
    border-color: #0079f5; }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(84, 168, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(84, 168, 255, 0.5); }

.btn-info {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-info:hover {
    color: white;
    background-color: #4377ff;
    border-color: #366eff; }
.btn-info:focus, .btn-info.focus {
    color: white;
    background-color: #4377ff;
    border-color: #366eff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(128, 163, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(128, 163, 255, 0.5); }
.btn-info.disabled, .btn-info:disabled {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: white;
    background-color: #366eff;
    border-color: #2965ff; }
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(128, 163, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(128, 163, 255, 0.5); }

.btn-warning {
    color: #181C32;
    background-color: #FFA800;
    border-color: #FFA800;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-warning:hover {
    color: white;
    background-color: #d98f00;
    border-color: #cc8600; }
.btn-warning:focus, .btn-warning.focus {
    color: white;
    background-color: #d98f00;
    border-color: #cc8600;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 147, 8, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 147, 8, 0.5); }
.btn-warning.disabled, .btn-warning:disabled {
    color: #181C32;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: white;
    background-color: #cc8600;
    border-color: #bf7e00; }
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 147, 8, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 147, 8, 0.5); }

.btn-danger {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-danger:hover {
    color: white;
    background-color: #f42a3f;
    border-color: #f41d34; }
.btn-danger:focus, .btn-danger.focus {
    color: white;
    background-color: #f42a3f;
    border-color: #f41d34;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(247, 105, 120, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 120, 0.5); }
.btn-danger.disabled, .btn-danger:disabled {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: white;
    background-color: #f41d34;
    border-color: #f3112a; }
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(247, 105, 120, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(247, 105, 120, 0.5); }

.btn-light {
    color: #181C32;
    background-color: var(--elem-bg);
    border-color: #F3F6F9;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-light:hover {
    color: #181C32;
    background-color: #dae3ec;
    border-color: #d1dde8; }
.btn-light:focus, .btn-light.focus {
    color: #181C32;
    background-color: #dae3ec;
    border-color: #d1dde8;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(210, 213, 219, 0.5); }
.btn-light.disabled, .btn-light:disabled {
    color: #181C32;
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    color: #181C32;
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 213, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(210, 213, 219, 0.5); }

.btn-dark {
    color: white;
    background-color: #181C32;
    border-color: #181C32;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-dark:hover {
    color: white;
    background-color: #0c0e18;
    border-color: #070910; }
.btn-dark:focus, .btn-dark.focus {
    color: white;
    background-color: #0c0e18;
    border-color: #070910;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5); }
.btn-dark.disabled, .btn-dark:disabled {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: white;
    background-color: #070910;
    border-color: #030407; }
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(59, 62, 81, 0.5); }

.btn-white {
    color: #181C32;
    background-color: white;
    border-color: white;
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-white:hover {
    color: #181C32;
    background-color: #ececec;
    border-color: #e6e6e6; }
.btn-white:focus, .btn-white.focus {
    color: #181C32;
    background-color: #ececec;
    border-color: #e6e6e6;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 221, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 224, 0.5); }
.btn-white.disabled, .btn-white:disabled {
    color: #181C32;
    background-color: white;
    border-color: white; }
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
    color: #181C32;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 221, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 224, 0.5); }

.btn-outline-primary {
    color: var(--success);
    border-color: var(--success); }
.btn-outline-primary:hover {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5); }
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: var(--success);
    background-color: transparent; }
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5); }

.btn-outline-secondary {
    color: #E4E6EF;
    border-color: #E4E6EF; }
.btn-outline-secondary:hover {
    color: #181C32;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5); }
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #E4E6EF;
    background-color: transparent; }
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #181C32;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5); }

.btn-outline-success {
    color: var(--success);
    border-color: var(--success); }
.btn-outline-success:hover {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5); }
.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: var(--success);
    background-color: transparent; }
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5); }

.btn-outline-info {
    color: #6993FF;
    border-color: #6993FF; }
.btn-outline-info:hover {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5); }
.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #6993FF;
    background-color: transparent; }
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5); }

.btn-outline-warning {
    color: #FFA800;
    border-color: #FFA800; }
.btn-outline-warning:hover {
    color: #181C32;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5); }
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFA800;
    background-color: transparent; }
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #181C32;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5); }

.btn-outline-danger {
    color: #F64E60;
    border-color: #F64E60; }
.btn-outline-danger:hover {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5); }
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F64E60;
    background-color: transparent; }
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5); }

.btn-outline-light {
    color: var(--elem-bg);
    border-color: var(--light-primary); }
.btn-outline-light:hover {
    color: #181C32;
    background-color: var(--elem-bg);
    border-color: var(--light-primary); }
.btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5); }
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F3F6F9;
    background-color: transparent; }
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    color: #181C32;
    background-color: var(--elem-bg);
    border-color: var(--light-primary); }
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5); }

.btn-outline-dark {
    color: #181C32;
    border-color: #181C32; }
.btn-outline-dark:hover {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5); }
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #181C32;
    background-color: transparent; }
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5); }

.btn-outline-white {
    color: white;
    border-color: white; }
.btn-outline-white:hover {
    color: #181C32;
    background-color: white;
    border-color: white; }
.btn-outline-white:focus, .btn-outline-white.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
.btn-outline-white.disabled, .btn-outline-white:disabled {
    color: white;
    background-color: transparent; }
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
    color: #181C32;
    background-color: white;
    border-color: white; }
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-link {
    font-weight: 400;
    color: var(--success);
    text-decoration: none; }
.btn-link:hover {
    color: #12827c;
    text-decoration: underline; }
.btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
.btn-link:disabled, .btn-link.disabled {
    color: #7E8299;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem; }

.btn-sm, .btn-group-sm > .btn {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.42rem; }

.btn-block {
    display: block;
    width: 100%; }
.btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%; }

.btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }

.btn-toolbar-preview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: space-between; }


.btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0; }
.dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.065rem;
    padding-left: 1.065rem; }

.btn-group.show .dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none; }
.btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none; }

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
    width: 100%; }
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.btn {
    outline: none !important;
    vertical-align: middle;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn:hover:not(.btn-text), .btn:focus:not(.btn-text), .btn.focus {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn:hover:not(.btn-text) i, .btn:focus:not(.btn-text) i, .btn.focus i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn.disabled, .btn:disabled {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn.disabled i, .btn:disabled i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn:not(:disabled):not(.disabled):active:not(.btn-text), .btn:not(:disabled):not(.disabled).active,
.show > .btn.dropdown-toggle {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn:not(:disabled):not(.disabled).active i,
.show > .btn.dropdown-toggle i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.btn:focus:not(.btn-shadow):not(.btn-shadow-hover) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
.btn.btn-square {
    border-radius: 0; }
.btn.btn-pill {
    border-radius: 2rem; }
.btn.btn-text {
    cursor: text; }
.btn:not(.btn-text) {
    cursor: pointer; }
.btn.btn-borderless {
    border-color: transparent; }

.btn i {
    font-size: 1.3rem;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }

.btn .svg-icon {
    margin-right: 0.5rem; }
.btn .svg-icon svg {
    height: 18px;
    width: 18px; }

.btn.btn-xs i {
    font-size: 1rem;
    padding-right: 0.3rem; }

.btn.btn-xs .svg-icon {
    margin-right: 0.3rem; }
.btn.btn-xs .svg-icon svg {
    height: 16px;
    width: 16px; }

.btn.btn-sm i, .btn-group-sm > .btn i {
    font-size: 1.3rem;
    padding-right: 0.35rem; }

.btn.btn-sm .svg-icon, .btn-group-sm > .btn .svg-icon {
    margin-right: 0.35rem; }
.btn.btn-sm .svg-icon svg, .btn-group-sm > .btn .svg-icon svg {
    height: 16px;
    width: 16px; }

.btn.btn-lg i, .btn-group-lg > .btn i {
    font-size: 2rem;
    padding-right: 0.75rem; }

.btn.btn-lg .svg-icon, .btn-group-lg > .btn .svg-icon {
    margin-right: 0.75rem; }
.btn.btn-lg .svg-icon svg, .btn-group-lg > .btn .svg-icon svg {
    height: 20px;
    width: 20px; }

.btn.btn-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    height: calc(1.5em + 1.3rem + 2px);
    width: calc(1.5em + 1.3rem + 2px); }
.btn.btn-icon.btn-xs {
    height: 24px;
    width: 24px; }
.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px); }
.btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon {
    height: calc(1.5em + 1.65rem + 2px);
    width: calc(1.5em + 1.65rem + 2px); }
.btn.btn-icon.btn-circle {
    border-radius: 50%; }
.btn.btn-icon i {
    padding: 0;
    margin: 0; }
.btn.btn-icon [class^="socicon-"]:before,
.btn.btn-icon [class*=" socicon-"]:before {
    line-height: 1; }
.btn.btn-icon .svg-icon {
    margin: 0;
    padding: 0; }

.btn.btn-fixed-height {
    padding-top: 0;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
.btn.btn-fixed-height.btn {
    height: calc(1.5em + 1.3rem + 2px); }
.btn.btn-fixed-height.btn-sm, .btn-group-sm > .btn.btn-fixed-height {
    height: calc(1.35em + 1.1rem + 2px); }
.btn.btn-fixed-height.btn-lg, .btn-group-lg > .btn.btn-fixed-height {
    height: calc(1.5em + 1.65rem + 2px); }

.btn-group.dropup > .btn.dropdown-toggle:after {
    font-family: Ki;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: ""; }

.btn-group.dropright > .btn.dropdown-toggle:after {
    font-family: Ki;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: ""; }

.btn-group.dropleft > .btn {
    border-top-right-radius: 0.42rem !important;
    border-bottom-right-radius: 0.42rem !important; }
.btn-group.dropleft > .btn.dropdown-toggle-split {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
.btn-group.dropleft > .btn.dropdown-toggle:after {
    display: none; }
.btn-group.dropleft > .btn.dropdown-toggle:before {
    display: inline; }

/*.btn.btn-clean {*/
/*    color: #B5B5C3 !important;*/
/*    background-color: transparent !important;*/
/*    border-color: transparent !important; }*/
.btn.btn-clean i {
    color: white; }
.btn.btn-clean .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #fbfbff; }
.btn.btn-clean .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-clean.dropdown-toggle:after {
    color: var(--elem-bg); }
.btn.btn-clean:hover:not(.btn-text):not(:disabled), .btn.btn-clean:focus:not(.btn-text), .btn.btn-clean.focus:not(.btn-text) {
    color: var(--originaltwo) !important;
    background-color: var(--left-menu-icon-color) !important;
    border-color: transparent !important; }
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-clean:focus:not(.btn-text) i, .btn.btn-clean.focus:not(.btn-text) i {
    color: var(--icon-primary); }
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-clean:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-clean:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-clean.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-clean:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-clean.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }
.btn.btn-clean.disabled, .btn.btn-clean:disabled {
    color: #B5B5C3;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-clean.disabled i, .btn.btn-clean:disabled i {
    color: #B5B5C3; }
.btn.btn-clean.disabled .svg-icon svg g [fill], .btn.btn-clean:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }
.btn.btn-clean.disabled .svg-icon svg:hover g [fill], .btn.btn-clean:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-clean.disabled.dropdown-toggle:after, .btn.btn-clean:disabled.dropdown-toggle:after {
    color: #B5B5C3; }
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-clean:not(:disabled):not(.disabled).active,
.show > .btn.btn-clean.dropdown-toggle,
.show .btn.btn-clean.btn-dropdown {
    color: var(--success);
    background-color: white;
    border-color: transparent; }
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-clean:not(:disabled):not(.disabled).active i,
.show > .btn.btn-clean.dropdown-toggle i,
.show .btn.btn-clean.btn-dropdown i {
    color: var(--icon-primary); }
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-clean:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-clean.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-clean.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-default {
    color: #B5B5C3;
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.btn.btn-default i {
    color: #B5B5C3; }
.btn.btn-default .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }
.btn.btn-default .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-default.dropdown-toggle:after {
    color: #B5B5C3; }
.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-default:focus:not(.btn-text), .btn.btn-default.focus:not(.btn-text) {
    color: var(--success);
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-default:focus:not(.btn-text) i, .btn.btn-default.focus:not(.btn-text) i {
    color: var(--success); }
.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-default:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-default.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-default:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-default.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-default:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-default.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }
.btn.btn-default.disabled, .btn.btn-default:disabled {
    color: #B5B5C3;
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-default.disabled i, .btn.btn-default:disabled i {
    color: #B5B5C3; }
.btn.btn-default.disabled .svg-icon svg g [fill], .btn.btn-default:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }
.btn.btn-default.disabled .svg-icon svg:hover g [fill], .btn.btn-default:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-default.disabled.dropdown-toggle:after, .btn.btn-default:disabled.dropdown-toggle:after {
    color: #B5B5C3; }
.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-default.dropdown-toggle,
.show .btn.btn-default.btn-dropdown {
    color: var(--success);
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-default:not(:disabled):not(.disabled).active i,
.show > .btn.btn-default.dropdown-toggle i,
.show .btn.btn-default.btn-dropdown i {
    color: var(--success); }
.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-default:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-default.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-default.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-default:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-default.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-default.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-default:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-default.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-default.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-primary {
    color: var(--left-menu-icon-color);
    background-color: var(--btn-primary);
    border-color: var(--btn-primary); }
.btn.btn-primary i {
    color: var(--left-menu-icon-color); }
.btn.btn-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-primary.dropdown-toggle:after {
    color: white; }
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text) {
    color: white;
    background-color: var(--btn-hover);
    border-color: var(--btn-hover); }
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-primary:focus:not(.btn-text) i, .btn.btn-primary.focus:not(.btn-text) i {
    color: white; }
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-primary.disabled, .btn.btn-primary:disabled {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-primary.disabled i, .btn.btn-primary:disabled i {
    color: white; }
.btn.btn-primary.disabled .svg-icon svg g [fill], .btn.btn-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-primary.disabled.dropdown-toggle:after, .btn.btn-primary:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.dropdown-toggle,
.show .btn.btn-primary.btn-dropdown {
    color: white;
    background-color: var(--original-light);
    border-color: var(--original-light); }
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-primary.dropdown-toggle i,
.show .btn.btn-primary.btn-dropdown i {
    color: white; }
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-primary.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-primary {
    color: var(--success);
    background-color: transparent;
    border-color: var(--success); }
.btn.btn-outline-primary i {
    color: var(--success); }
.btn.btn-outline-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-outline-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-primary.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text) {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-primary:focus:not(.btn-text) i, .btn.btn-outline-primary.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
    color: var(--success);
    background-color: transparent;
    border-color: var(--success); }
.btn.btn-outline-primary.disabled i, .btn.btn-outline-primary:disabled i {
    color: var(--success); }
.btn.btn-outline-primary.disabled .svg-icon svg g [fill], .btn.btn-outline-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-outline-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-primary.disabled.dropdown-toggle:after, .btn.btn-outline-primary:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-primary.dropdown-toggle,
.show .btn.btn-outline-primary.btn-dropdown {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-primary.dropdown-toggle i,
.show .btn.btn-outline-primary.btn-dropdown i {
    color: white; }
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-primary.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-primary {
    color: var(--success);
    background-color: var(--elem-bg);
    border-color: transparent; }
.btn.btn-light-primary i {
    color: var(--success); }
.btn.btn-light-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-light-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-primary.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-primary:focus:not(.btn-text), .btn.btn-light-primary.focus:not(.btn-text) {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-primary:focus:not(.btn-text) i, .btn.btn-light-primary.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-primary.disabled, .btn.btn-light-primary:disabled {
    color: var(--success);
    background-color: var(--elem-bg);
    border-color: transparent; }
.btn.btn-light-primary.disabled i, .btn.btn-light-primary:disabled i {
    color: var(--success); }
.btn.btn-light-primary.disabled .svg-icon svg g [fill], .btn.btn-light-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-light-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-light-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-primary.disabled.dropdown-toggle:after, .btn.btn-light-primary:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-primary.dropdown-toggle,
.show .btn.btn-light-primary.btn-dropdown {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-primary.dropdown-toggle i,
.show .btn.btn-light-primary.btn-dropdown i {
    color: white; }
.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-primary.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-primary {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1);
    border-color: transparent; }
.btn.btn-transparent-primary i {
    color: var(--success); }
.btn.btn-transparent-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-transparent-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-primary.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-primary:focus:not(.btn-text), .btn.btn-transparent-primary.focus:not(.btn-text) {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-primary:focus:not(.btn-text) i, .btn.btn-transparent-primary.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-primary.disabled, .btn.btn-transparent-primary:disabled {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1);
    border-color: transparent; }
.btn.btn-transparent-primary.disabled i, .btn.btn-transparent-primary:disabled i {
    color: var(--success); }
.btn.btn-transparent-primary.disabled .svg-icon svg g [fill], .btn.btn-transparent-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-transparent-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-primary.disabled.dropdown-toggle:after, .btn.btn-transparent-primary:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-primary.dropdown-toggle,
.show .btn.btn-transparent-primary.btn-dropdown {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-primary.dropdown-toggle i,
.show .btn.btn-transparent-primary.btn-dropdown i {
    color: white; }
.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-primary.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-primary {
    color: var(--success);
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-primary i {
    color: var(--success); }
.btn.btn-hover-transparent-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-primary.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-primary:focus:not(.btn-text), .btn.btn-hover-transparent-primary.focus:not(.btn-text) {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-primary:focus:not(.btn-text) i, .btn.btn-hover-transparent-primary.focus:not(.btn-text) i {
    color: var(--success); }
.btn.btn-hover-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-primary.disabled, .btn.btn-hover-transparent-primary:disabled {
    color: var(--success);
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-primary.disabled i, .btn.btn-hover-transparent-primary:disabled i {
    color: var(--success); }
.btn.btn-hover-transparent-primary.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-primary.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-primary:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle,
.show .btn.btn-hover-transparent-primary.btn-dropdown {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle i,
.show .btn.btn-hover-transparent-primary.btn-dropdown i {
    color: var(--success); }
.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-primary.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-primary:focus:not(.btn-text), .btn.btn-hover-light-primary.focus:not(.btn-text) {
    color: var(--success);
    background-color: #C9F7F5;
    border-color: transparent; }
.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-primary:focus:not(.btn-text) i, .btn.btn-hover-light-primary.focus:not(.btn-text) i {
    color: var(--success); }
.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-primary.dropdown-toggle,
.show .btn.btn-hover-light-primary.btn-dropdown {
    color: var(--success);
    background-color: #C9F7F5;
    border-color: transparent; }
.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-primary.dropdown-toggle i,
.show .btn.btn-hover-light-primary.btn-dropdown i {
    color: var(--success); }
.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-primary.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-primary.btn-clean {
    border: 0 !important; }

.btn.btn-link-primary {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: var(--success);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-primary i {
    color: var(--success); }
.btn.btn-link-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-link-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-primary.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-link-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-primary:focus:not(.btn-text), .btn.btn-link-primary.focus:not(.btn-text) {
    color: var(--hover-button);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-primary:focus:not(.btn-text) i, .btn.btn-link-primary.focus:not(.btn-text) i {
    color: var(--hover-button); }
.btn.btn-link-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--hover-button); }
.btn.btn-link-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--hover-button); }
.btn.btn-link-primary.disabled, .btn.btn-link-primary:disabled {
    color: var(--success);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-primary.disabled i, .btn.btn-link-primary:disabled i {
    color: var(--success); }
.btn.btn-link-primary.disabled .svg-icon svg g [fill], .btn.btn-link-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-link-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-link-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-primary.disabled.dropdown-toggle:after, .btn.btn-link-primary:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-primary.dropdown-toggle,
.show .btn.btn-link-primary.btn-dropdown {
    color: var(--hover-button);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-primary.dropdown-toggle i,
.show .btn.btn-link-primary.btn-dropdown i {
    color: var(--hover-button); }
.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--hover-button); }
.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-primary.btn-dropdown.dropdown-toggle:after {
    color: var(--hover-button); }

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary.focus:not(.btn-text) {
    color: var(--left-menu-icon-color) !important;
    background-color: var(--success) !important;
    border-color: var(--success) !important; }
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-primary:focus:not(.btn-text) i, .btn.btn-hover-primary.focus:not(.btn-text) i {
    color: var(--left-menu-icon-color) !important; }
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
    color: white !important;
    background-color: var(--success) !important;
    border-color: var(--success) !important; }
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-primary.dropdown-toggle i,
.show .btn.btn-hover-primary.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-primary.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-primary.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-primary:focus:not(.btn-text), .btn.btn-hover-bg-primary.focus:not(.btn-text) {
    background-color: var(--success) !important;
    border-color: var(--success) !important; }

.btn.btn-hover-bg-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-primary.dropdown-toggle,
.show .btn.btn-hover-bg-primary.btn-dropdown {
    background-color: var(--success) !important;
    border-color: var(--success) !important; }

.btn.btn-bg-primary {
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-bg-primary.disabled, .btn.btn-bg-primary:disabled {
    background-color: var(--success);
    border-color: var(--success); }

.btn.btn-primary.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important; }
.btn.btn-primary.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary.btn-shadow:focus:not(.btn-text), .btn.btn-primary.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important; }
.btn.btn-primary.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important; }

.btn.btn-primary.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary.btn-shadow-hover:focus:not(.btn-text), .btn.btn-primary.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important; }

.btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important; }

.btn.btn-secondary {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn.btn-secondary i {
    color: #3F4254; }
.btn.btn-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-secondary.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-secondary:focus:not(.btn-text), .btn.btn-secondary.focus:not(.btn-text) {
    color: #3F4254;
    background-color: #d7dae7;
    border-color: #d7dae7; }
.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-secondary:focus:not(.btn-text) i, .btn.btn-secondary.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn.btn-secondary.disabled i, .btn.btn-secondary:disabled i {
    color: #3F4254; }
.btn.btn-secondary.disabled .svg-icon svg g [fill], .btn.btn-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-secondary.disabled.dropdown-toggle:after, .btn.btn-secondary:disabled.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.dropdown-toggle,
.show .btn.btn-secondary.btn-dropdown {
    color: #3F4254;
    background-color: #d7dae7;
    border-color: #d7dae7; }
.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-secondary.dropdown-toggle i,
.show .btn.btn-secondary.btn-dropdown i {
    color: #3F4254; }
.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-secondary.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-outline-secondary {
    color: #3F4254;
    background-color: transparent;
    border-color: #E4E6EF; }
.btn.btn-outline-secondary i {
    color: #3F4254; }
.btn.btn-outline-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-secondary.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-secondary:focus:not(.btn-text), .btn.btn-outline-secondary.focus:not(.btn-text) {
    color: #3F4254;
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-secondary:focus:not(.btn-text) i, .btn.btn-outline-secondary.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
    color: #3F4254;
    background-color: transparent;
    border-color: #E4E6EF; }
.btn.btn-outline-secondary.disabled i, .btn.btn-outline-secondary:disabled i {
    color: #3F4254; }
.btn.btn-outline-secondary.disabled .svg-icon svg g [fill], .btn.btn-outline-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-secondary.disabled.dropdown-toggle:after, .btn.btn-outline-secondary:disabled.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-secondary.dropdown-toggle,
.show .btn.btn-outline-secondary.btn-dropdown {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-secondary.dropdown-toggle i,
.show .btn.btn-outline-secondary.btn-dropdown i {
    color: #3F4254; }
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-secondary.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-light-secondary {
    color: #E4E6EF;
    background-color: #EBEDF3;
    border-color: transparent; }
.btn.btn-light-secondary i {
    color: #E4E6EF; }
.btn.btn-light-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-light-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-secondary.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-secondary:focus:not(.btn-text), .btn.btn-light-secondary.focus:not(.btn-text) {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: transparent; }
.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-secondary:focus:not(.btn-text) i, .btn.btn-light-secondary.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-light-secondary.disabled, .btn.btn-light-secondary:disabled {
    color: #E4E6EF;
    background-color: #EBEDF3;
    border-color: transparent; }
.btn.btn-light-secondary.disabled i, .btn.btn-light-secondary:disabled i {
    color: #E4E6EF; }
.btn.btn-light-secondary.disabled .svg-icon svg g [fill], .btn.btn-light-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-light-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-light-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-secondary.disabled.dropdown-toggle:after, .btn.btn-light-secondary:disabled.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-secondary.dropdown-toggle,
.show .btn.btn-light-secondary.btn-dropdown {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: transparent; }
.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-secondary.dropdown-toggle i,
.show .btn.btn-light-secondary.btn-dropdown i {
    color: #3F4254; }
.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-secondary.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-transparent-secondary {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1);
    border-color: transparent; }
.btn.btn-transparent-secondary i {
    color: #E4E6EF; }
.btn.btn-transparent-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-transparent-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-secondary.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-secondary:focus:not(.btn-text), .btn.btn-transparent-secondary.focus:not(.btn-text) {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: transparent; }
.btn.btn-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-secondary:focus:not(.btn-text) i, .btn.btn-transparent-secondary.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-transparent-secondary.disabled, .btn.btn-transparent-secondary:disabled {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1);
    border-color: transparent; }
.btn.btn-transparent-secondary.disabled i, .btn.btn-transparent-secondary:disabled i {
    color: #E4E6EF; }
.btn.btn-transparent-secondary.disabled .svg-icon svg g [fill], .btn.btn-transparent-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-transparent-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-secondary.disabled.dropdown-toggle:after, .btn.btn-transparent-secondary:disabled.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-secondary.dropdown-toggle,
.show .btn.btn-transparent-secondary.btn-dropdown {
    color: #3F4254;
    background-color: #E4E6EF;
    border-color: transparent; }
.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-secondary.dropdown-toggle i,
.show .btn.btn-transparent-secondary.btn-dropdown i {
    color: #3F4254; }
.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-secondary.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-hover-transparent-secondary {
    color: #E4E6EF;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-secondary i {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-transparent-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-secondary.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-secondary:focus:not(.btn-text), .btn.btn-hover-transparent-secondary.focus:not(.btn-text) {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-secondary:focus:not(.btn-text) i, .btn.btn-hover-transparent-secondary.focus:not(.btn-text) i {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary.disabled, .btn.btn-hover-transparent-secondary:disabled {
    color: #E4E6EF;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-secondary.disabled i, .btn.btn-hover-transparent-secondary:disabled i {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-transparent-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-secondary.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-secondary:disabled.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle,
.show .btn.btn-hover-transparent-secondary.btn-dropdown {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle i,
.show .btn.btn-hover-transparent-secondary.btn-dropdown i {
    color: #E4E6EF; }
.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-secondary.btn-dropdown.dropdown-toggle:after {
    color: #E4E6EF; }

.btn.btn-hover-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-secondary:focus:not(.btn-text), .btn.btn-hover-light-secondary.focus:not(.btn-text) {
    color: #E4E6EF;
    background-color: #EBEDF3;
    border-color: transparent; }
.btn.btn-hover-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-secondary:focus:not(.btn-text) i, .btn.btn-hover-light-secondary.focus:not(.btn-text) i {
    color: #E4E6EF; }
.btn.btn-hover-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #E4E6EF; }

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-secondary.dropdown-toggle,
.show .btn.btn-hover-light-secondary.btn-dropdown {
    color: #E4E6EF;
    background-color: #EBEDF3;
    border-color: transparent; }
.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-secondary.dropdown-toggle i,
.show .btn.btn-hover-light-secondary.btn-dropdown i {
    color: #E4E6EF; }
.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-secondary.btn-dropdown.dropdown-toggle:after {
    color: #E4E6EF; }

.btn.btn-hover-light-secondary.btn-clean {
    border: 0 !important; }

.btn.btn-link-secondary {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #E4E6EF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-secondary i {
    color: #E4E6EF; }
.btn.btn-link-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-link-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-secondary.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-link-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-secondary:focus:not(.btn-text), .btn.btn-link-secondary.focus:not(.btn-text) {
    color: #d7dae7;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-secondary:focus:not(.btn-text) i, .btn.btn-link-secondary.focus:not(.btn-text) i {
    color: #d7dae7; }
.btn.btn-link-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #d7dae7; }
.btn.btn-link-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #d7dae7; }
.btn.btn-link-secondary.disabled, .btn.btn-link-secondary:disabled {
    color: #E4E6EF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-secondary.disabled i, .btn.btn-link-secondary:disabled i {
    color: #E4E6EF; }
.btn.btn-link-secondary.disabled .svg-icon svg g [fill], .btn.btn-link-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-link-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-link-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-secondary.disabled.dropdown-toggle:after, .btn.btn-link-secondary:disabled.dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-secondary.dropdown-toggle,
.show .btn.btn-link-secondary.btn-dropdown {
    color: #d7dae7;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-secondary.dropdown-toggle i,
.show .btn.btn-link-secondary.btn-dropdown i {
    color: #d7dae7; }
.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #d7dae7; }
.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-secondary.btn-dropdown.dropdown-toggle:after {
    color: #d7dae7; }

.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-secondary:focus:not(.btn-text), .btn.btn-hover-secondary.focus:not(.btn-text) {
    color: #3F4254 !important;
    background-color: #E4E6EF !important;
    border-color: #E4E6EF !important; }
.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-secondary:focus:not(.btn-text) i, .btn.btn-hover-secondary.focus:not(.btn-text) i {
    color: #3F4254 !important; }
.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-secondary.dropdown-toggle,
.show .btn.btn-hover-secondary.btn-dropdown {
    color: #3F4254 !important;
    background-color: #E4E6EF !important;
    border-color: #E4E6EF !important; }
.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-secondary.dropdown-toggle i,
.show .btn.btn-hover-secondary.btn-dropdown i {
    color: #3F4254 !important; }
.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-secondary.btn-dropdown.dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-hover-secondary.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-secondary:focus:not(.btn-text), .btn.btn-hover-bg-secondary.focus:not(.btn-text) {
    background-color: #E4E6EF !important;
    border-color: #E4E6EF !important; }

.btn.btn-hover-bg-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-secondary.dropdown-toggle,
.show .btn.btn-hover-bg-secondary.btn-dropdown {
    background-color: #E4E6EF !important;
    border-color: #E4E6EF !important; }

.btn.btn-bg-secondary {
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.btn.btn-bg-secondary.disabled, .btn.btn-bg-secondary:disabled {
    background-color: #E4E6EF;
    border-color: #E4E6EF; }

.btn.btn-secondary.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important; }
.btn.btn-secondary.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-secondary.btn-shadow:focus:not(.btn-text), .btn.btn-secondary.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }
.btn.btn-secondary.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-secondary.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-secondary.btn-shadow-hover:focus:not(.btn-text), .btn.btn-secondary.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-secondary.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-success {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-success i {
    color: white; }
.btn.btn-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-success.dropdown-toggle:after {
    color: white; }
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-success:focus:not(.btn-text), .btn.btn-success.focus:not(.btn-text) {
    color: white;
    background-color: var(--originaltwo);
    border-color: var(--originaltwo); }
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-success:focus:not(.btn-text) i, .btn.btn-success.focus:not(.btn-text) i {
    color: white; }
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-success.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-success.disabled, .btn.btn-success:disabled {
    color: white;
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-success.disabled i, .btn.btn-success:disabled i {
    color: white; }
.btn.btn-success.disabled .svg-icon svg g [fill], .btn.btn-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-success.disabled .svg-icon svg:hover g [fill], .btn.btn-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-success.disabled.dropdown-toggle:after, .btn.btn-success:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.dropdown-toggle,
.show .btn.btn-success.btn-dropdown {
    color: white;
    background-color: var(--originaltwo);
    border-color: var(--originaltwo); }
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-success.dropdown-toggle i,
.show .btn.btn-success.btn-dropdown i {
    color: white; }
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-success.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-success {
    color: var(--icon-primary);
    background-color: transparent;
    border-color: var(--icon-primary); }
.btn.btn-outline-success i {
    color: var(--icon-primary); }
.btn.btn-outline-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--icon-primary); }
.btn.btn-outline-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-success.dropdown-toggle:after {
    color: var(--icon-primary); }
.btn.btn-outline-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-success:focus:not(.btn-text), .btn.btn-outline-success.focus:not(.btn-text) {
    color: white;
    background-color: var(--icon-primary);
    border-color: var(--icon-primary); }
.btn.btn-outline-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-success:focus:not(.btn-text) i, .btn.btn-outline-success.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-success.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-success.disabled, .btn.btn-outline-success:disabled {
    color: var(--icon-primary);
    background-color: transparent;
    border-color: var(--icon-primary); }
.btn.btn-outline-success.disabled i, .btn.btn-outline-success:disabled i {
    color: var(--icon-primary); }
.btn.btn-outline-success.disabled .svg-icon svg g [fill], .btn.btn-outline-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--icon-primary); }
.btn.btn-outline-success.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-success.disabled.dropdown-toggle:after, .btn.btn-outline-success:disabled.dropdown-toggle:after {
    color: var(--icon-primary); }
.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-success.dropdown-toggle,
.show .btn.btn-outline-success.btn-dropdown {
    color: white;
    background-color: var(--icon-primary);
    border-color: var(--icon-primary); }
.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-success.dropdown-toggle i,
.show .btn.btn-outline-success.btn-dropdown i {
    color: white; }
.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-success.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-success {
    color: var(--success);
    background-color: var(--elem-bg);
    border-color: transparent; }
.btn.btn-light-success i {
    color: var(--success); }
.btn.btn-light-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-light-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-success.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-success:focus:not(.btn-text), .btn.btn-light-success.focus:not(.btn-text) {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-success:focus:not(.btn-text) i, .btn.btn-light-success.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-success.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-success.disabled, .btn.btn-light-success:disabled {
    color: var(--success);
    background-color: var(--elem-bg);
    border-color: transparent; }
.btn.btn-light-success.disabled i, .btn.btn-light-success:disabled i {
    color: var(--success); }
.btn.btn-light-success.disabled .svg-icon svg g [fill], .btn.btn-light-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-light-success.disabled .svg-icon svg:hover g [fill], .btn.btn-light-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-success.disabled.dropdown-toggle:after, .btn.btn-light-success:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-success.dropdown-toggle,
.show .btn.btn-light-success.btn-dropdown {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-success.dropdown-toggle i,
.show .btn.btn-light-success.btn-dropdown i {
    color: white; }
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-success.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-success {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-success i {
    color: var(--success); }
.btn.btn-transparent-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-transparent-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-success.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-success:focus:not(.btn-text), .btn.btn-transparent-success.focus:not(.btn-text) {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-success:focus:not(.btn-text) i, .btn.btn-transparent-success.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-success.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-success.disabled, .btn.btn-transparent-success:disabled {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-success.disabled i, .btn.btn-transparent-success:disabled i {
    color: var(--success); }
.btn.btn-transparent-success.disabled .svg-icon svg g [fill], .btn.btn-transparent-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-transparent-success.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-success.disabled.dropdown-toggle:after, .btn.btn-transparent-success:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-success.dropdown-toggle,
.show .btn.btn-transparent-success.btn-dropdown {
    color: white;
    background-color: var(--success);
    border-color: transparent; }
.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-success.dropdown-toggle i,
.show .btn.btn-transparent-success.btn-dropdown i {
    color: white; }
.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-success.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-success {
    color: var(--success);
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-success i {
    color: var(--success); }
.btn.btn-hover-transparent-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-success.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-success:focus:not(.btn-text), .btn.btn-hover-transparent-success.focus:not(.btn-text) {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-success:focus:not(.btn-text) i, .btn.btn-hover-transparent-success.focus:not(.btn-text) i {
    color: var(--success); }
.btn.btn-hover-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-success.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-success.disabled, .btn.btn-hover-transparent-success:disabled {
    color: var(--success);
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-success.disabled i, .btn.btn-hover-transparent-success:disabled i {
    color: var(--success); }
.btn.btn-hover-transparent-success.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-success.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-success.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-success:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-success.dropdown-toggle,
.show .btn.btn-hover-transparent-success.btn-dropdown {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-success.dropdown-toggle i,
.show .btn.btn-hover-transparent-success.btn-dropdown i {
    color: var(--success); }
.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-success.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-success:focus:not(.btn-text), .btn.btn-hover-light-success.focus:not(.btn-text) {
    color: var(--success);
    background-color: #E1F0FF;
    border-color: transparent; }
.btn.btn-hover-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-success:focus:not(.btn-text) i, .btn.btn-hover-light-success.focus:not(.btn-text) i {
    color: var(--success); }
.btn.btn-hover-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-light-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-success.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-success.dropdown-toggle,
.show .btn.btn-hover-light-success.btn-dropdown {
    color: var(--success);
    background-color: #E1F0FF;
    border-color: transparent; }
.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-success.dropdown-toggle i,
.show .btn.btn-hover-light-success.btn-dropdown i {
    color: var(--success); }
.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-success.btn-dropdown.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-light-success.btn-clean {
    border: 0 !important; }

.btn.btn-link-success {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: var(--success);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-success i {
    color: var(--success); }
.btn.btn-link-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-link-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-success.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-link-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-success:focus:not(.btn-text), .btn.btn-link-success.focus:not(.btn-text) {
    color: #187DE4;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-success:focus:not(.btn-text) i, .btn.btn-link-success.focus:not(.btn-text) i {
    color: #187DE4; }
.btn.btn-link-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #187DE4; }
.btn.btn-link-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-success.focus:not(.btn-text).dropdown-toggle:after {
    color: #187DE4; }
.btn.btn-link-success.disabled, .btn.btn-link-success:disabled {
    color: var(--success);
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-success.disabled i, .btn.btn-link-success:disabled i {
    color: var(--success); }
.btn.btn-link-success.disabled .svg-icon svg g [fill], .btn.btn-link-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.btn.btn-link-success.disabled .svg-icon svg:hover g [fill], .btn.btn-link-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-success.disabled.dropdown-toggle:after, .btn.btn-link-success:disabled.dropdown-toggle:after {
    color: var(--success); }
.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-success.dropdown-toggle,
.show .btn.btn-link-success.btn-dropdown {
    color: #187DE4;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-success.dropdown-toggle i,
.show .btn.btn-link-success.btn-dropdown i {
    color: #187DE4; }
.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #187DE4; }
.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-success.btn-dropdown.dropdown-toggle:after {
    color: #187DE4; }

.btn.btn-hover-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-success:focus:not(.btn-text), .btn.btn-hover-success.focus:not(.btn-text) {
    color: white !important;
    background-color: var(--success) !important;
    border-color: var(--success) !important; }
.btn.btn-hover-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-success:focus:not(.btn-text) i, .btn.btn-hover-success.focus:not(.btn-text) i {
    color: white !important; }
.btn.btn-hover-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-success.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-success.dropdown-toggle,
.show .btn.btn-hover-success.btn-dropdown {
    color: white !important;
    background-color: var(--success) !important;
    border-color: var(--success) !important; }
.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-success.dropdown-toggle i,
.show .btn.btn-hover-success.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-success.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-success.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-success:focus:not(.btn-text), .btn.btn-hover-bg-success.focus:not(.btn-text) {
    background-color: var(--success) !important;
    border-color: var(--success) !important; }

.btn.btn-hover-bg-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-success.dropdown-toggle,
.show .btn.btn-hover-bg-success.btn-dropdown {
    background-color: var(--success) !important;
    border-color: var(--success) !important; }

.btn.btn-bg-success {
    background-color: var(--success);
    border-color: var(--success); }
.btn.btn-bg-success.disabled, .btn.btn-bg-success:disabled {
    background-color: var(--success);
    border-color: var(--success); }

.btn.btn-success.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.25) !important; }
.btn.btn-success.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-success.btn-shadow:focus:not(.btn-text), .btn.btn-success.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important; }
.btn.btn-success.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important; }

.btn.btn-success.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-success.btn-shadow-hover:focus:not(.btn-text), .btn.btn-success.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important; }

.btn.btn-success.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(54, 153, 255, 0.3) !important; }

.btn.btn-info {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn.btn-info i {
    color: white; }
.btn.btn-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-info.dropdown-toggle:after {
    color: white; }
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-info:focus:not(.btn-text), .btn.btn-info.focus:not(.btn-text) {
    color: white;
    background-color: #4A7DFF;
    border-color: #4A7DFF; }
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-info:focus:not(.btn-text) i, .btn.btn-info.focus:not(.btn-text) i {
    color: white; }
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-info.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-info.disabled, .btn.btn-info:disabled {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn.btn-info.disabled i, .btn.btn-info:disabled i {
    color: white; }
.btn.btn-info.disabled .svg-icon svg g [fill], .btn.btn-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-info.disabled .svg-icon svg:hover g [fill], .btn.btn-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-info.disabled.dropdown-toggle:after, .btn.btn-info:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.dropdown-toggle,
.show .btn.btn-info.btn-dropdown {
    color: white;
    background-color: #4A7DFF;
    border-color: #4A7DFF; }
.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-info.dropdown-toggle i,
.show .btn.btn-info.btn-dropdown i {
    color: white; }
.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-info.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-info {
    color: #6993FF;
    background-color: transparent;
    border-color: #6993FF; }
.btn.btn-outline-info i {
    color: #6993FF; }
.btn.btn-outline-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-outline-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-info.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-info:focus:not(.btn-text), .btn.btn-outline-info.focus:not(.btn-text) {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-info:focus:not(.btn-text) i, .btn.btn-outline-info.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-info.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-info.disabled, .btn.btn-outline-info:disabled {
    color: #6993FF;
    background-color: transparent;
    border-color: #6993FF; }
.btn.btn-outline-info.disabled i, .btn.btn-outline-info:disabled i {
    color: #6993FF; }
.btn.btn-outline-info.disabled .svg-icon svg g [fill], .btn.btn-outline-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-outline-info.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-info.disabled.dropdown-toggle:after, .btn.btn-outline-info:disabled.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-info.dropdown-toggle,
.show .btn.btn-outline-info.btn-dropdown {
    color: white;
    background-color: #6993FF;
    border-color: #6993FF; }
.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-info.dropdown-toggle i,
.show .btn.btn-outline-info.btn-dropdown i {
    color: white; }
.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-info.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-info {
    color: #6993FF;
    background-color: #E1E9FF;
    border-color: transparent; }
.btn.btn-light-info i {
    color: #6993FF; }
.btn.btn-light-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-light-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-info.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-light-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-info:focus:not(.btn-text), .btn.btn-light-info.focus:not(.btn-text) {
    color: white;
    background-color: #6993FF;
    border-color: transparent; }
.btn.btn-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-info:focus:not(.btn-text) i, .btn.btn-light-info.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-info.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-info.disabled, .btn.btn-light-info:disabled {
    color: #6993FF;
    background-color: #E1E9FF;
    border-color: transparent; }
.btn.btn-light-info.disabled i, .btn.btn-light-info:disabled i {
    color: #6993FF; }
.btn.btn-light-info.disabled .svg-icon svg g [fill], .btn.btn-light-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-light-info.disabled .svg-icon svg:hover g [fill], .btn.btn-light-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-info.disabled.dropdown-toggle:after, .btn.btn-light-info:disabled.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-info.dropdown-toggle,
.show .btn.btn-light-info.btn-dropdown {
    color: white;
    background-color: #6993FF;
    border-color: transparent; }
.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-info.dropdown-toggle i,
.show .btn.btn-light-info.btn-dropdown i {
    color: white; }
.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-info.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-info {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-info i {
    color: #6993FF; }
.btn.btn-transparent-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-transparent-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-info.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-info:focus:not(.btn-text), .btn.btn-transparent-info.focus:not(.btn-text) {
    color: white;
    background-color: #6993FF;
    border-color: transparent; }
.btn.btn-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-info:focus:not(.btn-text) i, .btn.btn-transparent-info.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-info.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-info.disabled, .btn.btn-transparent-info:disabled {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-info.disabled i, .btn.btn-transparent-info:disabled i {
    color: #6993FF; }
.btn.btn-transparent-info.disabled .svg-icon svg g [fill], .btn.btn-transparent-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-transparent-info.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-info.disabled.dropdown-toggle:after, .btn.btn-transparent-info:disabled.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-info.dropdown-toggle,
.show .btn.btn-transparent-info.btn-dropdown {
    color: white;
    background-color: #6993FF;
    border-color: transparent; }
.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-info.dropdown-toggle i,
.show .btn.btn-transparent-info.btn-dropdown i {
    color: white; }
.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-info.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-info {
    color: #6993FF;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-info i {
    color: #6993FF; }
.btn.btn-hover-transparent-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-transparent-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-info.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-hover-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-info:focus:not(.btn-text), .btn.btn-hover-transparent-info.focus:not(.btn-text) {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-info:focus:not(.btn-text) i, .btn.btn-hover-transparent-info.focus:not(.btn-text) i {
    color: #6993FF; }
.btn.btn-hover-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-info.focus:not(.btn-text).dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-hover-transparent-info.disabled, .btn.btn-hover-transparent-info:disabled {
    color: #6993FF;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-info.disabled i, .btn.btn-hover-transparent-info:disabled i {
    color: #6993FF; }
.btn.btn-hover-transparent-info.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-transparent-info.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-info.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-info:disabled.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-info.dropdown-toggle,
.show .btn.btn-hover-transparent-info.btn-dropdown {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-info.dropdown-toggle i,
.show .btn.btn-hover-transparent-info.btn-dropdown i {
    color: #6993FF; }
.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-info.btn-dropdown.dropdown-toggle:after {
    color: #6993FF; }

.btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-info:focus:not(.btn-text), .btn.btn-hover-light-info.focus:not(.btn-text) {
    color: #6993FF;
    background-color: #E1E9FF;
    border-color: transparent; }
.btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-info:focus:not(.btn-text) i, .btn.btn-hover-light-info.focus:not(.btn-text) i {
    color: #6993FF; }
.btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-info.focus:not(.btn-text).dropdown-toggle:after {
    color: #6993FF; }

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-info.dropdown-toggle,
.show .btn.btn-hover-light-info.btn-dropdown {
    color: #6993FF;
    background-color: #E1E9FF;
    border-color: transparent; }
.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-info.dropdown-toggle i,
.show .btn.btn-hover-light-info.btn-dropdown i {
    color: #6993FF; }
.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-info.btn-dropdown.dropdown-toggle:after {
    color: #6993FF; }

.btn.btn-hover-light-info.btn-clean {
    border: 0 !important; }

.btn.btn-link-info {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #6993FF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-info i {
    color: #6993FF; }
.btn.btn-link-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-link-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-info.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-link-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-info:focus:not(.btn-text), .btn.btn-link-info.focus:not(.btn-text) {
    color: #4A7DFF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-info:focus:not(.btn-text) i, .btn.btn-link-info.focus:not(.btn-text) i {
    color: #4A7DFF; }
.btn.btn-link-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #4A7DFF; }
.btn.btn-link-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-info.focus:not(.btn-text).dropdown-toggle:after {
    color: #4A7DFF; }
.btn.btn-link-info.disabled, .btn.btn-link-info:disabled {
    color: #6993FF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-info.disabled i, .btn.btn-link-info:disabled i {
    color: #6993FF; }
.btn.btn-link-info.disabled .svg-icon svg g [fill], .btn.btn-link-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.btn.btn-link-info.disabled .svg-icon svg:hover g [fill], .btn.btn-link-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-info.disabled.dropdown-toggle:after, .btn.btn-link-info:disabled.dropdown-toggle:after {
    color: #6993FF; }
.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-info.dropdown-toggle,
.show .btn.btn-link-info.btn-dropdown {
    color: #4A7DFF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-info.dropdown-toggle i,
.show .btn.btn-link-info.btn-dropdown i {
    color: #4A7DFF; }
.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #4A7DFF; }
.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-info.btn-dropdown.dropdown-toggle:after {
    color: #4A7DFF; }

.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-info:focus:not(.btn-text), .btn.btn-hover-info.focus:not(.btn-text) {
    color: white !important;
    background-color: #6993FF !important;
    border-color: #6993FF !important; }
.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-info:focus:not(.btn-text) i, .btn.btn-hover-info.focus:not(.btn-text) i {
    color: white !important; }
.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-info.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-info.dropdown-toggle,
.show .btn.btn-hover-info.btn-dropdown {
    color: white !important;
    background-color: #6993FF !important;
    border-color: #6993FF !important; }
.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-info.dropdown-toggle i,
.show .btn.btn-hover-info.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-info.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-info.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-info:focus:not(.btn-text), .btn.btn-hover-bg-info.focus:not(.btn-text) {
    background-color: #6993FF !important;
    border-color: #6993FF !important; }

.btn.btn-hover-bg-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-info.dropdown-toggle,
.show .btn.btn-hover-bg-info.btn-dropdown {
    background-color: #6993FF !important;
    border-color: #6993FF !important; }

.btn.btn-bg-info {
    background-color: #6993FF;
    border-color: #6993FF; }
.btn.btn-bg-info.disabled, .btn.btn-bg-info:disabled {
    background-color: #6993FF;
    border-color: #6993FF; }

.btn.btn-info.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.25) !important; }
.btn.btn-info.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-info.btn-shadow:focus:not(.btn-text), .btn.btn-info.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important; }
.btn.btn-info.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important; }

.btn.btn-info.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-info.btn-shadow-hover:focus:not(.btn-text), .btn.btn-info.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important; }

.btn.btn-info.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important; }

.btn.btn-warning {
    color: white;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn.btn-warning i {
    color: white; }
.btn.btn-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-warning.dropdown-toggle:after {
    color: white; }
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-warning:focus:not(.btn-text), .btn.btn-warning.focus:not(.btn-text) {
    color: white;
    background-color: #EE9D01;
    border-color: #EE9D01; }
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-warning:focus:not(.btn-text) i, .btn.btn-warning.focus:not(.btn-text) i {
    color: white; }
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-warning.disabled, .btn.btn-warning:disabled {
    color: white;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn.btn-warning.disabled i, .btn.btn-warning:disabled i {
    color: white; }
.btn.btn-warning.disabled .svg-icon svg g [fill], .btn.btn-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-warning.disabled.dropdown-toggle:after, .btn.btn-warning:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.dropdown-toggle,
.show .btn.btn-warning.btn-dropdown {
    color: white;
    background-color: #EE9D01;
    border-color: #EE9D01; }
.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-warning.dropdown-toggle i,
.show .btn.btn-warning.btn-dropdown i {
    color: white; }
.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-warning.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-warning {
    color: #FFA800;
    background-color: transparent;
    border-color: #FFA800; }
.btn.btn-outline-warning i {
    color: #FFA800; }
.btn.btn-outline-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-outline-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-warning.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-warning:focus:not(.btn-text), .btn.btn-outline-warning.focus:not(.btn-text) {
    color: white;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-warning:focus:not(.btn-text) i, .btn.btn-outline-warning.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-warning.disabled, .btn.btn-outline-warning:disabled {
    color: #FFA800;
    background-color: transparent;
    border-color: #FFA800; }
.btn.btn-outline-warning.disabled i, .btn.btn-outline-warning:disabled i {
    color: #FFA800; }
.btn.btn-outline-warning.disabled .svg-icon svg g [fill], .btn.btn-outline-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-outline-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-warning.disabled.dropdown-toggle:after, .btn.btn-outline-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-warning.dropdown-toggle,
.show .btn.btn-outline-warning.btn-dropdown {
    color: white;
    background-color: #FFA800;
    border-color: #FFA800; }
.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-warning.dropdown-toggle i,
.show .btn.btn-outline-warning.btn-dropdown i {
    color: white; }
.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-warning.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-warning {
    color: #FFA800;
    background-color: #FFF4DE;
    border-color: transparent; }
.btn.btn-light-warning i {
    color: #FFA800; }
.btn.btn-light-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-light-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-warning.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-warning:focus:not(.btn-text), .btn.btn-light-warning.focus:not(.btn-text) {
    color: white;
    background-color: #FFA800;
    border-color: transparent; }
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-warning:focus:not(.btn-text) i, .btn.btn-light-warning.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-warning.disabled, .btn.btn-light-warning:disabled {
    color: #FFA800;
    background-color: #FFF4DE;
    border-color: transparent; }
.btn.btn-light-warning.disabled i, .btn.btn-light-warning:disabled i {
    color: #FFA800; }
.btn.btn-light-warning.disabled .svg-icon svg g [fill], .btn.btn-light-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-light-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-light-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-warning.disabled.dropdown-toggle:after, .btn.btn-light-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-warning.dropdown-toggle,
.show .btn.btn-light-warning.btn-dropdown {
    color: white;
    background-color: #FFA800;
    border-color: transparent; }
.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-warning.dropdown-toggle i,
.show .btn.btn-light-warning.btn-dropdown i {
    color: white; }
.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-warning.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-warning {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1);
    border-color: transparent; }
.btn.btn-transparent-warning i {
    color: #FFA800; }
.btn.btn-transparent-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-transparent-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-warning.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-warning:focus:not(.btn-text), .btn.btn-transparent-warning.focus:not(.btn-text) {
    color: white;
    background-color: #FFA800;
    border-color: transparent; }
.btn.btn-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-warning:focus:not(.btn-text) i, .btn.btn-transparent-warning.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-warning.disabled, .btn.btn-transparent-warning:disabled {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1);
    border-color: transparent; }
.btn.btn-transparent-warning.disabled i, .btn.btn-transparent-warning:disabled i {
    color: #FFA800; }
.btn.btn-transparent-warning.disabled .svg-icon svg g [fill], .btn.btn-transparent-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-transparent-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-warning.disabled.dropdown-toggle:after, .btn.btn-transparent-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-warning.dropdown-toggle,
.show .btn.btn-transparent-warning.btn-dropdown {
    color: white;
    background-color: #FFA800;
    border-color: transparent; }
.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-warning.dropdown-toggle i,
.show .btn.btn-transparent-warning.btn-dropdown i {
    color: white; }
.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-warning.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-warning {
    color: #FFA800;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-warning i {
    color: #FFA800; }
.btn.btn-hover-transparent-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-transparent-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-warning.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-hover-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-warning:focus:not(.btn-text), .btn.btn-hover-transparent-warning.focus:not(.btn-text) {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-warning:focus:not(.btn-text) i, .btn.btn-hover-transparent-warning.focus:not(.btn-text) i {
    color: #FFA800; }
.btn.btn-hover-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-hover-transparent-warning.disabled, .btn.btn-hover-transparent-warning:disabled {
    color: #FFA800;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-warning.disabled i, .btn.btn-hover-transparent-warning:disabled i {
    color: #FFA800; }
.btn.btn-hover-transparent-warning.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-transparent-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-warning.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle,
.show .btn.btn-hover-transparent-warning.btn-dropdown {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle i,
.show .btn.btn-hover-transparent-warning.btn-dropdown i {
    color: #FFA800; }
.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-warning.btn-dropdown.dropdown-toggle:after {
    color: #FFA800; }

.btn.btn-hover-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-warning:focus:not(.btn-text), .btn.btn-hover-light-warning.focus:not(.btn-text) {
    color: #FFA800;
    background-color: #FFF4DE;
    border-color: transparent; }
.btn.btn-hover-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-warning:focus:not(.btn-text) i, .btn.btn-hover-light-warning.focus:not(.btn-text) i {
    color: #FFA800; }
.btn.btn-hover-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: #FFA800; }

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-warning.dropdown-toggle,
.show .btn.btn-hover-light-warning.btn-dropdown {
    color: #FFA800;
    background-color: #FFF4DE;
    border-color: transparent; }
.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-warning.dropdown-toggle i,
.show .btn.btn-hover-light-warning.btn-dropdown i {
    color: #FFA800; }
.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-warning.btn-dropdown.dropdown-toggle:after {
    color: #FFA800; }

.btn.btn-hover-light-warning.btn-clean {
    border: 0 !important; }

.btn.btn-link-warning {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #FFA800;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-warning i {
    color: #FFA800; }
.btn.btn-link-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-link-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-warning.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-link-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-warning:focus:not(.btn-text), .btn.btn-link-warning.focus:not(.btn-text) {
    color: #EE9D01;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-warning:focus:not(.btn-text) i, .btn.btn-link-warning.focus:not(.btn-text) i {
    color: #EE9D01; }
.btn.btn-link-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #EE9D01; }
.btn.btn-link-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: #EE9D01; }
.btn.btn-link-warning.disabled, .btn.btn-link-warning:disabled {
    color: #FFA800;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-warning.disabled i, .btn.btn-link-warning:disabled i {
    color: #FFA800; }
.btn.btn-link-warning.disabled .svg-icon svg g [fill], .btn.btn-link-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.btn.btn-link-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-link-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-warning.disabled.dropdown-toggle:after, .btn.btn-link-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }
.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-warning.dropdown-toggle,
.show .btn.btn-link-warning.btn-dropdown {
    color: #EE9D01;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-warning.dropdown-toggle i,
.show .btn.btn-link-warning.btn-dropdown i {
    color: #EE9D01; }
.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #EE9D01; }
.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-warning.btn-dropdown.dropdown-toggle:after {
    color: #EE9D01; }

.btn.btn-hover-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-warning:focus:not(.btn-text), .btn.btn-hover-warning.focus:not(.btn-text) {
    color: white !important;
    background-color: #FFA800 !important;
    border-color: #FFA800 !important; }
.btn.btn-hover-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-warning:focus:not(.btn-text) i, .btn.btn-hover-warning.focus:not(.btn-text) i {
    color: white !important; }
.btn.btn-hover-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-warning.dropdown-toggle,
.show .btn.btn-hover-warning.btn-dropdown {
    color: white !important;
    background-color: #FFA800 !important;
    border-color: #FFA800 !important; }
.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-warning.dropdown-toggle i,
.show .btn.btn-hover-warning.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-warning.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-warning.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-warning:focus:not(.btn-text), .btn.btn-hover-bg-warning.focus:not(.btn-text) {
    background-color: #FFA800 !important;
    border-color: #FFA800 !important; }

.btn.btn-hover-bg-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-warning.dropdown-toggle,
.show .btn.btn-hover-bg-warning.btn-dropdown {
    background-color: #FFA800 !important;
    border-color: #FFA800 !important; }

.btn.btn-bg-warning {
    background-color: #FFA800;
    border-color: #FFA800; }
.btn.btn-bg-warning.disabled, .btn.btn-bg-warning:disabled {
    background-color: #FFA800;
    border-color: #FFA800; }

.btn.btn-warning.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.25) !important; }
.btn.btn-warning.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-warning.btn-shadow:focus:not(.btn-text), .btn.btn-warning.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important; }
.btn.btn-warning.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important; }

.btn.btn-warning.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-warning.btn-shadow-hover:focus:not(.btn-text), .btn.btn-warning.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important; }

.btn.btn-warning.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important; }

.btn.btn-danger {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn.btn-danger i {
    color: white; }
.btn.btn-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-danger.dropdown-toggle:after {
    color: white; }
.btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-danger:focus:not(.btn-text), .btn.btn-danger.focus:not(.btn-text) {
    color: white;
    background-color: #EE2D41;
    border-color: #EE2D41; }
.btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-danger:focus:not(.btn-text) i, .btn.btn-danger.focus:not(.btn-text) i {
    color: white; }
.btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-danger.disabled, .btn.btn-danger:disabled {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn.btn-danger.disabled i, .btn.btn-danger:disabled i {
    color: white; }
.btn.btn-danger.disabled .svg-icon svg g [fill], .btn.btn-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-danger.disabled.dropdown-toggle:after, .btn.btn-danger:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.dropdown-toggle,
.show .btn.btn-danger.btn-dropdown {
    color: white;
    background-color: #EE2D41;
    border-color: #EE2D41; }
.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-danger.dropdown-toggle i,
.show .btn.btn-danger.btn-dropdown i {
    color: white; }
.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-danger.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-danger {
    color: #F64E60;
    background-color: transparent;
    border-color: #F64E60; }
.btn.btn-outline-danger i {
    color: #F64E60; }
.btn.btn-outline-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-outline-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-danger.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-outline-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-danger:focus:not(.btn-text), .btn.btn-outline-danger.focus:not(.btn-text) {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn.btn-outline-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-danger:focus:not(.btn-text) i, .btn.btn-outline-danger.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-danger.disabled, .btn.btn-outline-danger:disabled {
    color: #F64E60;
    background-color: transparent;
    border-color: #F64E60; }
.btn.btn-outline-danger.disabled i, .btn.btn-outline-danger:disabled i {
    color: #F64E60; }
.btn.btn-outline-danger.disabled .svg-icon svg g [fill], .btn.btn-outline-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-outline-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-danger.disabled.dropdown-toggle:after, .btn.btn-outline-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-danger.dropdown-toggle,
.show .btn.btn-outline-danger.btn-dropdown {
    color: white;
    background-color: #F64E60;
    border-color: #F64E60; }
.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-danger.dropdown-toggle i,
.show .btn.btn-outline-danger.btn-dropdown i {
    color: white; }
.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-danger.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-danger {
    color: #F64E60;
    background-color: #FFE2E5;
    border-color: transparent; }
.btn.btn-light-danger i {
    color: #F64E60; }
.btn.btn-light-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-light-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-danger.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-danger:focus:not(.btn-text), .btn.btn-light-danger.focus:not(.btn-text) {
    color: white;
    background-color: #F64E60;
    border-color: transparent; }
.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-danger:focus:not(.btn-text) i, .btn.btn-light-danger.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-danger.disabled, .btn.btn-light-danger:disabled {
    color: #F64E60;
    background-color: #FFE2E5;
    border-color: transparent; }
.btn.btn-light-danger.disabled i, .btn.btn-light-danger:disabled i {
    color: #F64E60; }
.btn.btn-light-danger.disabled .svg-icon svg g [fill], .btn.btn-light-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-light-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-light-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-danger.disabled.dropdown-toggle:after, .btn.btn-light-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-danger.dropdown-toggle,
.show .btn.btn-light-danger.btn-dropdown {
    color: white;
    background-color: #F64E60;
    border-color: transparent; }
.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-danger.dropdown-toggle i,
.show .btn.btn-light-danger.btn-dropdown i {
    color: white; }
.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-danger.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-danger {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1);
    border-color: transparent; }
.btn.btn-transparent-danger i {
    color: #F64E60; }
.btn.btn-transparent-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-transparent-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-danger.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-danger:focus:not(.btn-text), .btn.btn-transparent-danger.focus:not(.btn-text) {
    color: white;
    background-color: #F64E60;
    border-color: transparent; }
.btn.btn-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-danger:focus:not(.btn-text) i, .btn.btn-transparent-danger.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-danger.disabled, .btn.btn-transparent-danger:disabled {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1);
    border-color: transparent; }
.btn.btn-transparent-danger.disabled i, .btn.btn-transparent-danger:disabled i {
    color: #F64E60; }
.btn.btn-transparent-danger.disabled .svg-icon svg g [fill], .btn.btn-transparent-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-transparent-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-danger.disabled.dropdown-toggle:after, .btn.btn-transparent-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-danger.dropdown-toggle,
.show .btn.btn-transparent-danger.btn-dropdown {
    color: white;
    background-color: #F64E60;
    border-color: transparent; }
.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-danger.dropdown-toggle i,
.show .btn.btn-transparent-danger.btn-dropdown i {
    color: white; }
.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-danger.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-danger {
    color: #F64E60;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-danger i {
    color: #F64E60; }
.btn.btn-hover-transparent-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-transparent-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-danger.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-hover-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-danger:focus:not(.btn-text), .btn.btn-hover-transparent-danger.focus:not(.btn-text) {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-danger:focus:not(.btn-text) i, .btn.btn-hover-transparent-danger.focus:not(.btn-text) i {
    color: #F64E60; }
.btn.btn-hover-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-hover-transparent-danger.disabled, .btn.btn-hover-transparent-danger:disabled {
    color: #F64E60;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-danger.disabled i, .btn.btn-hover-transparent-danger:disabled i {
    color: #F64E60; }
.btn.btn-hover-transparent-danger.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-transparent-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-danger.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle,
.show .btn.btn-hover-transparent-danger.btn-dropdown {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle i,
.show .btn.btn-hover-transparent-danger.btn-dropdown i {
    color: #F64E60; }
.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-danger.btn-dropdown.dropdown-toggle:after {
    color: #F64E60; }

.btn.btn-hover-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-danger:focus:not(.btn-text), .btn.btn-hover-light-danger.focus:not(.btn-text) {
    color: #F64E60;
    background-color: #FFE2E5;
    border-color: transparent; }
.btn.btn-hover-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-danger:focus:not(.btn-text) i, .btn.btn-hover-light-danger.focus:not(.btn-text) i {
    color: #F64E60; }
.btn.btn-hover-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: #F64E60; }

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-danger.dropdown-toggle,
.show .btn.btn-hover-light-danger.btn-dropdown {
    color: #F64E60;
    background-color: #FFE2E5;
    border-color: transparent; }
.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-danger.dropdown-toggle i,
.show .btn.btn-hover-light-danger.btn-dropdown i {
    color: #F64E60; }
.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-danger.btn-dropdown.dropdown-toggle:after {
    color: #F64E60; }

.btn.btn-hover-light-danger.btn-clean {
    border: 0 !important; }

.btn.btn-link-danger {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #F64E60;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-danger i {
    color: #F64E60; }
.btn.btn-link-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-link-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-danger.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-link-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-danger:focus:not(.btn-text), .btn.btn-link-danger.focus:not(.btn-text) {
    color: #EE2D41;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-danger:focus:not(.btn-text) i, .btn.btn-link-danger.focus:not(.btn-text) i {
    color: #EE2D41; }
.btn.btn-link-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #EE2D41; }
.btn.btn-link-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: #EE2D41; }
.btn.btn-link-danger.disabled, .btn.btn-link-danger:disabled {
    color: #F64E60;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-danger.disabled i, .btn.btn-link-danger:disabled i {
    color: #F64E60; }
.btn.btn-link-danger.disabled .svg-icon svg g [fill], .btn.btn-link-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.btn.btn-link-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-link-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-danger.disabled.dropdown-toggle:after, .btn.btn-link-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }
.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-danger.dropdown-toggle,
.show .btn.btn-link-danger.btn-dropdown {
    color: #EE2D41;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-danger.dropdown-toggle i,
.show .btn.btn-link-danger.btn-dropdown i {
    color: #EE2D41; }
.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #EE2D41; }
.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-danger.btn-dropdown.dropdown-toggle:after {
    color: #EE2D41; }

.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-danger:focus:not(.btn-text), .btn.btn-hover-danger.focus:not(.btn-text) {
    color: white !important;
    background-color: #F64E60 !important;
    border-color: #F64E60 !important; }
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-danger:focus:not(.btn-text) i, .btn.btn-hover-danger.focus:not(.btn-text) i {
    color: white !important; }
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-danger.dropdown-toggle,
.show .btn.btn-hover-danger.btn-dropdown {
    color: white !important;
    background-color: #F64E60 !important;
    border-color: #F64E60 !important; }
.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-danger.dropdown-toggle i,
.show .btn.btn-hover-danger.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-danger.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-danger.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-danger:focus:not(.btn-text), .btn.btn-hover-bg-danger.focus:not(.btn-text) {
    background-color: #F64E60 !important;
    border-color: #F64E60 !important; }

.btn.btn-hover-bg-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-danger.dropdown-toggle,
.show .btn.btn-hover-bg-danger.btn-dropdown {
    background-color: #F64E60 !important;
    border-color: #F64E60 !important; }

.btn.btn-bg-danger {
    background-color: #F64E60;
    border-color: #F64E60; }
.btn.btn-bg-danger.disabled, .btn.btn-bg-danger:disabled {
    background-color: #F64E60;
    border-color: #F64E60; }

.btn.btn-danger.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.25) !important; }
.btn.btn-danger.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-danger.btn-shadow:focus:not(.btn-text), .btn.btn-danger.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important; }
.btn.btn-danger.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important; }

.btn.btn-danger.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-danger.btn-shadow-hover:focus:not(.btn-text), .btn.btn-danger.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important; }

.btn.btn-danger.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important; }

.btn.btn-light {
    color: #7E8299;
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.btn.btn-light i {
    color: #7E8299; }
.btn.btn-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light.dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light:focus:not(.btn-text), .btn.btn-light.focus:not(.btn-text) {
    color: #7E8299;
    /*background-color: #E4E6EF;*/
    background-color: rgba(214, 192,154, .25);
    border-color: rgba(214, 192,154, .25); }
.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light:focus:not(.btn-text) i, .btn.btn-light.focus:not(.btn-text) i {
    color: #7E8299; }
.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-light.disabled, .btn.btn-light:disabled {
    color: #7E8299;
    /*background-color: #F3F6F9;*/
    /*border-color: #F3F6F9; }*/
    background-color: rgba(214, 192,154, .1);
    border-color: rgba(214, 192,154, .1); }
.btn.btn-light.disabled i, .btn.btn-light:disabled i {
    color: #7E8299; }
.btn.btn-light.disabled .svg-icon svg g [fill], .btn.btn-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light.disabled .svg-icon svg:hover g [fill], .btn.btn-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light.disabled.dropdown-toggle:after, .btn.btn-light:disabled.dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.dropdown-toggle,
.show .btn.btn-light.btn-dropdown {
    color: #7E8299;
    /*background-color: #E4E6EF;*/
    /*border-color: #E4E6EF; }*/
    background-color: rgba(214, 192,154, .4);
    border-color: rgba(214, 192,154, .4); }
.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light.dropdown-toggle i,
.show .btn.btn-light.btn-dropdown i {
    color: #7E8299; }
.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light.btn-dropdown.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-outline-light {
    color: #3F4254;
    background-color: transparent;
    border-color: #F3F6F9; }
.btn.btn-outline-light i {
    color: #3F4254; }
.btn.btn-outline-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-light.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-light:focus:not(.btn-text), .btn.btn-outline-light.focus:not(.btn-text) {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-light:focus:not(.btn-text) i, .btn.btn-outline-light.focus:not(.btn-text) i {
    color: #7E8299; }
.btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-outline-light.disabled, .btn.btn-outline-light:disabled {
    color: #3F4254;
    background-color: transparent;
    border-color: #F3F6F9; }
.btn.btn-outline-light.disabled i, .btn.btn-outline-light:disabled i {
    color: #3F4254; }
.btn.btn-outline-light.disabled .svg-icon svg g [fill], .btn.btn-outline-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-light.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-light.disabled.dropdown-toggle:after, .btn.btn-outline-light:disabled.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-light.dropdown-toggle,
.show .btn.btn-outline-light.btn-dropdown {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-light.dropdown-toggle i,
.show .btn.btn-outline-light.btn-dropdown i {
    color: #7E8299; }
.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-light.btn-dropdown.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-light-light {
    color: #F3F6F9;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-light-light i {
    color: #F3F6F9; }
.btn.btn-light-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-light-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-light.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-light-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-light:focus:not(.btn-text), .btn.btn-light-light.focus:not(.btn-text) {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-light:focus:not(.btn-text) i, .btn.btn-light-light.focus:not(.btn-text) i {
    color: #7E8299; }
.btn.btn-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-light-light.disabled, .btn.btn-light-light:disabled {
    color: #F3F6F9;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-light-light.disabled i, .btn.btn-light-light:disabled i {
    color: #F3F6F9; }
.btn.btn-light-light.disabled .svg-icon svg g [fill], .btn.btn-light-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-light-light.disabled .svg-icon svg:hover g [fill], .btn.btn-light-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-light.disabled.dropdown-toggle:after, .btn.btn-light-light:disabled.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-light.dropdown-toggle,
.show .btn.btn-light-light.btn-dropdown {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-light.dropdown-toggle i,
.show .btn.btn-light-light.btn-dropdown i {
    color: #7E8299; }
.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-light.btn-dropdown.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-transparent-light {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1);
    border-color: transparent; }
.btn.btn-transparent-light i {
    color: #F3F6F9; }
.btn.btn-transparent-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-transparent-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-light.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-light:focus:not(.btn-text), .btn.btn-transparent-light.focus:not(.btn-text) {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-light:focus:not(.btn-text) i, .btn.btn-transparent-light.focus:not(.btn-text) i {
    color: #7E8299; }
.btn.btn-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299; }
.btn.btn-transparent-light.disabled, .btn.btn-transparent-light:disabled {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1);
    border-color: transparent; }
.btn.btn-transparent-light.disabled i, .btn.btn-transparent-light:disabled i {
    color: #F3F6F9; }
.btn.btn-transparent-light.disabled .svg-icon svg g [fill], .btn.btn-transparent-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-transparent-light.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-light.disabled.dropdown-toggle:after, .btn.btn-transparent-light:disabled.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-light.dropdown-toggle,
.show .btn.btn-transparent-light.btn-dropdown {
    color: #7E8299;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-light.dropdown-toggle i,
.show .btn.btn-transparent-light.btn-dropdown i {
    color: #7E8299; }
.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-light.btn-dropdown.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-hover-transparent-light {
    color: #F3F6F9;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-light i {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-transparent-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-light.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-light:focus:not(.btn-text), .btn.btn-hover-transparent-light.focus:not(.btn-text) {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-light:focus:not(.btn-text) i, .btn.btn-hover-transparent-light.focus:not(.btn-text) i {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light.disabled, .btn.btn-hover-transparent-light:disabled {
    color: #F3F6F9;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-light.disabled i, .btn.btn-hover-transparent-light:disabled i {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-transparent-light.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-light.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-light:disabled.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-light.dropdown-toggle,
.show .btn.btn-hover-transparent-light.btn-dropdown {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-light.dropdown-toggle i,
.show .btn.btn-hover-transparent-light.btn-dropdown i {
    color: #F3F6F9; }
.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-light.btn-dropdown.dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-hover-light-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-light:focus:not(.btn-text), .btn.btn-hover-light-light.focus:not(.btn-text) {
    color: #F3F6F9;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-hover-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-light:focus:not(.btn-text) i, .btn.btn-hover-light-light.focus:not(.btn-text) i {
    color: #F3F6F9; }
.btn.btn-hover-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-light-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-light.dropdown-toggle,
.show .btn.btn-hover-light-light.btn-dropdown {
    color: #F3F6F9;
    background-color: #F3F6F9;
    border-color: transparent; }
.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-light.dropdown-toggle i,
.show .btn.btn-hover-light-light.btn-dropdown i {
    color: #F3F6F9; }
.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-light.btn-dropdown.dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-hover-light-light.btn-clean {
    border: 0 !important; }

.btn.btn-link-light {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #F3F6F9;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-light i {
    color: #F3F6F9; }
.btn.btn-link-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-link-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-light.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-link-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-light:focus:not(.btn-text), .btn.btn-link-light.focus:not(.btn-text) {
    color: #E4E6EF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-light:focus:not(.btn-text) i, .btn.btn-link-light.focus:not(.btn-text) i {
    color: #E4E6EF; }
.btn.btn-link-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-link-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #E4E6EF; }
.btn.btn-link-light.disabled, .btn.btn-link-light:disabled {
    color: #F3F6F9;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-light.disabled i, .btn.btn-link-light:disabled i {
    color: #F3F6F9; }
.btn.btn-link-light.disabled .svg-icon svg g [fill], .btn.btn-link-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-link-light.disabled .svg-icon svg:hover g [fill], .btn.btn-link-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-light.disabled.dropdown-toggle:after, .btn.btn-link-light:disabled.dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-light.dropdown-toggle,
.show .btn.btn-link-light.btn-dropdown {
    color: #E4E6EF;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-light.dropdown-toggle i,
.show .btn.btn-link-light.btn-dropdown i {
    color: #E4E6EF; }
.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-light.btn-dropdown.dropdown-toggle:after {
    color: #E4E6EF; }

.btn.btn-hover-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light:focus:not(.btn-text), .btn.btn-hover-light.focus:not(.btn-text) {
    color: #7E8299 !important;
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important; }
.btn.btn-hover-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light:focus:not(.btn-text) i, .btn.btn-hover-light.focus:not(.btn-text) i {
    color: #7E8299 !important; }
.btn.btn-hover-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }
.btn.btn-hover-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299 !important; }

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light.dropdown-toggle,
.show .btn.btn-hover-light.btn-dropdown {
    color: #7E8299 !important;
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important; }
.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light.dropdown-toggle i,
.show .btn.btn-hover-light.btn-dropdown i {
    color: #7E8299 !important; }
.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }
.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light.btn-dropdown.dropdown-toggle:after {
    color: #7E8299 !important; }

.btn.btn-hover-light.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-light:focus:not(.btn-text), .btn.btn-hover-bg-light.focus:not(.btn-text) {
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important; }

.btn.btn-hover-bg-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-light.dropdown-toggle,
.show .btn.btn-hover-bg-light.btn-dropdown {
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important; }

.btn.btn-bg-light {
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-bg-light.disabled, .btn.btn-bg-light:disabled {
    background-color: #F3F6F9;
    border-color: #F3F6F9; }

.btn.btn-light.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important; }
.btn.btn-light.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light.btn-shadow:focus:not(.btn-text), .btn.btn-light.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }
.btn.btn-light.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-light.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light.btn-shadow-hover:focus:not(.btn-text), .btn.btn-light.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-light.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-dark {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn.btn-dark i {
    color: white; }
.btn.btn-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-dark.dropdown-toggle:after {
    color: white; }
.btn.btn-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-dark:focus:not(.btn-text), .btn.btn-dark.focus:not(.btn-text) {
    color: white;
    background-color: #131628;
    border-color: #131628; }
.btn.btn-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-dark:focus:not(.btn-text) i, .btn.btn-dark.focus:not(.btn-text) i {
    color: white; }
.btn.btn-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-dark.disabled, .btn.btn-dark:disabled {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn.btn-dark.disabled i, .btn.btn-dark:disabled i {
    color: white; }
.btn.btn-dark.disabled .svg-icon svg g [fill], .btn.btn-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-dark.disabled.dropdown-toggle:after, .btn.btn-dark:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.dropdown-toggle,
.show .btn.btn-dark.btn-dropdown {
    color: white;
    background-color: #131628;
    border-color: #131628; }
.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-dark.dropdown-toggle i,
.show .btn.btn-dark.btn-dropdown i {
    color: white; }
.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-dark.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-outline-dark {
    color: #181C32;
    background-color: transparent;
    border-color: #181C32; }
.btn.btn-outline-dark i {
    color: #181C32; }
.btn.btn-outline-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-outline-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-dark.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-dark:focus:not(.btn-text), .btn.btn-outline-dark.focus:not(.btn-text) {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-dark:focus:not(.btn-text) i, .btn.btn-outline-dark.focus:not(.btn-text) i {
    color: white; }
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-outline-dark.disabled, .btn.btn-outline-dark:disabled {
    color: #181C32;
    background-color: transparent;
    border-color: #181C32; }
.btn.btn-outline-dark.disabled i, .btn.btn-outline-dark:disabled i {
    color: #181C32; }
.btn.btn-outline-dark.disabled .svg-icon svg g [fill], .btn.btn-outline-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-outline-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-dark.disabled.dropdown-toggle:after, .btn.btn-outline-dark:disabled.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-dark.dropdown-toggle,
.show .btn.btn-outline-dark.btn-dropdown {
    color: white;
    background-color: #181C32;
    border-color: #181C32; }
.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-dark.dropdown-toggle i,
.show .btn.btn-outline-dark.btn-dropdown i {
    color: white; }
.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-dark.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-dark {
    color: #181C32;
    background-color: #D1D3E0;
    border-color: transparent; }
.btn.btn-light-dark i {
    color: #181C32; }
.btn.btn-light-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-light-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-dark.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-dark:focus:not(.btn-text), .btn.btn-light-dark.focus:not(.btn-text) {
    color: white;
    background-color: #181C32;
    border-color: transparent; }
.btn.btn-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-dark:focus:not(.btn-text) i, .btn.btn-light-dark.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-dark.disabled, .btn.btn-light-dark:disabled {
    color: #181C32;
    background-color: #D1D3E0;
    border-color: transparent; }
.btn.btn-light-dark.disabled i, .btn.btn-light-dark:disabled i {
    color: #181C32; }
.btn.btn-light-dark.disabled .svg-icon svg g [fill], .btn.btn-light-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-light-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-light-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-dark.disabled.dropdown-toggle:after, .btn.btn-light-dark:disabled.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-dark.dropdown-toggle,
.show .btn.btn-light-dark.btn-dropdown {
    color: white;
    background-color: #181C32;
    border-color: transparent; }
.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-dark.dropdown-toggle i,
.show .btn.btn-light-dark.btn-dropdown i {
    color: white; }
.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-dark.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-transparent-dark {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1);
    border-color: transparent; }
.btn.btn-transparent-dark i {
    color: #181C32; }
.btn.btn-transparent-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-transparent-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-dark.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-dark:focus:not(.btn-text), .btn.btn-transparent-dark.focus:not(.btn-text) {
    color: white;
    background-color: #181C32;
    border-color: transparent; }
.btn.btn-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-dark:focus:not(.btn-text) i, .btn.btn-transparent-dark.focus:not(.btn-text) i {
    color: white; }
.btn.btn-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-dark.disabled, .btn.btn-transparent-dark:disabled {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1);
    border-color: transparent; }
.btn.btn-transparent-dark.disabled i, .btn.btn-transparent-dark:disabled i {
    color: #181C32; }
.btn.btn-transparent-dark.disabled .svg-icon svg g [fill], .btn.btn-transparent-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-transparent-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-dark.disabled.dropdown-toggle:after, .btn.btn-transparent-dark:disabled.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-dark.dropdown-toggle,
.show .btn.btn-transparent-dark.btn-dropdown {
    color: white;
    background-color: #181C32;
    border-color: transparent; }
.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-dark.dropdown-toggle i,
.show .btn.btn-transparent-dark.btn-dropdown i {
    color: white; }
.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-dark.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-transparent-dark {
    color: #181C32;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-dark i {
    color: #181C32; }
.btn.btn-hover-transparent-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-transparent-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-dark.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-hover-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-dark:focus:not(.btn-text), .btn.btn-hover-transparent-dark.focus:not(.btn-text) {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-dark:focus:not(.btn-text) i, .btn.btn-hover-transparent-dark.focus:not(.btn-text) i {
    color: #181C32; }
.btn.btn-hover-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: #181C32; }
.btn.btn-hover-transparent-dark.disabled, .btn.btn-hover-transparent-dark:disabled {
    color: #181C32;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-dark.disabled i, .btn.btn-hover-transparent-dark:disabled i {
    color: #181C32; }
.btn.btn-hover-transparent-dark.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-transparent-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-dark.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-dark:disabled.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle,
.show .btn.btn-hover-transparent-dark.btn-dropdown {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle i,
.show .btn.btn-hover-transparent-dark.btn-dropdown i {
    color: #181C32; }
.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-dark.btn-dropdown.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-hover-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-dark:focus:not(.btn-text), .btn.btn-hover-light-dark.focus:not(.btn-text) {
    color: #181C32;
    background-color: #D1D3E0;
    border-color: transparent; }
.btn.btn-hover-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-dark:focus:not(.btn-text) i, .btn.btn-hover-light-dark.focus:not(.btn-text) i {
    color: #181C32; }
.btn.btn-hover-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: #181C32; }

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-dark.dropdown-toggle,
.show .btn.btn-hover-light-dark.btn-dropdown {
    color: #181C32;
    background-color: #D1D3E0;
    border-color: transparent; }
.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-dark.dropdown-toggle i,
.show .btn.btn-hover-light-dark.btn-dropdown i {
    color: #181C32; }
.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-dark.btn-dropdown.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-hover-light-dark.btn-clean {
    border: 0 !important; }

.btn.btn-link-dark {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #181C32;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-dark i {
    color: #181C32; }
.btn.btn-link-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-link-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-dark.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-link-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-dark:focus:not(.btn-text), .btn.btn-link-dark.focus:not(.btn-text) {
    color: #131628;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-dark:focus:not(.btn-text) i, .btn.btn-link-dark.focus:not(.btn-text) i {
    color: #131628; }
.btn.btn-link-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #131628; }
.btn.btn-link-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: #131628; }
.btn.btn-link-dark.disabled, .btn.btn-link-dark:disabled {
    color: #181C32;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-dark.disabled i, .btn.btn-link-dark:disabled i {
    color: #181C32; }
.btn.btn-link-dark.disabled .svg-icon svg g [fill], .btn.btn-link-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.btn.btn-link-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-link-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-dark.disabled.dropdown-toggle:after, .btn.btn-link-dark:disabled.dropdown-toggle:after {
    color: #181C32; }
.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-dark.dropdown-toggle,
.show .btn.btn-link-dark.btn-dropdown {
    color: #131628;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-dark.dropdown-toggle i,
.show .btn.btn-link-dark.btn-dropdown i {
    color: #131628; }
.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #131628; }
.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-dark.btn-dropdown.dropdown-toggle:after {
    color: #131628; }

.btn.btn-hover-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-dark:focus:not(.btn-text), .btn.btn-hover-dark.focus:not(.btn-text) {
    color: white !important;
    background-color: #181C32 !important;
    border-color: #181C32 !important; }
.btn.btn-hover-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-dark:focus:not(.btn-text) i, .btn.btn-hover-dark.focus:not(.btn-text) i {
    color: white !important; }
.btn.btn-hover-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-dark.dropdown-toggle,
.show .btn.btn-hover-dark.btn-dropdown {
    color: white !important;
    background-color: #181C32 !important;
    border-color: #181C32 !important; }
.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-dark.dropdown-toggle i,
.show .btn.btn-hover-dark.btn-dropdown i {
    color: white !important; }
.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-dark.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-dark.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-dark:focus:not(.btn-text), .btn.btn-hover-bg-dark.focus:not(.btn-text) {
    background-color: #181C32 !important;
    border-color: #181C32 !important; }

.btn.btn-hover-bg-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-dark.dropdown-toggle,
.show .btn.btn-hover-bg-dark.btn-dropdown {
    background-color: #181C32 !important;
    border-color: #181C32 !important; }

.btn.btn-bg-dark {
    background-color: #181C32;
    border-color: #181C32; }
.btn.btn-bg-dark.disabled, .btn.btn-bg-dark:disabled {
    background-color: #181C32;
    border-color: #181C32; }

.btn.btn-dark.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important; }
.btn.btn-dark.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-dark.btn-shadow:focus:not(.btn-text), .btn.btn-dark.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }
.btn.btn-dark.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-dark.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-dark.btn-shadow-hover:focus:not(.btn-text), .btn.btn-dark.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-dark.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-white {
    color: #3F4254;
    background-color: white;
    border-color: white; }
.btn.btn-white i {
    color: #3F4254; }
.btn.btn-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-white.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-white:focus:not(.btn-text), .btn.btn-white.focus:not(.btn-text) {
    color: #3F4254;
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-white:focus:not(.btn-text) i, .btn.btn-white.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-white.disabled, .btn.btn-white:disabled {
    color: #3F4254;
    background-color: white;
    border-color: white; }
.btn.btn-white.disabled i, .btn.btn-white:disabled i {
    color: #3F4254; }
.btn.btn-white.disabled .svg-icon svg g [fill], .btn.btn-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-white.disabled .svg-icon svg:hover g [fill], .btn.btn-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-white.disabled.dropdown-toggle:after, .btn.btn-white:disabled.dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.dropdown-toggle,
.show .btn.btn-white.btn-dropdown {
    color: #3F4254;
    background-color: #F3F6F9;
    border-color: #F3F6F9; }
.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-white.dropdown-toggle i,
.show .btn.btn-white.btn-dropdown i {
    color: #3F4254; }
.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-white.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-outline-white {
    color: white;
    background-color: transparent;
    border-color: white; }
.btn.btn-outline-white i {
    color: white; }
.btn.btn-outline-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-white.dropdown-toggle:after {
    color: white; }
.btn.btn-outline-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-outline-white:focus:not(.btn-text), .btn.btn-outline-white.focus:not(.btn-text) {
    color: #3F4254;
    background-color: white;
    border-color: white; }
.btn.btn-outline-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-outline-white:focus:not(.btn-text) i, .btn.btn-outline-white.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-outline-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-outline-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-outline-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-outline-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-outline-white.disabled, .btn.btn-outline-white:disabled {
    color: white;
    background-color: transparent;
    border-color: white; }
.btn.btn-outline-white.disabled i, .btn.btn-outline-white:disabled i {
    color: white; }
.btn.btn-outline-white.disabled .svg-icon svg g [fill], .btn.btn-outline-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-outline-white.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-white.disabled.dropdown-toggle:after, .btn.btn-outline-white:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-white.dropdown-toggle,
.show .btn.btn-outline-white.btn-dropdown {
    color: #3F4254;
    background-color: white;
    border-color: white; }
.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-white.dropdown-toggle i,
.show .btn.btn-outline-white.btn-dropdown i {
    color: #3F4254; }
.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-white.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-light-white {
    color: white;
    background-color: white;
    border-color: transparent; }
.btn.btn-light-white i {
    color: white; }
.btn.btn-light-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-white.dropdown-toggle:after {
    color: white; }
.btn.btn-light-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-white:focus:not(.btn-text), .btn.btn-light-white.focus:not(.btn-text) {
    color: #3F4254;
    background-color: white;
    border-color: transparent; }
.btn.btn-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-white:focus:not(.btn-text) i, .btn.btn-light-white.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-light-white.disabled, .btn.btn-light-white:disabled {
    color: white;
    background-color: white;
    border-color: transparent; }
.btn.btn-light-white.disabled i, .btn.btn-light-white:disabled i {
    color: white; }
.btn.btn-light-white.disabled .svg-icon svg g [fill], .btn.btn-light-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-white.disabled .svg-icon svg:hover g [fill], .btn.btn-light-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-white.disabled.dropdown-toggle:after, .btn.btn-light-white:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-white.dropdown-toggle,
.show .btn.btn-light-white.btn-dropdown {
    color: #3F4254;
    background-color: white;
    border-color: transparent; }
.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-white.dropdown-toggle i,
.show .btn.btn-light-white.btn-dropdown i {
    color: #3F4254; }
.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-white.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-transparent-white {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-white i {
    color: white; }
.btn.btn-transparent-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-white.dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-transparent-white:focus:not(.btn-text), .btn.btn-transparent-white.focus:not(.btn-text) {
    color: #3F4254;
    background-color: white;
    border-color: transparent; }
.btn.btn-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-transparent-white:focus:not(.btn-text) i, .btn.btn-transparent-white.focus:not(.btn-text) i {
    color: #3F4254; }
.btn.btn-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-transparent-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-transparent-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-transparent-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254; }
.btn.btn-transparent-white.disabled, .btn.btn-transparent-white:disabled {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent; }
.btn.btn-transparent-white.disabled i, .btn.btn-transparent-white:disabled i {
    color: white; }
.btn.btn-transparent-white.disabled .svg-icon svg g [fill], .btn.btn-transparent-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-transparent-white.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-white.disabled.dropdown-toggle:after, .btn.btn-transparent-white:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-white.dropdown-toggle,
.show .btn.btn-transparent-white.btn-dropdown {
    color: #3F4254;
    background-color: white;
    border-color: transparent; }
.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-white.dropdown-toggle i,
.show .btn.btn-transparent-white.btn-dropdown i {
    color: #3F4254; }
.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-white.btn-dropdown.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-hover-transparent-white {
    color: white;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-white i {
    color: white; }
.btn.btn-hover-transparent-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-transparent-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-white.dropdown-toggle:after {
    color: white; }
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-transparent-white:focus:not(.btn-text), .btn.btn-hover-transparent-white.focus:not(.btn-text) {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-transparent-white:focus:not(.btn-text) i, .btn.btn-hover-transparent-white.focus:not(.btn-text) i {
    color: white; }
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-transparent-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-transparent-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-white.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-hover-transparent-white.disabled, .btn.btn-hover-transparent-white:disabled {
    color: white;
    background-color: transparent;
    border-color: transparent; }
.btn.btn-hover-transparent-white.disabled i, .btn.btn-hover-transparent-white:disabled i {
    color: white; }
.btn.btn-hover-transparent-white.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-transparent-white.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-white.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-white:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-white.dropdown-toggle,
.show .btn.btn-hover-transparent-white.btn-dropdown {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent; }
.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-white.dropdown-toggle i,
.show .btn.btn-hover-transparent-white.btn-dropdown i {
    color: white; }
.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-white.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-light-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-light-white:focus:not(.btn-text), .btn.btn-hover-light-white.focus:not(.btn-text) {
    color: white;
    background-color: white;
    border-color: transparent; }
.btn.btn-hover-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-light-white:focus:not(.btn-text) i, .btn.btn-hover-light-white.focus:not(.btn-text) i {
    color: white; }
.btn.btn-hover-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-light-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-light-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-light-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-white.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-white.dropdown-toggle,
.show .btn.btn-hover-light-white.btn-dropdown {
    color: white;
    background-color: white;
    border-color: transparent; }
.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-white.dropdown-toggle i,
.show .btn.btn-hover-light-white.btn-dropdown i {
    color: white; }
.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-white.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-light-white.btn-clean {
    border: 0 !important; }

.btn.btn-link-white {
    border: 0;
    padding: 0 !important;
    border-radius: 0 !important;
    color: white;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-white i {
    color: white; }
.btn.btn-link-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-link-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-white.dropdown-toggle:after {
    color: white; }
.btn.btn-link-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-link-white:focus:not(.btn-text), .btn.btn-link-white.focus:not(.btn-text) {
    color: #F3F6F9;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-link-white:focus:not(.btn-text) i, .btn.btn-link-white.focus:not(.btn-text) i {
    color: #F3F6F9; }
.btn.btn-link-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-link-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-link-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-link-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-link-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #F3F6F9; }
.btn.btn-link-white.disabled, .btn.btn-link-white:disabled {
    color: white;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-white.disabled i, .btn.btn-link-white:disabled i {
    color: white; }
.btn.btn-link-white.disabled .svg-icon svg g [fill], .btn.btn-link-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-link-white.disabled .svg-icon svg:hover g [fill], .btn.btn-link-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-white.disabled.dropdown-toggle:after, .btn.btn-link-white:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-white.dropdown-toggle,
.show .btn.btn-link-white.btn-dropdown {
    color: #F3F6F9;
    background-color: transparent !important;
    border-color: transparent !important; }
.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-white.dropdown-toggle i,
.show .btn.btn-link-white.btn-dropdown i {
    color: #F3F6F9; }
.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-white.btn-dropdown.dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-hover-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-white:focus:not(.btn-text), .btn.btn-hover-white.focus:not(.btn-text) {
    color: #3F4254 !important;
    background-color: white !important;
    border-color: white !important; }
.btn.btn-hover-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-white:focus:not(.btn-text) i, .btn.btn-hover-white.focus:not(.btn-text) i {
    color: #3F4254 !important; }
.btn.btn-hover-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.btn.btn-hover-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-white.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-white.dropdown-toggle,
.show .btn.btn-hover-white.btn-dropdown {
    color: #3F4254 !important;
    background-color: white !important;
    border-color: white !important; }
.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-white.dropdown-toggle i,
.show .btn.btn-hover-white.btn-dropdown i {
    color: #3F4254 !important; }
.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-white.btn-dropdown.dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-hover-white.btn-clean {
    border: 0 !important; }

.btn.btn-hover-bg-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-bg-white:focus:not(.btn-text), .btn.btn-hover-bg-white.focus:not(.btn-text) {
    background-color: white !important;
    border-color: white !important; }

.btn.btn-hover-bg-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-white.dropdown-toggle,
.show .btn.btn-hover-bg-white.btn-dropdown {
    background-color: white !important;
    border-color: white !important; }

.btn.btn-bg-white {
    background-color: white;
    border-color: white; }
.btn.btn-bg-white.disabled, .btn.btn-bg-white:disabled {
    background-color: white;
    border-color: white; }

.btn.btn-white.btn-shadow {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important; }
.btn.btn-white.btn-shadow:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-white.btn-shadow:focus:not(.btn-text), .btn.btn-white.btn-shadow.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }
.btn.btn-white.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.btn-shadow.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-white.btn-shadow-hover:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-white.btn-shadow-hover:focus:not(.btn-text), .btn.btn-white.btn-shadow-hover.focus:not(.btn-text) {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.btn-shadow-hover.dropdown-toggle {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important;
    box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.3) !important; }

.btn.btn-text-white {
    color: white; }
.btn.btn-text-white.disabled, .btn.btn-text-white:disabled {
    color: white; }

.btn.btn-icon-white i {
    color: white; }

.btn.btn-icon-white .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }

.btn.btn-icon-white .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-white.dropdown-toggle:after {
    color: white; }

.btn.btn-icon-white.disabled i, .btn.btn-icon-white:disabled i {
    color: white; }

.btn.btn-icon-white.disabled .svg-icon svg g [fill], .btn.btn-icon-white:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }

.btn.btn-icon-white.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-white:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-white.disabled.dropdown-toggle:after, .btn.btn-icon-white:disabled.dropdown-toggle:after {
    color: white; }

.btn.btn-hover-text-white:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-white:focus:not(.btn-text), .btn.btn-hover-text-white.focus:not(.btn-text) {
    color: white !important; }

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-white.dropdown-toggle,
.show .btn.btn-hover-text-white.btn-dropdown {
    color: white !important; }

.btn.btn-hover-icon-white:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-white:focus:not(.btn-text) i, .btn.btn-hover-icon-white.focus:not(.btn-text) i {
    color: white !important; }

.btn.btn-hover-icon-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-white.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }

.btn.btn-hover-icon-white:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-white:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-white.focus:not(.btn-text).dropdown-toggle:after {
    color: white !important; }

.btn.btn-hover-icon-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-white.dropdown-toggle i,
.show .btn.btn-hover-icon-white.btn-dropdown i {
    color: white !important; }

.btn.btn-hover-icon-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-white.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }

.btn.btn-hover-icon-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-white.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-white.btn-dropdown.dropdown-toggle:after {
    color: white !important; }

.btn.btn-text-primary {
    color: var(--success); }
.btn.btn-text-primary.disabled, .btn.btn-text-primary:disabled {
    color: var(--success); }

.btn.btn-icon-primary i {
    color: var(--success); }

.btn.btn-icon-primary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }

.btn.btn-icon-primary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-primary.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-icon-primary.disabled i, .btn.btn-icon-primary:disabled i {
    color: var(--success); }

.btn.btn-icon-primary.disabled .svg-icon svg g [fill], .btn.btn-icon-primary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }

.btn.btn-icon-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-primary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-primary.disabled.dropdown-toggle:after, .btn.btn-icon-primary:disabled.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-text-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-primary:focus:not(.btn-text), .btn.btn-hover-text-primary.focus:not(.btn-text) {
    color: var(--success) !important; }

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-primary.dropdown-toggle,
.show .btn.btn-hover-text-primary.btn-dropdown {
    color: var(--success) !important; }

.btn.btn-hover-icon-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-primary:focus:not(.btn-text) i, .btn.btn-hover-icon-primary.focus:not(.btn-text) i {
    color: var(--success) !important; }

.btn.btn-hover-icon-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.btn.btn-hover-icon-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-primary.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success) !important; }

.btn.btn-hover-icon-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-primary.dropdown-toggle i,
.show .btn.btn-hover-icon-primary.btn-dropdown i {
    color: var(--success) !important; }

.btn.btn-hover-icon-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-primary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.btn.btn-hover-icon-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-primary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-primary.btn-dropdown.dropdown-toggle:after {
    color: var(--success) !important; }

.btn.btn-text-secondary {
    color: #181C32; }
.btn.btn-text-secondary.disabled, .btn.btn-text-secondary:disabled {
    color: #181C32; }

.btn.btn-icon-secondary i {
    color: #181C32; }

.btn.btn-icon-secondary .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }

.btn.btn-icon-secondary .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-secondary.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-icon-secondary.disabled i, .btn.btn-icon-secondary:disabled i {
    color: #181C32; }

.btn.btn-icon-secondary.disabled .svg-icon svg g [fill], .btn.btn-icon-secondary:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }

.btn.btn-icon-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-secondary:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-secondary.disabled.dropdown-toggle:after, .btn.btn-icon-secondary:disabled.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-hover-text-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-secondary:focus:not(.btn-text), .btn.btn-hover-text-secondary.focus:not(.btn-text) {
    color: #181C32 !important; }

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-secondary.dropdown-toggle,
.show .btn.btn-hover-text-secondary.btn-dropdown {
    color: #181C32 !important; }

.btn.btn-hover-icon-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-secondary:focus:not(.btn-text) i, .btn.btn-hover-icon-secondary.focus:not(.btn-text) i {
    color: #181C32 !important; }

.btn.btn-hover-icon-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.btn.btn-hover-icon-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-secondary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-secondary.focus:not(.btn-text).dropdown-toggle:after {
    color: #181C32 !important; }

.btn.btn-hover-icon-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-secondary.dropdown-toggle i,
.show .btn.btn-hover-icon-secondary.btn-dropdown i {
    color: #181C32 !important; }

.btn.btn-hover-icon-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-secondary.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.btn.btn-hover-icon-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-secondary.btn-dropdown.dropdown-toggle:after {
    color: #181C32 !important; }

.btn.btn-text-success {
    color: var(--success); }
.btn.btn-text-success.disabled, .btn.btn-text-success:disabled {
    color: var(--success); }

.btn.btn-icon-success i {
    color: var(--success); }

.btn.btn-icon-success .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }

.btn.btn-icon-success .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-success.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-icon-success.disabled i, .btn.btn-icon-success:disabled i {
    color: var(--success); }

.btn.btn-icon-success.disabled .svg-icon svg g [fill], .btn.btn-icon-success:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }

.btn.btn-icon-success.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-success:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-success.disabled.dropdown-toggle:after, .btn.btn-icon-success:disabled.dropdown-toggle:after {
    color: var(--success); }

.btn.btn-hover-text-success:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-success:focus:not(.btn-text), .btn.btn-hover-text-success.focus:not(.btn-text) {
    color: var(--success) !important; }

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-success.dropdown-toggle,
.show .btn.btn-hover-text-success.btn-dropdown {
    color: var(--success) !important; }

.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-success:focus:not(.btn-text) i, .btn.btn-hover-icon-success.focus:not(.btn-text) i {
    color: var(--success) !important; }

.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-success.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-success:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-success.focus:not(.btn-text).dropdown-toggle:after {
    color: var(--success) !important; }

.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-success.dropdown-toggle i,
.show .btn.btn-hover-icon-success.btn-dropdown i {
    color: var(--success) !important; }

.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-success.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success) !important; }

.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-success.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-success.btn-dropdown.dropdown-toggle:after {
    color: var(--success) !important; }

.btn.btn-text-info {
    color: #6993FF; }
.btn.btn-text-info.disabled, .btn.btn-text-info:disabled {
    color: #6993FF; }

.btn.btn-icon-info i {
    color: #6993FF; }

.btn.btn-icon-info .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }

.btn.btn-icon-info .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-info.dropdown-toggle:after {
    color: #6993FF; }

.btn.btn-icon-info.disabled i, .btn.btn-icon-info:disabled i {
    color: #6993FF; }

.btn.btn-icon-info.disabled .svg-icon svg g [fill], .btn.btn-icon-info:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }

.btn.btn-icon-info.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-info:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-info.disabled.dropdown-toggle:after, .btn.btn-icon-info:disabled.dropdown-toggle:after {
    color: #6993FF; }

.btn.btn-hover-text-info:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-info:focus:not(.btn-text), .btn.btn-hover-text-info.focus:not(.btn-text) {
    color: #6993FF !important; }

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-info.dropdown-toggle,
.show .btn.btn-hover-text-info.btn-dropdown {
    color: #6993FF !important; }

.btn.btn-hover-icon-info:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-info:focus:not(.btn-text) i, .btn.btn-hover-icon-info.focus:not(.btn-text) i {
    color: #6993FF !important; }

.btn.btn-hover-icon-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-info.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF !important; }

.btn.btn-hover-icon-info:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-info:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-info.focus:not(.btn-text).dropdown-toggle:after {
    color: #6993FF !important; }

.btn.btn-hover-icon-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-info.dropdown-toggle i,
.show .btn.btn-hover-icon-info.btn-dropdown i {
    color: #6993FF !important; }

.btn.btn-hover-icon-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-info.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF !important; }

.btn.btn-hover-icon-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-info.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-info.btn-dropdown.dropdown-toggle:after {
    color: #6993FF !important; }

.btn.btn-text-warning {
    color: #FFA800; }
.btn.btn-text-warning.disabled, .btn.btn-text-warning:disabled {
    color: #FFA800; }

.btn.btn-icon-warning i {
    color: #FFA800; }

.btn.btn-icon-warning .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }

.btn.btn-icon-warning .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-warning.dropdown-toggle:after {
    color: #FFA800; }

.btn.btn-icon-warning.disabled i, .btn.btn-icon-warning:disabled i {
    color: #FFA800; }

.btn.btn-icon-warning.disabled .svg-icon svg g [fill], .btn.btn-icon-warning:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }

.btn.btn-icon-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-warning:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-warning.disabled.dropdown-toggle:after, .btn.btn-icon-warning:disabled.dropdown-toggle:after {
    color: #FFA800; }

.btn.btn-hover-text-warning:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-warning:focus:not(.btn-text), .btn.btn-hover-text-warning.focus:not(.btn-text) {
    color: #FFA800 !important; }

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-warning.dropdown-toggle,
.show .btn.btn-hover-text-warning.btn-dropdown {
    color: #FFA800 !important; }

.btn.btn-hover-icon-warning:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-warning:focus:not(.btn-text) i, .btn.btn-hover-icon-warning.focus:not(.btn-text) i {
    color: #FFA800 !important; }

.btn.btn-hover-icon-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800 !important; }

.btn.btn-hover-icon-warning:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-warning:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-warning.focus:not(.btn-text).dropdown-toggle:after {
    color: #FFA800 !important; }

.btn.btn-hover-icon-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-warning.dropdown-toggle i,
.show .btn.btn-hover-icon-warning.btn-dropdown i {
    color: #FFA800 !important; }

.btn.btn-hover-icon-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-warning.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800 !important; }

.btn.btn-hover-icon-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-warning.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-warning.btn-dropdown.dropdown-toggle:after {
    color: #FFA800 !important; }

.btn.btn-text-danger {
    color: #F64E60; }
.btn.btn-text-danger.disabled, .btn.btn-text-danger:disabled {
    color: #F64E60; }

.btn.btn-icon-danger i {
    color: #F64E60; }

.btn.btn-icon-danger .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }

.btn.btn-icon-danger .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-danger.dropdown-toggle:after {
    color: #F64E60; }

.btn.btn-icon-danger.disabled i, .btn.btn-icon-danger:disabled i {
    color: #F64E60; }

.btn.btn-icon-danger.disabled .svg-icon svg g [fill], .btn.btn-icon-danger:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }

.btn.btn-icon-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-danger:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-danger.disabled.dropdown-toggle:after, .btn.btn-icon-danger:disabled.dropdown-toggle:after {
    color: #F64E60; }

.btn.btn-hover-text-danger:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-danger:focus:not(.btn-text), .btn.btn-hover-text-danger.focus:not(.btn-text) {
    color: #F64E60 !important; }

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-danger.dropdown-toggle,
.show .btn.btn-hover-text-danger.btn-dropdown {
    color: #F64E60 !important; }

.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-danger:focus:not(.btn-text) i, .btn.btn-hover-icon-danger.focus:not(.btn-text) i {
    color: #F64E60 !important; }

.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60 !important; }

.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-danger:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-danger.focus:not(.btn-text).dropdown-toggle:after {
    color: #F64E60 !important; }

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-danger.dropdown-toggle i,
.show .btn.btn-hover-icon-danger.btn-dropdown i {
    color: #F64E60 !important; }

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-danger.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60 !important; }

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-danger.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-danger.btn-dropdown.dropdown-toggle:after {
    color: #F64E60 !important; }

.btn.btn-text-light {
    color: #F3F6F9; }
.btn.btn-text-light.disabled, .btn.btn-text-light:disabled {
    color: #F3F6F9; }

.btn.btn-icon-light i {
    color: #F3F6F9; }

.btn.btn-icon-light .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }

.btn.btn-icon-light .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-light.dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-icon-light.disabled i, .btn.btn-icon-light:disabled i {
    color: #F3F6F9; }

.btn.btn-icon-light.disabled .svg-icon svg g [fill], .btn.btn-icon-light:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }

.btn.btn-icon-light.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-light:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-light.disabled.dropdown-toggle:after, .btn.btn-icon-light:disabled.dropdown-toggle:after {
    color: #F3F6F9; }

.btn.btn-hover-text-light:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-light:focus:not(.btn-text), .btn.btn-hover-text-light.focus:not(.btn-text) {
    color: #F3F6F9 !important; }

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-light.dropdown-toggle,
.show .btn.btn-hover-text-light.btn-dropdown {
    color: #F3F6F9 !important; }

.btn.btn-hover-icon-light:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-light:focus:not(.btn-text) i, .btn.btn-hover-icon-light.focus:not(.btn-text) i {
    color: #F3F6F9 !important; }

.btn.btn-hover-icon-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-light.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9 !important; }

.btn.btn-hover-icon-light:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-light:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-light.focus:not(.btn-text).dropdown-toggle:after {
    color: #F3F6F9 !important; }

.btn.btn-hover-icon-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-light.dropdown-toggle i,
.show .btn.btn-hover-icon-light.btn-dropdown i {
    color: #F3F6F9 !important; }

.btn.btn-hover-icon-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-light.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9 !important; }

.btn.btn-hover-icon-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-light.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-light.btn-dropdown.dropdown-toggle:after {
    color: #F3F6F9 !important; }

.btn.btn-text-dark {
    color: #181C32; }
.btn.btn-text-dark.disabled, .btn.btn-text-dark:disabled {
    color: #181C32; }

.btn.btn-icon-dark i {
    color: #181C32; }

.btn.btn-icon-dark .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }

.btn.btn-icon-dark .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-icon-dark.disabled i, .btn.btn-icon-dark:disabled i {
    color: #181C32; }

.btn.btn-icon-dark.disabled .svg-icon svg g [fill], .btn.btn-icon-dark:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }

.btn.btn-icon-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-dark:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark.disabled.dropdown-toggle:after, .btn.btn-icon-dark:disabled.dropdown-toggle:after {
    color: #181C32; }

.btn.btn-hover-text-dark:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-dark:focus:not(.btn-text), .btn.btn-hover-text-dark.focus:not(.btn-text) {
    color: #181C32 !important; }

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark.dropdown-toggle,
.show .btn.btn-hover-text-dark.btn-dropdown {
    color: #181C32 !important; }

.btn.btn-hover-icon-dark:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-dark:focus:not(.btn-text) i, .btn.btn-hover-icon-dark.focus:not(.btn-text) i {
    color: #181C32 !important; }

.btn.btn-hover-icon-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.btn.btn-hover-icon-dark:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark.focus:not(.btn-text).dropdown-toggle:after {
    color: #181C32 !important; }

.btn.btn-hover-icon-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-dark.dropdown-toggle i,
.show .btn.btn-hover-icon-dark.btn-dropdown i {
    color: #181C32 !important; }

.btn.btn-hover-icon-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-dark.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32 !important; }

.btn.btn-hover-icon-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-dark.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-dark.btn-dropdown.dropdown-toggle:after {
    color: #181C32 !important; }

.btn.btn-text-dark-75 {
    color: #3F4254; }
.btn.btn-text-dark-75.disabled, .btn.btn-text-dark-75:disabled {
    color: #3F4254; }

.btn.btn-icon-dark-75 i {
    color: #3F4254; }

.btn.btn-icon-dark-75 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }

.btn.btn-icon-dark-75 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-75.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-icon-dark-75.disabled i, .btn.btn-icon-dark-75:disabled i {
    color: #3F4254; }

.btn.btn-icon-dark-75.disabled .svg-icon svg g [fill], .btn.btn-icon-dark-75:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }

.btn.btn-icon-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-dark-75:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-75.disabled.dropdown-toggle:after, .btn.btn-icon-dark-75:disabled.dropdown-toggle:after {
    color: #3F4254; }

.btn.btn-hover-text-dark-75:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-dark-75:focus:not(.btn-text), .btn.btn-hover-text-dark-75.focus:not(.btn-text) {
    color: #3F4254 !important; }

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark-75.dropdown-toggle,
.show .btn.btn-hover-text-dark-75.btn-dropdown {
    color: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-dark-75:focus:not(.btn-text) i, .btn.btn-hover-icon-dark-75.focus:not(.btn-text) i {
    color: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-75:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-75.focus:not(.btn-text).dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-dark-75.dropdown-toggle i,
.show .btn.btn-hover-icon-dark-75.btn-dropdown i {
    color: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-dark-75.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }

.btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-dark-75.btn-dropdown.dropdown-toggle:after {
    color: #3F4254 !important; }

.btn.btn-text-dark-65 {
    color: #5E6278; }
.btn.btn-text-dark-65.disabled, .btn.btn-text-dark-65:disabled {
    color: #5E6278; }

.btn.btn-icon-dark-65 i {
    color: #5E6278; }

.btn.btn-icon-dark-65 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278; }

.btn.btn-icon-dark-65 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-65.dropdown-toggle:after {
    color: #5E6278; }

.btn.btn-icon-dark-65.disabled i, .btn.btn-icon-dark-65:disabled i {
    color: #5E6278; }

.btn.btn-icon-dark-65.disabled .svg-icon svg g [fill], .btn.btn-icon-dark-65:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278; }

.btn.btn-icon-dark-65.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-dark-65:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-65.disabled.dropdown-toggle:after, .btn.btn-icon-dark-65:disabled.dropdown-toggle:after {
    color: #5E6278; }

.btn.btn-hover-text-dark-65:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-dark-65:focus:not(.btn-text), .btn.btn-hover-text-dark-65.focus:not(.btn-text) {
    color: #5E6278 !important; }

.btn.btn-hover-text-dark-65:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark-65:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark-65.dropdown-toggle,
.show .btn.btn-hover-text-dark-65.btn-dropdown {
    color: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-dark-65:focus:not(.btn-text) i, .btn.btn-hover-icon-dark-65.focus:not(.btn-text) i {
    color: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-65:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-65.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-65:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-65.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-65:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-dark-65:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-65.focus:not(.btn-text).dropdown-toggle:after {
    color: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-dark-65.dropdown-toggle i,
.show .btn.btn-hover-icon-dark-65.btn-dropdown i {
    color: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-dark-65.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-dark-65.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5E6278 !important; }

.btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-dark-65.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-dark-65.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-65:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-dark-65.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-dark-65.btn-dropdown.dropdown-toggle:after {
    color: #5E6278 !important; }

.btn.btn-text-dark-50 {
    color: #7E8299; }
.btn.btn-text-dark-50.disabled, .btn.btn-text-dark-50:disabled {
    color: #7E8299; }

.btn.btn-icon-dark-50 i {
    color: #7E8299; }

.btn.btn-icon-dark-50 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }

.btn.btn-icon-dark-50 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-50.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-icon-dark-50.disabled i, .btn.btn-icon-dark-50:disabled i {
    color: #7E8299; }

.btn.btn-icon-dark-50.disabled .svg-icon svg g [fill], .btn.btn-icon-dark-50:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }

.btn.btn-icon-dark-50.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-dark-50:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-50.disabled.dropdown-toggle:after, .btn.btn-icon-dark-50:disabled.dropdown-toggle:after {
    color: #7E8299; }

.btn.btn-hover-text-dark-50:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-dark-50:focus:not(.btn-text), .btn.btn-hover-text-dark-50.focus:not(.btn-text) {
    color: #7E8299 !important; }

.btn.btn-hover-text-dark-50:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark-50:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark-50.dropdown-toggle,
.show .btn.btn-hover-text-dark-50.btn-dropdown {
    color: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-dark-50:focus:not(.btn-text) i, .btn.btn-hover-icon-dark-50.focus:not(.btn-text) i {
    color: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-50:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-50.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-50:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-50.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-50:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-dark-50:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-50.focus:not(.btn-text).dropdown-toggle:after {
    color: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-dark-50.dropdown-toggle i,
.show .btn.btn-hover-icon-dark-50.btn-dropdown i {
    color: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-dark-50.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-dark-50.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }

.btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-dark-50.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-dark-50.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-50:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-dark-50.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-dark-50.btn-dropdown.dropdown-toggle:after {
    color: #7E8299 !important; }

.btn.btn-text-dark-25 {
    color: #D1D3E0; }
.btn.btn-text-dark-25.disabled, .btn.btn-text-dark-25:disabled {
    color: #D1D3E0; }

.btn.btn-icon-dark-25 i {
    color: #D1D3E0; }

.btn.btn-icon-dark-25 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0; }

.btn.btn-icon-dark-25 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-25.dropdown-toggle:after {
    color: #D1D3E0; }

.btn.btn-icon-dark-25.disabled i, .btn.btn-icon-dark-25:disabled i {
    color: #D1D3E0; }

.btn.btn-icon-dark-25.disabled .svg-icon svg g [fill], .btn.btn-icon-dark-25:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0; }

.btn.btn-icon-dark-25.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-dark-25:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-dark-25.disabled.dropdown-toggle:after, .btn.btn-icon-dark-25:disabled.dropdown-toggle:after {
    color: #D1D3E0; }

.btn.btn-hover-text-dark-25:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-dark-25:focus:not(.btn-text), .btn.btn-hover-text-dark-25.focus:not(.btn-text) {
    color: #D1D3E0 !important; }

.btn.btn-hover-text-dark-25:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark-25:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark-25.dropdown-toggle,
.show .btn.btn-hover-text-dark-25.btn-dropdown {
    color: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-dark-25:focus:not(.btn-text) i, .btn.btn-hover-icon-dark-25.focus:not(.btn-text) i {
    color: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-25:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-25.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-25:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-25.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-25:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-dark-25:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-25.focus:not(.btn-text).dropdown-toggle:after {
    color: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-dark-25.dropdown-toggle i,
.show .btn.btn-hover-icon-dark-25.btn-dropdown i {
    color: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-dark-25.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-dark-25.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D1D3E0 !important; }

.btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-dark-25.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-dark-25.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-dark-25:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-dark-25.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-dark-25.btn-dropdown.dropdown-toggle:after {
    color: #D1D3E0 !important; }

.btn.btn-text-muted {
    color: #B5B5C3; }
.btn.btn-text-muted.disabled, .btn.btn-text-muted:disabled {
    color: #B5B5C3; }

.btn.btn-icon-muted i {
    color: #B5B5C3; }

.btn.btn-icon-muted .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }

.btn.btn-icon-muted .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-muted.dropdown-toggle:after {
    color: #B5B5C3; }

.btn.btn-icon-muted.disabled i, .btn.btn-icon-muted:disabled i {
    color: #B5B5C3; }

.btn.btn-icon-muted.disabled .svg-icon svg g [fill], .btn.btn-icon-muted:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }

.btn.btn-icon-muted.disabled .svg-icon svg:hover g [fill], .btn.btn-icon-muted:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-icon-muted.disabled.dropdown-toggle:after, .btn.btn-icon-muted:disabled.dropdown-toggle:after {
    color: #B5B5C3; }

.btn.btn-hover-text-muted:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-text-muted:focus:not(.btn-text), .btn.btn-hover-text-muted.focus:not(.btn-text) {
    color: #B5B5C3 !important; }

.btn.btn-hover-text-muted:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-muted:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-muted.dropdown-toggle,
.show .btn.btn-hover-text-muted.btn-dropdown {
    color: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-hover-icon-muted:focus:not(.btn-text) i, .btn.btn-hover-icon-muted.focus:not(.btn-text) i {
    color: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-icon-muted:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-muted.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-muted:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-muted.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-muted:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-hover-icon-muted:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-muted.focus:not(.btn-text).dropdown-toggle:after {
    color: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-icon-muted:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-icon-muted.dropdown-toggle i,
.show .btn.btn-hover-icon-muted.btn-dropdown i {
    color: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-icon-muted:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-icon-muted.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-icon-muted.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3 !important; }

.btn.btn-hover-icon-muted:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-icon-muted:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-icon-muted.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-icon-muted.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }

.btn.btn-hover-icon-muted:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-icon-muted:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-icon-muted.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-icon-muted.btn-dropdown.dropdown-toggle:after {
    color: #B5B5C3 !important; }

.btn.btn-facebook {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998; }
.btn.btn-facebook i {
    color: white; }
.btn.btn-facebook .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-facebook .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-facebook.dropdown-toggle:after {
    color: white; }
.btn.btn-facebook:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-facebook:focus:not(.btn-text), .btn.btn-facebook.focus:not(.btn-text) {
    color: white;
    background-color: #30497c;
    border-color: #30497c; }
.btn.btn-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-facebook:focus:not(.btn-text) i, .btn.btn-facebook.focus:not(.btn-text) i {
    color: white; }
.btn.btn-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-facebook:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-facebook.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-facebook:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-facebook.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-facebook:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-facebook:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-facebook.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-facebook.disabled, .btn.btn-facebook:disabled {
    color: white;
    background-color: #3b5998;
    border-color: #3b5998; }
.btn.btn-facebook.disabled i, .btn.btn-facebook:disabled i {
    color: white; }
.btn.btn-facebook.disabled .svg-icon svg g [fill], .btn.btn-facebook:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-facebook.disabled .svg-icon svg:hover g [fill], .btn.btn-facebook:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-facebook.disabled.dropdown-toggle:after, .btn.btn-facebook:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-facebook:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn.btn-facebook.dropdown-toggle,
.show .btn.btn-facebook.btn-dropdown {
    color: white;
    background-color: #30497c;
    border-color: #30497c; }
.btn.btn-facebook:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-facebook:not(:disabled):not(.disabled).active i,
.show > .btn.btn-facebook.dropdown-toggle i,
.show .btn.btn-facebook.btn-dropdown i {
    color: white; }
.btn.btn-facebook:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-facebook:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-facebook.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-facebook.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-facebook:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-facebook:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-facebook.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-facebook.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-facebook:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-facebook:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-facebook.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-facebook.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-facebook {
    color: #3b5998;
    background-color: rgba(59, 89, 152, 0.1);
    border-color: transparent; }
.btn.btn-light-facebook i {
    color: #3b5998; }
.btn.btn-light-facebook .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3b5998; }
.btn.btn-light-facebook .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-facebook.dropdown-toggle:after {
    color: #3b5998; }
.btn.btn-light-facebook:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-facebook:focus:not(.btn-text), .btn.btn-light-facebook.focus:not(.btn-text) {
    color: white;
    background-color: #3b5998;
    border-color: transparent; }
.btn.btn-light-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-facebook:focus:not(.btn-text) i, .btn.btn-light-facebook.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-facebook:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-facebook.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-facebook:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-facebook:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-facebook.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-facebook:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-facebook:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-facebook.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-facebook.disabled, .btn.btn-light-facebook:disabled {
    color: #3b5998;
    background-color: rgba(59, 89, 152, 0.1);
    border-color: transparent; }
.btn.btn-light-facebook.disabled i, .btn.btn-light-facebook:disabled i {
    color: #3b5998; }
.btn.btn-light-facebook.disabled .svg-icon svg g [fill], .btn.btn-light-facebook:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3b5998; }
.btn.btn-light-facebook.disabled .svg-icon svg:hover g [fill], .btn.btn-light-facebook:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-facebook.disabled.dropdown-toggle:after, .btn.btn-light-facebook:disabled.dropdown-toggle:after {
    color: #3b5998; }
.btn.btn-light-facebook:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-facebook:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-facebook.dropdown-toggle,
.show .btn.btn-light-facebook.btn-dropdown {
    color: white;
    background-color: #3b5998;
    border-color: transparent; }
.btn.btn-light-facebook:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-facebook:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-facebook.dropdown-toggle i,
.show .btn.btn-light-facebook.btn-dropdown i {
    color: white; }
.btn.btn-light-facebook:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-facebook:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-facebook.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-facebook.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-facebook:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-facebook:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-facebook.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-facebook.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-facebook:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-facebook:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-facebook.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-facebook.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-google {
    color: white;
    background-color: #dd4b39;
    border-color: #dd4b39; }
.btn.btn-google i {
    color: white; }
.btn.btn-google .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-google .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-google.dropdown-toggle:after {
    color: white; }
.btn.btn-google:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-google:focus:not(.btn-text), .btn.btn-google.focus:not(.btn-text) {
    color: white;
    background-color: #cd3623;
    border-color: #cd3623; }
.btn.btn-google:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-google:focus:not(.btn-text) i, .btn.btn-google.focus:not(.btn-text) i {
    color: white; }
.btn.btn-google:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-google:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-google.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-google:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-google:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-google.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-google:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-google:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-google.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-google.disabled, .btn.btn-google:disabled {
    color: white;
    background-color: #dd4b39;
    border-color: #dd4b39; }
.btn.btn-google.disabled i, .btn.btn-google:disabled i {
    color: white; }
.btn.btn-google.disabled .svg-icon svg g [fill], .btn.btn-google:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-google.disabled .svg-icon svg:hover g [fill], .btn.btn-google:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-google.disabled.dropdown-toggle:after, .btn.btn-google:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-google:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-google:not(:disabled):not(.disabled).active,
.show > .btn.btn-google.dropdown-toggle,
.show .btn.btn-google.btn-dropdown {
    color: white;
    background-color: #cd3623;
    border-color: #cd3623; }
.btn.btn-google:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-google:not(:disabled):not(.disabled).active i,
.show > .btn.btn-google.dropdown-toggle i,
.show .btn.btn-google.btn-dropdown i {
    color: white; }
.btn.btn-google:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-google:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-google.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-google.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-google:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-google:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-google.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-google.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-google:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-google:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-google.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-google.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-google {
    color: #dd4b39;
    background-color: rgba(221, 75, 57, 0.1);
    border-color: transparent; }
.btn.btn-light-google i {
    color: #dd4b39; }
.btn.btn-light-google .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #dd4b39; }
.btn.btn-light-google .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-google.dropdown-toggle:after {
    color: #dd4b39; }
.btn.btn-light-google:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-google:focus:not(.btn-text), .btn.btn-light-google.focus:not(.btn-text) {
    color: white;
    background-color: #dd4b39;
    border-color: transparent; }
.btn.btn-light-google:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-google:focus:not(.btn-text) i, .btn.btn-light-google.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-google:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-google:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-google.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-google:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-google:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-google.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-google:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-google:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-google.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-google.disabled, .btn.btn-light-google:disabled {
    color: #dd4b39;
    background-color: rgba(221, 75, 57, 0.1);
    border-color: transparent; }
.btn.btn-light-google.disabled i, .btn.btn-light-google:disabled i {
    color: #dd4b39; }
.btn.btn-light-google.disabled .svg-icon svg g [fill], .btn.btn-light-google:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #dd4b39; }
.btn.btn-light-google.disabled .svg-icon svg:hover g [fill], .btn.btn-light-google:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-google.disabled.dropdown-toggle:after, .btn.btn-light-google:disabled.dropdown-toggle:after {
    color: #dd4b39; }
.btn.btn-light-google:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-google:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-google.dropdown-toggle,
.show .btn.btn-light-google.btn-dropdown {
    color: white;
    background-color: #dd4b39;
    border-color: transparent; }
.btn.btn-light-google:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-google:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-google.dropdown-toggle i,
.show .btn.btn-light-google.btn-dropdown i {
    color: white; }
.btn.btn-light-google:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-google:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-google.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-google.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-google:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-google:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-google.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-google.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-google:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-google:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-google.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-google.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-twitter {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2; }
.btn.btn-twitter i {
    color: white; }
.btn.btn-twitter .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-twitter .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-twitter.dropdown-toggle:after {
    color: white; }
.btn.btn-twitter:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-twitter:focus:not(.btn-text), .btn.btn-twitter.focus:not(.btn-text) {
    color: white;
    background-color: #0d8ddc;
    border-color: #0d8ddc; }
.btn.btn-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-twitter:focus:not(.btn-text) i, .btn.btn-twitter.focus:not(.btn-text) i {
    color: white; }
.btn.btn-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-twitter:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-twitter.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-twitter:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-twitter.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-twitter:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-twitter:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-twitter.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-twitter.disabled, .btn.btn-twitter:disabled {
    color: white;
    background-color: #1da1f2;
    border-color: #1da1f2; }
.btn.btn-twitter.disabled i, .btn.btn-twitter:disabled i {
    color: white; }
.btn.btn-twitter.disabled .svg-icon svg g [fill], .btn.btn-twitter:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-twitter.disabled .svg-icon svg:hover g [fill], .btn.btn-twitter:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-twitter.disabled.dropdown-toggle:after, .btn.btn-twitter:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-twitter:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn.btn-twitter.dropdown-toggle,
.show .btn.btn-twitter.btn-dropdown {
    color: white;
    background-color: #0d8ddc;
    border-color: #0d8ddc; }
.btn.btn-twitter:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-twitter:not(:disabled):not(.disabled).active i,
.show > .btn.btn-twitter.dropdown-toggle i,
.show .btn.btn-twitter.btn-dropdown i {
    color: white; }
.btn.btn-twitter:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-twitter:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-twitter.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-twitter.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-twitter:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-twitter:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-twitter.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-twitter.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-twitter:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-twitter:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-twitter.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-twitter.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-twitter {
    color: #1da1f2;
    background-color: rgba(29, 161, 242, 0.1);
    border-color: transparent; }
.btn.btn-light-twitter i {
    color: #1da1f2; }
.btn.btn-light-twitter .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #1da1f2; }
.btn.btn-light-twitter .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-twitter.dropdown-toggle:after {
    color: #1da1f2; }
.btn.btn-light-twitter:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-twitter:focus:not(.btn-text), .btn.btn-light-twitter.focus:not(.btn-text) {
    color: white;
    background-color: #1da1f2;
    border-color: transparent; }
.btn.btn-light-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-twitter:focus:not(.btn-text) i, .btn.btn-light-twitter.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-twitter:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-twitter.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-twitter:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-twitter:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-twitter.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-twitter:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-twitter:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-twitter.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-twitter.disabled, .btn.btn-light-twitter:disabled {
    color: #1da1f2;
    background-color: rgba(29, 161, 242, 0.1);
    border-color: transparent; }
.btn.btn-light-twitter.disabled i, .btn.btn-light-twitter:disabled i {
    color: #1da1f2; }
.btn.btn-light-twitter.disabled .svg-icon svg g [fill], .btn.btn-light-twitter:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #1da1f2; }
.btn.btn-light-twitter.disabled .svg-icon svg:hover g [fill], .btn.btn-light-twitter:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-twitter.disabled.dropdown-toggle:after, .btn.btn-light-twitter:disabled.dropdown-toggle:after {
    color: #1da1f2; }
.btn.btn-light-twitter:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-twitter:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-twitter.dropdown-toggle,
.show .btn.btn-light-twitter.btn-dropdown {
    color: white;
    background-color: #1da1f2;
    border-color: transparent; }
.btn.btn-light-twitter:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-twitter:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-twitter.dropdown-toggle i,
.show .btn.btn-light-twitter.btn-dropdown i {
    color: white; }
.btn.btn-light-twitter:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-twitter:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-twitter.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-twitter.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-twitter:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-twitter:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-twitter.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-twitter.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-twitter:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-twitter:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-twitter.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-twitter.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-instagram {
    color: white;
    background-color: #e1306c;
    border-color: #e1306c; }
.btn.btn-instagram i {
    color: white; }
.btn.btn-instagram .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-instagram .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-instagram.dropdown-toggle:after {
    color: white; }
.btn.btn-instagram:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-instagram:focus:not(.btn-text), .btn.btn-instagram.focus:not(.btn-text) {
    color: white;
    background-color: #cd1e59;
    border-color: #cd1e59; }
.btn.btn-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-instagram:focus:not(.btn-text) i, .btn.btn-instagram.focus:not(.btn-text) i {
    color: white; }
.btn.btn-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-instagram:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-instagram.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-instagram:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-instagram.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-instagram:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-instagram:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-instagram.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-instagram.disabled, .btn.btn-instagram:disabled {
    color: white;
    background-color: #e1306c;
    border-color: #e1306c; }
.btn.btn-instagram.disabled i, .btn.btn-instagram:disabled i {
    color: white; }
.btn.btn-instagram.disabled .svg-icon svg g [fill], .btn.btn-instagram:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-instagram.disabled .svg-icon svg:hover g [fill], .btn.btn-instagram:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-instagram.disabled.dropdown-toggle:after, .btn.btn-instagram:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-instagram:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn.btn-instagram.dropdown-toggle,
.show .btn.btn-instagram.btn-dropdown {
    color: white;
    background-color: #cd1e59;
    border-color: #cd1e59; }
.btn.btn-instagram:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-instagram:not(:disabled):not(.disabled).active i,
.show > .btn.btn-instagram.dropdown-toggle i,
.show .btn.btn-instagram.btn-dropdown i {
    color: white; }
.btn.btn-instagram:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-instagram:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-instagram.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-instagram.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-instagram:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-instagram:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-instagram.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-instagram.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-instagram:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-instagram:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-instagram.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-instagram.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-instagram {
    color: #e1306c;
    background-color: rgba(225, 48, 108, 0.1);
    border-color: transparent; }
.btn.btn-light-instagram i {
    color: #e1306c; }
.btn.btn-light-instagram .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e1306c; }
.btn.btn-light-instagram .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-instagram.dropdown-toggle:after {
    color: #e1306c; }
.btn.btn-light-instagram:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-instagram:focus:not(.btn-text), .btn.btn-light-instagram.focus:not(.btn-text) {
    color: white;
    background-color: #e1306c;
    border-color: transparent; }
.btn.btn-light-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-instagram:focus:not(.btn-text) i, .btn.btn-light-instagram.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-instagram:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-instagram.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-instagram:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-instagram:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-instagram.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-instagram:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-instagram:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-instagram.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-instagram.disabled, .btn.btn-light-instagram:disabled {
    color: #e1306c;
    background-color: rgba(225, 48, 108, 0.1);
    border-color: transparent; }
.btn.btn-light-instagram.disabled i, .btn.btn-light-instagram:disabled i {
    color: #e1306c; }
.btn.btn-light-instagram.disabled .svg-icon svg g [fill], .btn.btn-light-instagram:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e1306c; }
.btn.btn-light-instagram.disabled .svg-icon svg:hover g [fill], .btn.btn-light-instagram:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-instagram.disabled.dropdown-toggle:after, .btn.btn-light-instagram:disabled.dropdown-toggle:after {
    color: #e1306c; }
.btn.btn-light-instagram:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-instagram:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-instagram.dropdown-toggle,
.show .btn.btn-light-instagram.btn-dropdown {
    color: white;
    background-color: #e1306c;
    border-color: transparent; }
.btn.btn-light-instagram:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-instagram:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-instagram.dropdown-toggle i,
.show .btn.btn-light-instagram.btn-dropdown i {
    color: white; }
.btn.btn-light-instagram:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-instagram:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-instagram.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-instagram.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-instagram:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-instagram:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-instagram.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-instagram.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-instagram:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-instagram:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-instagram.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-instagram.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-youtube {
    color: white;
    background-color: #ff0000;
    border-color: #ff0000; }
.btn.btn-youtube i {
    color: white; }
.btn.btn-youtube .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-youtube .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-youtube.dropdown-toggle:after {
    color: white; }
.btn.btn-youtube:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-youtube:focus:not(.btn-text), .btn.btn-youtube.focus:not(.btn-text) {
    color: white;
    background-color: #d90000;
    border-color: #d90000; }
.btn.btn-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-youtube:focus:not(.btn-text) i, .btn.btn-youtube.focus:not(.btn-text) i {
    color: white; }
.btn.btn-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-youtube:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-youtube.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-youtube:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-youtube.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-youtube:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-youtube:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-youtube.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-youtube.disabled, .btn.btn-youtube:disabled {
    color: white;
    background-color: #ff0000;
    border-color: #ff0000; }
.btn.btn-youtube.disabled i, .btn.btn-youtube:disabled i {
    color: white; }
.btn.btn-youtube.disabled .svg-icon svg g [fill], .btn.btn-youtube:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-youtube.disabled .svg-icon svg:hover g [fill], .btn.btn-youtube:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-youtube.disabled.dropdown-toggle:after, .btn.btn-youtube:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-youtube:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn.btn-youtube.dropdown-toggle,
.show .btn.btn-youtube.btn-dropdown {
    color: white;
    background-color: #d90000;
    border-color: #d90000; }
.btn.btn-youtube:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-youtube:not(:disabled):not(.disabled).active i,
.show > .btn.btn-youtube.dropdown-toggle i,
.show .btn.btn-youtube.btn-dropdown i {
    color: white; }
.btn.btn-youtube:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-youtube:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-youtube.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-youtube.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-youtube:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-youtube:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-youtube.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-youtube.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-youtube:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-youtube:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-youtube.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-youtube.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-youtube {
    color: #ff0000;
    background-color: rgba(255, 0, 0, 0.1);
    border-color: transparent; }
.btn.btn-light-youtube i {
    color: #ff0000; }
.btn.btn-light-youtube .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ff0000; }
.btn.btn-light-youtube .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-youtube.dropdown-toggle:after {
    color: #ff0000; }
.btn.btn-light-youtube:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-youtube:focus:not(.btn-text), .btn.btn-light-youtube.focus:not(.btn-text) {
    color: white;
    background-color: #ff0000;
    border-color: transparent; }
.btn.btn-light-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-youtube:focus:not(.btn-text) i, .btn.btn-light-youtube.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-youtube:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-youtube.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-youtube:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-youtube:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-youtube.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-youtube:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-youtube:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-youtube.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-youtube.disabled, .btn.btn-light-youtube:disabled {
    color: #ff0000;
    background-color: rgba(255, 0, 0, 0.1);
    border-color: transparent; }
.btn.btn-light-youtube.disabled i, .btn.btn-light-youtube:disabled i {
    color: #ff0000; }
.btn.btn-light-youtube.disabled .svg-icon svg g [fill], .btn.btn-light-youtube:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ff0000; }
.btn.btn-light-youtube.disabled .svg-icon svg:hover g [fill], .btn.btn-light-youtube:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-youtube.disabled.dropdown-toggle:after, .btn.btn-light-youtube:disabled.dropdown-toggle:after {
    color: #ff0000; }
.btn.btn-light-youtube:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-youtube:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-youtube.dropdown-toggle,
.show .btn.btn-light-youtube.btn-dropdown {
    color: white;
    background-color: #ff0000;
    border-color: transparent; }
.btn.btn-light-youtube:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-youtube:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-youtube.dropdown-toggle i,
.show .btn.btn-light-youtube.btn-dropdown i {
    color: white; }
.btn.btn-light-youtube:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-youtube:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-youtube.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-youtube.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-youtube:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-youtube:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-youtube.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-youtube.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-youtube:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-youtube:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-youtube.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-youtube.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-linkedin {
    color: white;
    background-color: #0077b5;
    border-color: #0077b5; }
.btn.btn-linkedin i {
    color: white; }
.btn.btn-linkedin .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-linkedin .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-linkedin.dropdown-toggle:after {
    color: white; }
.btn.btn-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-linkedin:focus:not(.btn-text), .btn.btn-linkedin.focus:not(.btn-text) {
    color: white;
    background-color: #005e8f;
    border-color: #005e8f; }
.btn.btn-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-linkedin:focus:not(.btn-text) i, .btn.btn-linkedin.focus:not(.btn-text) i {
    color: white; }
.btn.btn-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-linkedin:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-linkedin.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-linkedin:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-linkedin.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-linkedin:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-linkedin.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-linkedin.disabled, .btn.btn-linkedin:disabled {
    color: white;
    background-color: #0077b5;
    border-color: #0077b5; }
.btn.btn-linkedin.disabled i, .btn.btn-linkedin:disabled i {
    color: white; }
.btn.btn-linkedin.disabled .svg-icon svg g [fill], .btn.btn-linkedin:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-linkedin.disabled .svg-icon svg:hover g [fill], .btn.btn-linkedin:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-linkedin.disabled.dropdown-toggle:after, .btn.btn-linkedin:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-linkedin:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn.btn-linkedin.dropdown-toggle,
.show .btn.btn-linkedin.btn-dropdown {
    color: white;
    background-color: #005e8f;
    border-color: #005e8f; }
.btn.btn-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-linkedin:not(:disabled):not(.disabled).active i,
.show > .btn.btn-linkedin.dropdown-toggle i,
.show .btn.btn-linkedin.btn-dropdown i {
    color: white; }
.btn.btn-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-linkedin:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-linkedin.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-linkedin.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-linkedin:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-linkedin.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-linkedin.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-linkedin:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-linkedin:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-linkedin.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-linkedin.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-linkedin {
    color: #0077b5;
    background-color: rgba(0, 119, 181, 0.1);
    border-color: transparent; }
.btn.btn-light-linkedin i {
    color: #0077b5; }
.btn.btn-light-linkedin .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #0077b5; }
.btn.btn-light-linkedin .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-linkedin.dropdown-toggle:after {
    color: #0077b5; }
.btn.btn-light-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-linkedin:focus:not(.btn-text), .btn.btn-light-linkedin.focus:not(.btn-text) {
    color: white;
    background-color: #0077b5;
    border-color: transparent; }
.btn.btn-light-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-linkedin:focus:not(.btn-text) i, .btn.btn-light-linkedin.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-linkedin:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-linkedin.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-linkedin:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-linkedin.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-linkedin:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-linkedin:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-linkedin.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-linkedin.disabled, .btn.btn-light-linkedin:disabled {
    color: #0077b5;
    background-color: rgba(0, 119, 181, 0.1);
    border-color: transparent; }
.btn.btn-light-linkedin.disabled i, .btn.btn-light-linkedin:disabled i {
    color: #0077b5; }
.btn.btn-light-linkedin.disabled .svg-icon svg g [fill], .btn.btn-light-linkedin:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #0077b5; }
.btn.btn-light-linkedin.disabled .svg-icon svg:hover g [fill], .btn.btn-light-linkedin:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-linkedin.disabled.dropdown-toggle:after, .btn.btn-light-linkedin:disabled.dropdown-toggle:after {
    color: #0077b5; }
.btn.btn-light-linkedin:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-linkedin:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-linkedin.dropdown-toggle,
.show .btn.btn-light-linkedin.btn-dropdown {
    color: white;
    background-color: #0077b5;
    border-color: transparent; }
.btn.btn-light-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-linkedin:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-linkedin.dropdown-toggle i,
.show .btn.btn-light-linkedin.btn-dropdown i {
    color: white; }
.btn.btn-light-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-linkedin:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-linkedin.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-linkedin.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-linkedin:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-linkedin:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-linkedin.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-linkedin.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-linkedin:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-linkedin:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-linkedin.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-linkedin.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-skype {
    color: white;
    background-color: #00aff0;
    border-color: #00aff0; }
.btn.btn-skype i {
    color: white; }
.btn.btn-skype .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-skype .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-skype.dropdown-toggle:after {
    color: white; }
.btn.btn-skype:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-skype:focus:not(.btn-text), .btn.btn-skype.focus:not(.btn-text) {
    color: white;
    background-color: #0093ca;
    border-color: #0093ca; }
.btn.btn-skype:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-skype:focus:not(.btn-text) i, .btn.btn-skype.focus:not(.btn-text) i {
    color: white; }
.btn.btn-skype:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-skype:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-skype.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-skype:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-skype:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-skype.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-skype:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-skype:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-skype.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-skype.disabled, .btn.btn-skype:disabled {
    color: white;
    background-color: #00aff0;
    border-color: #00aff0; }
.btn.btn-skype.disabled i, .btn.btn-skype:disabled i {
    color: white; }
.btn.btn-skype.disabled .svg-icon svg g [fill], .btn.btn-skype:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-skype.disabled .svg-icon svg:hover g [fill], .btn.btn-skype:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-skype.disabled.dropdown-toggle:after, .btn.btn-skype:disabled.dropdown-toggle:after {
    color: white; }
.btn.btn-skype:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-skype:not(:disabled):not(.disabled).active,
.show > .btn.btn-skype.dropdown-toggle,
.show .btn.btn-skype.btn-dropdown {
    color: white;
    background-color: #0093ca;
    border-color: #0093ca; }
.btn.btn-skype:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-skype:not(:disabled):not(.disabled).active i,
.show > .btn.btn-skype.dropdown-toggle i,
.show .btn.btn-skype.btn-dropdown i {
    color: white; }
.btn.btn-skype:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-skype:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-skype.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-skype.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-skype:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-skype:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-skype.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-skype.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-skype:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-skype:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-skype.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-skype.btn-dropdown.dropdown-toggle:after {
    color: white; }

.btn.btn-light-skype {
    color: #00aff0;
    background-color: rgba(0, 175, 240, 0.1);
    border-color: transparent; }
.btn.btn-light-skype i {
    color: #00aff0; }
.btn.btn-light-skype .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #00aff0; }
.btn.btn-light-skype .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-skype.dropdown-toggle:after {
    color: #00aff0; }
.btn.btn-light-skype:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-skype:focus:not(.btn-text), .btn.btn-light-skype.focus:not(.btn-text) {
    color: white;
    background-color: #00aff0;
    border-color: transparent; }
.btn.btn-light-skype:hover:not(.btn-text):not(:disabled):not(.disabled) i, .btn.btn-light-skype:focus:not(.btn-text) i, .btn.btn-light-skype.focus:not(.btn-text) i {
    color: white; }
.btn.btn-light-skype:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-light-skype:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-skype.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-skype:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill], .btn.btn-light-skype:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-skype.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-skype:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after, .btn.btn-light-skype:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-skype.focus:not(.btn-text).dropdown-toggle:after {
    color: white; }
.btn.btn-light-skype.disabled, .btn.btn-light-skype:disabled {
    color: #00aff0;
    background-color: rgba(0, 175, 240, 0.1);
    border-color: transparent; }
.btn.btn-light-skype.disabled i, .btn.btn-light-skype:disabled i {
    color: #00aff0; }
.btn.btn-light-skype.disabled .svg-icon svg g [fill], .btn.btn-light-skype:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #00aff0; }
.btn.btn-light-skype.disabled .svg-icon svg:hover g [fill], .btn.btn-light-skype:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-skype.disabled.dropdown-toggle:after, .btn.btn-light-skype:disabled.dropdown-toggle:after {
    color: #00aff0; }
.btn.btn-light-skype:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-skype:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-skype.dropdown-toggle,
.show .btn.btn-light-skype.btn-dropdown {
    color: white;
    background-color: #00aff0;
    border-color: transparent; }
.btn.btn-light-skype:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-skype:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-skype.dropdown-toggle i,
.show .btn.btn-light-skype.btn-dropdown i {
    color: white; }
.btn.btn-light-skype:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-skype:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-skype.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-skype.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.btn.btn-light-skype:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-skype:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-skype.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-skype.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.btn.btn-light-skype:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-skype:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-skype.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-skype.btn-dropdown.dropdown-toggle:after {
    color: white; }
.border-dashed {
    border: 2px dashed var(--originaltwo) !important;
}

.left-menu-icon i{
    color: var(--left-menu-icon-color) !important;
}

.left-menu-icon.active,
.left-menu-icon:hover.active {
    background-color: var(--left-menu-icon-color) !important;
}

.left-menu-icon.active i{
    color: var(--originaltwo) !important;
}

.left-menu-icon:hover i{
    color: var(--originaltwo) !important;
}

.pagination-btn,
.pagination-btn i,
.table-menu-arrow-btn,
.table-menu-arrow-btn i{
    color: var(--pagination-color) !important;
    background-color: var(--pagination-bg) !important;
    border-color: var(--pagination-bg) !important;
}

.pagination-btn.active,
.pagination-btn:hover,
.pagination-btn:hover i,
.table-menu-arrow-btn:not(.disabled):hover,
.table-menu-arrow-btn:not(.disabled):hover i,
.table-menu-btn,
.table-menu-btn i{
    color: white !important;
    background-color: var(--pagination-color) !important;
    border-color: var(--pagination-color) !important;
}
