@charset "UTF-8";
.tooltip_new {
    border-radius:1rem;
}
.tooltip_new text_tt {
    border-radius:1rem;
    vertical-align: center;
    text-align: center;
}
.badge {
    display: inline-block;
    padding: 0.5em 0.75em;
    font-size: 85%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.42rem;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out; }
@media (prefers-reduced-motion: reduce) {
    .badge {
        -webkit-transition: none;
        transition: none; } }
a.badge:hover, a.badge:focus {
    text-decoration: none; }
.badge:empty {
    display: none; }

.btn .badge {
    position: relative;
    top: -1px; }

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }

.badge-primary {
    color: white;
    background-color: var(--success); }
a.badge-primary:hover, a.badge-primary:focus {
    color: white;
    background-color: #159892; }
a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5); }

.badge-secondary {
    color: #181C32;
    background-color: #E4E6EF; }
a.badge-secondary:hover, a.badge-secondary:focus {
    color: #181C32;
    background-color: #c4c8dc; }
a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(228, 230, 239, 0.5); }

.badge-success {
    color: white;
    background-color: var(--success); }
a.badge-success:hover, a.badge-success:focus {
    color: white;
    background-color: #037fff; }
a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.5); }

.badge-info {
    color: white;
    background-color: #6993FF; }
a.badge-info:hover, a.badge-info:focus {
    color: white;
    background-color: #366eff; }
a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5); }

.badge-warning {
    color: #181C32;
    background-color: #FFA800; }
a.badge-warning:hover, a.badge-warning:focus {
    color: #181C32;
    background-color: #cc8600; }
a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5); }

.badge-danger {
    color: white;
    background-color: #F64E60; }
a.badge-danger:hover, a.badge-danger:focus {
    color: white;
    background-color: #f41d34; }
a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5); }

.badge-light {
    color: #181C32;
    background-color: var(--elem-bg); }
a.badge-light:hover, a.badge-light:focus {
    color: #181C32;
    background-color: #d1dde8; }
a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5); }

.badge-dark {
    color: white;
    background-color: #181C32; }
a.badge-dark:hover, a.badge-dark:focus {
    color: white;
    background-color: #070910; }
a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(24, 28, 50, 0.5); }

.badge-white {
    color: #181C32;
    background-color: white; }
a.badge-white:hover, a.badge-white:focus {
    color: #181C32;
    background-color: #e6e6e6; }
a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #EBEDF3;
    border-radius: 0.85rem; }
@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem; } }

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.42rem; }

.alert-heading {
    color: inherit; }

.alert-link {
    font-weight: 500; }

.alert-dismissible {
    padding-right: 4rem; }
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }


.alert-primary hr {
    border-top-color: #abeae7; }
.alert-primary .alert-link {
    color: #083937; }

.alert-secondary {
    color: #77787c;
    background-color: #fafafc;
    border-color: #f7f8fb; }
.alert-secondary hr {
    border-top-color: #e6e9f3; }
.alert-secondary .alert-link {
    color: #5e5f62; }

.alert-success {
    color: #1c5085;
    background-color: #d7ebff;
    border-color: #c7e2ff; }
.alert-success hr {
    border-top-color: #aed5ff; }
.alert-success .alert-link {
    color: #13375b; }

.alert-info {
    color: #374c85;
    background-color: #e1e9ff;
    border-color: #d5e1ff; }
.alert-info hr {
    border-top-color: #bccfff; }
.alert-info .alert-link {
    color: #283761; }

.alert-warning {
    color: #855700;
    background-color: #ffeecc;
    border-color: #ffe7b8; }
.alert-warning hr {
    border-top-color: #ffde9f; }
.alert-warning .alert-link {
    color: #523600; }

.alert-danger {
    color: #802932;
    background-color: #fddcdf;
    border-color: #fccdd2; }
.alert-danger hr {
    border-top-color: #fbb5bc; }
.alert-danger .alert-link {
    color: #591d23; }

.alert-light {
    color: #7e8081;
    background-color: #fdfdfe;
    border-color: #fcfcfd; }
.alert-light hr {
    border-top-color: #ededf3; }
.alert-light .alert-link {
    color: #656667; }

.alert-dark {
    color: #0c0f1a;
    background-color: #d1d2d6;
    border-color: #bebfc6; }
.alert-dark hr {
    border-top-color: #b0b2ba; }
.alert-dark .alert-link {
    color: black; }

.alert-white {
    color: #858585;
    background-color: white;
    border-color: white; }
.alert-white hr {
    border-top-color: #f2f2f2; }
.alert-white .alert-link {
    color: #6c6c6c; }

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 white;
    opacity: .5; }
@media (max-width: 1200px) {
    .close {
        font-size: calc(1.275rem + 0.3vw) ; } }
.close:hover {
    color: #000000;
    text-decoration: none; }
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
    padding: 0;
    background-color: transparent;
    border: 0; }

a.close.disabled {
    pointer-events: none; }

.alert.alert-primary {
    background-color: var(--success);
    border-color: var(--success);
    color: white; }
.alert.alert-primary .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-primary .close:focus i, .alert.alert-primary .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-secondary {
    background-color: #E4E6EF;
    border-color: #E4E6EF;
    color: #3F4254; }
.alert.alert-secondary .close i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-secondary .close:focus i, .alert.alert-secondary .close:hover i {
    opacity: 1;
    color: #3F4254; }

.alert.alert-success {
    background-color: var(--success);
    border-color: var(--success);
    color: white; }
.alert.alert-success .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-success .close:focus i, .alert.alert-success .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-info {
    background-color: #6993FF;
    border-color: #6993FF;
    color: white; }
.alert.alert-info .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-info .close:focus i, .alert.alert-info .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-warning {
    background-color: #FFA800;
    border-color: #FFA800;
    color: white; }
.alert.alert-warning .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-warning .close:focus i, .alert.alert-warning .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-danger {
    background-color: #F64E60;
    border-color: #F64E60;
    color: white; }
.alert.alert-danger .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-danger .close:focus i, .alert.alert-danger .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-light {
    background-color: var(--elem-bg);
    border-color: var(--elem-bg);
    color: #7E8299; }
.alert.alert-light .close i {
    opacity: 0.8;
    color: #7E8299; }
.alert.alert-light .close:focus i, .alert.alert-light .close:hover i {
    opacity: 1;
    color: #7E8299; }

.alert.alert-dark {
    background-color: #181C32;
    border-color: #181C32;
    color: white; }
.alert.alert-dark .close i {
    opacity: 0.8;
    color: white; }
.alert.alert-dark .close:focus i, .alert.alert-dark .close:hover i {
    opacity: 1;
    color: white; }

.alert.alert-white {
    background-color: white;
    border-color: white;
    color: #3F4254; }
.alert.alert-white .close i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-white .close:focus i, .alert.alert-white .close:hover i {
    opacity: 1;
    color: #3F4254; }

.alert.alert-custom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 1.5rem 2rem; }
.alert.alert-custom .alert-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1.25rem 0 0; }
.alert.alert-custom .alert-icon i {
    font-size: 2.4rem; }
.alert.alert-custom .alert-icon.alert-icon-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: stretch;
    align-content: stretch; }
.alert.alert-custom .alert-text {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
.alert.alert-custom .alert-close {
    padding: 0 0 0 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    line-height: 0; }
.alert.alert-custom .alert-close .close {
    text-shadow: none !important;
    cursor: pointer;
    line-height: 0; }
.alert.alert-custom .alert-close i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    font-size: 1rem; }
.alert.alert-custom .alert-close i.ki {
    font-size: 0.75rem; }
.alert.alert-custom .alert-close:focus i, .alert.alert-custom .alert-close:hover i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.alert.alert-custom.alert-outline-2x {
    border-width: 2px; }
.alert.alert-custom.alert-white {
    background-color: white;
    border-color: white; }
.alert.alert-custom.alert-white .alert-icon i {
    color: #3F4254; }
.alert.alert-custom.alert-white .alert-text {
    color: #3F4254; }
.alert.alert-custom.alert-white .alert-close i {
    color: #3F4254; }
.alert.alert-custom.alert-default {
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.alert.alert-custom.alert-shadow {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05); }
.alert.alert-custom.alert-primary {
    background-color: var(--success);
    border-color: var(--success); }
.alert.alert-custom.alert-primary .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-primary .alert-text {
    color: white; }
.alert.alert-custom.alert-primary .alert-close i {
    color: white; }
.alert.alert-custom.alert-primary .alert-close:focus, .alert.alert-custom.alert-primary .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-primary {
    background-color: #C9F7F5;
    border-color: transparent; }
.alert.alert-custom.alert-light-primary .alert-icon i {
    color: var(--success); }
.alert.alert-custom.alert-light-primary .alert-text {
    color: var(--success); }
.alert.alert-custom.alert-light-primary .alert-close i {
    color: var(--success); }
.alert.alert-custom.alert-light-primary .alert-close:focus, .alert.alert-custom.alert-light-primary .alert-close:hover {
    color: var(--hover-button); }
.alert.alert-custom.alert-light-primary.alert-notice {
    border: 0;
    border-left: 4px solid var(--success);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-primary {
    background-color: transparent;
    border-color: var(--success); }
.alert.alert-custom.alert-outline-primary .alert-icon i {
    color: var(--success); }
.alert.alert-custom.alert-outline-primary .alert-text {
    color: var(--success); }
.alert.alert-custom.alert-outline-primary .alert-close i {
    opacity: 0.8;
    color: var(--success); }
.alert.alert-custom.alert-outline-primary .alert-close:focus i, .alert.alert-custom.alert-outline-primary .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-secondary {
    background-color: #E4E6EF;
    border-color: #E4E6EF; }
.alert.alert-custom.alert-secondary .alert-icon i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-custom.alert-secondary .alert-text {
    color: #3F4254; }
.alert.alert-custom.alert-secondary .alert-close i {
    color: #3F4254; }
.alert.alert-custom.alert-secondary .alert-close:focus, .alert.alert-custom.alert-secondary .alert-close:hover {
    opacity: 1;
    color: #3F4254; }
.alert.alert-custom.alert-light-secondary {
    background-color: #EBEDF3;
    border-color: transparent; }
.alert.alert-custom.alert-light-secondary .alert-icon i {
    color: #E4E6EF; }
.alert.alert-custom.alert-light-secondary .alert-text {
    color: #E4E6EF; }
.alert.alert-custom.alert-light-secondary .alert-close i {
    color: #E4E6EF; }
.alert.alert-custom.alert-light-secondary .alert-close:focus, .alert.alert-custom.alert-light-secondary .alert-close:hover {
    color: #d7dae7; }
.alert.alert-custom.alert-light-secondary.alert-notice {
    border: 0;
    border-left: 4px solid #E4E6EF;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-secondary {
    background-color: transparent;
    border-color: #E4E6EF; }
.alert.alert-custom.alert-outline-secondary .alert-icon i {
    color: #3F4254; }
.alert.alert-custom.alert-outline-secondary .alert-text {
    color: #3F4254; }
.alert.alert-custom.alert-outline-secondary .alert-close i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-custom.alert-outline-secondary .alert-close:focus i, .alert.alert-custom.alert-outline-secondary .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-success {
    background-color: var(--success);
    border-color: var(--success); }
.alert.alert-custom.alert-success .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-success .alert-text {
    color: white; }
.alert.alert-custom.alert-success .alert-close i {
    color: white; }
.alert.alert-custom.alert-success .alert-close:focus, .alert.alert-custom.alert-success .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-success {
    background-color: #E1F0FF;
    border-color: transparent; }
.alert.alert-custom.alert-light-success .alert-icon i {
    color: var(--success); }
.alert.alert-custom.alert-light-success .alert-text {
    color: var(--success); }
.alert.alert-custom.alert-light-success .alert-close i {
    color: var(--success); }
.alert.alert-custom.alert-light-success .alert-close:focus, .alert.alert-custom.alert-light-success .alert-close:hover {
    color: #187DE4; }
.alert.alert-custom.alert-light-success.alert-notice {
    border: 0;
    border-left: 4px solid var(--success);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-success {
    background-color: transparent;
    border-color: var(--success); }
.alert.alert-custom.alert-outline-success .alert-icon i {
    color: var(--success); }
.alert.alert-custom.alert-outline-success .alert-text {
    color: var(--success); }
.alert.alert-custom.alert-outline-success .alert-close i {
    opacity: 0.8;
    color: var(--success); }
.alert.alert-custom.alert-outline-success .alert-close:focus i, .alert.alert-custom.alert-outline-success .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-info {
    background-color: #6993FF;
    border-color: #6993FF; }
.alert.alert-custom.alert-info .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-info .alert-text {
    color: white; }
.alert.alert-custom.alert-info .alert-close i {
    color: white; }
.alert.alert-custom.alert-info .alert-close:focus, .alert.alert-custom.alert-info .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-info {
    background-color: #E1E9FF;
    border-color: transparent; }
.alert.alert-custom.alert-light-info .alert-icon i {
    color: #6993FF; }
.alert.alert-custom.alert-light-info .alert-text {
    color: #6993FF; }
.alert.alert-custom.alert-light-info .alert-close i {
    color: #6993FF; }
.alert.alert-custom.alert-light-info .alert-close:focus, .alert.alert-custom.alert-light-info .alert-close:hover {
    color: #4A7DFF; }
.alert.alert-custom.alert-light-info.alert-notice {
    border: 0;
    border-left: 4px solid #6993FF;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-info {
    background-color: transparent;
    border-color: #6993FF; }
.alert.alert-custom.alert-outline-info .alert-icon i {
    color: #6993FF; }
.alert.alert-custom.alert-outline-info .alert-text {
    color: #6993FF; }
.alert.alert-custom.alert-outline-info .alert-close i {
    opacity: 0.8;
    color: #6993FF; }
.alert.alert-custom.alert-outline-info .alert-close:focus i, .alert.alert-custom.alert-outline-info .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-warning {
    background-color: #FFA800;
    border-color: #FFA800; }
.alert.alert-custom.alert-warning .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-warning .alert-text {
    color: white; }
.alert.alert-custom.alert-warning .alert-close i {
    color: white; }
.alert.alert-custom.alert-warning .alert-close:focus, .alert.alert-custom.alert-warning .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-warning {
    background-color: #FFF4DE;
    border-color: transparent; }
.alert.alert-custom.alert-light-warning .alert-icon i {
    color: #FFA800; }
.alert.alert-custom.alert-light-warning .alert-text {
    color: #FFA800; }
.alert.alert-custom.alert-light-warning .alert-close i {
    color: #FFA800; }
.alert.alert-custom.alert-light-warning .alert-close:focus, .alert.alert-custom.alert-light-warning .alert-close:hover {
    color: #EE9D01; }
.alert.alert-custom.alert-light-warning.alert-notice {
    border: 0;
    border-left: 4px solid #FFA800;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-warning {
    background-color: transparent;
    border-color: #FFA800; }
.alert.alert-custom.alert-outline-warning .alert-icon i {
    color: #FFA800; }
.alert.alert-custom.alert-outline-warning .alert-text {
    color: #FFA800; }
.alert.alert-custom.alert-outline-warning .alert-close i {
    opacity: 0.8;
    color: #FFA800; }
.alert.alert-custom.alert-outline-warning .alert-close:focus i, .alert.alert-custom.alert-outline-warning .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-danger {
    background-color: #F64E60;
    border-color: #F64E60; }
.alert.alert-custom.alert-danger .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-danger .alert-text {
    color: white; }
.alert.alert-custom.alert-danger .alert-close i {
    color: white; }
.alert.alert-custom.alert-danger .alert-close:focus, .alert.alert-custom.alert-danger .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-danger {
    background-color: #FFE2E5;
    border-color: transparent; }
.alert.alert-custom.alert-light-danger .alert-icon i {
    color: #F64E60; }
.alert.alert-custom.alert-light-danger .alert-text {
    color: #F64E60; }
.alert.alert-custom.alert-light-danger .alert-close i {
    color: #F64E60; }
.alert.alert-custom.alert-light-danger .alert-close:focus, .alert.alert-custom.alert-light-danger .alert-close:hover {
    color: #EE2D41; }
.alert.alert-custom.alert-light-danger.alert-notice {
    border: 0;
    border-left: 4px solid #F64E60;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-danger {
    background-color: transparent;
    border-color: #F64E60; }
.alert.alert-custom.alert-outline-danger .alert-icon i {
    color: #F64E60; }
.alert.alert-custom.alert-outline-danger .alert-text {
    color: #F64E60; }
.alert.alert-custom.alert-outline-danger .alert-close i {
    opacity: 0.8;
    color: #F64E60; }
.alert.alert-custom.alert-outline-danger .alert-close:focus i, .alert.alert-custom.alert-outline-danger .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-light {
    background-color: var(--elem-bg);
    border-color: var(--elem-bg); }
.alert.alert-custom.alert-light .alert-icon i {
    opacity: 0.8;
    color: #7E8299; }
.alert.alert-custom.alert-light .alert-text {
    color: #7E8299; }
.alert.alert-custom.alert-light .alert-close i {
    color: #7E8299; }
.alert.alert-custom.alert-light .alert-close:focus, .alert.alert-custom.alert-light .alert-close:hover {
    opacity: 1;
    color: #7E8299; }
.alert.alert-custom.alert-light-light {
    background-color: var(--elem-bg);
    border-color: transparent; }
.alert.alert-custom.alert-light-light .alert-icon i {
    color: #F3F6F9; }
.alert.alert-custom.alert-light-light .alert-text {
    color: #F3F6F9; }
.alert.alert-custom.alert-light-light .alert-close i {
    color: #F3F6F9; }
.alert.alert-custom.alert-light-light .alert-close:focus, .alert.alert-custom.alert-light-light .alert-close:hover {
    color: #E4E6EF; }
.alert.alert-custom.alert-light-light.alert-notice {
    border: 0;
    border-left: 4px solid var(--light-primary);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-light {
    background-color: transparent;
    border-color: var(--light-primary); }
.alert.alert-custom.alert-outline-light .alert-icon i {
    color: #3F4254; }
.alert.alert-custom.alert-outline-light .alert-text {
    color: #3F4254; }
.alert.alert-custom.alert-outline-light .alert-close i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-custom.alert-outline-light .alert-close:focus i, .alert.alert-custom.alert-outline-light .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-dark {
    background-color: #181C32;
    border-color: #181C32; }
.alert.alert-custom.alert-dark .alert-icon i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-dark .alert-text {
    color: white; }
.alert.alert-custom.alert-dark .alert-close i {
    color: white; }
.alert.alert-custom.alert-dark .alert-close:focus, .alert.alert-custom.alert-dark .alert-close:hover {
    opacity: 1;
    color: white; }
.alert.alert-custom.alert-light-dark {
    background-color: #D1D3E0;
    border-color: transparent; }
.alert.alert-custom.alert-light-dark .alert-icon i {
    color: #181C32; }
.alert.alert-custom.alert-light-dark .alert-text {
    color: #181C32; }
.alert.alert-custom.alert-light-dark .alert-close i {
    color: #181C32; }
.alert.alert-custom.alert-light-dark .alert-close:focus, .alert.alert-custom.alert-light-dark .alert-close:hover {
    color: #131628; }
.alert.alert-custom.alert-light-dark.alert-notice {
    border: 0;
    border-left: 4px solid #181C32;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-dark {
    background-color: transparent;
    border-color: #181C32; }
.alert.alert-custom.alert-outline-dark .alert-icon i {
    color: #181C32; }
.alert.alert-custom.alert-outline-dark .alert-text {
    color: #181C32; }
.alert.alert-custom.alert-outline-dark .alert-close i {
    opacity: 0.8;
    color: #181C32; }
.alert.alert-custom.alert-outline-dark .alert-close:focus i, .alert.alert-custom.alert-outline-dark .alert-close:hover i {
    opacity: 1; }
.alert.alert-custom.alert-white {
    background-color: white;
    border-color: white; }
.alert.alert-custom.alert-white .alert-icon i {
    opacity: 0.8;
    color: #3F4254; }
.alert.alert-custom.alert-white .alert-text {
    color: #3F4254; }
.alert.alert-custom.alert-white .alert-close i {
    color: #3F4254; }
.alert.alert-custom.alert-white .alert-close:focus, .alert.alert-custom.alert-white .alert-close:hover {
    opacity: 1;
    color: #3F4254; }
.alert.alert-custom.alert-light-white {
    background-color: white;
    border-color: transparent; }
.alert.alert-custom.alert-light-white .alert-icon i {
    color: white; }
.alert.alert-custom.alert-light-white .alert-text {
    color: white; }
.alert.alert-custom.alert-light-white .alert-close i {
    color: white; }
.alert.alert-custom.alert-light-white .alert-close:focus, .alert.alert-custom.alert-light-white .alert-close:hover {
    color: #F3F6F9; }
.alert.alert-custom.alert-light-white.alert-notice {
    border: 0;
    border-left: 4px solid white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
.alert.alert-custom.alert-outline-white {
    background-color: transparent;
    border-color: white; }
.alert.alert-custom.alert-outline-white .alert-icon i {
    color: white; }
.alert.alert-custom.alert-outline-white .alert-text {
    color: white; }
.alert.alert-custom.alert-outline-white .alert-close i {
    opacity: 0.8;
    color: white; }
.alert.alert-custom.alert-outline-white .alert-close:focus i, .alert.alert-custom.alert-outline-white .alert-close:hover i {
    opacity: 1; }
