@charset "UTF-8";
.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }

.nav-link {
    display: block;
    padding: 0.5rem 1rem; }
.nav-link:hover, .nav-link:focus {
    text-decoration: none; }
.nav-link.disabled {
    color: #7E8299;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
    border-bottom: 1px solid #E4E6EF; }
.nav-tabs .nav-item {
    margin-bottom: -1px; }
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem; }
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #EBEDF3 #EBEDF3 #E4E6EF; }
.nav-tabs .nav-link.disabled {
    color: #7E8299;
    background-color: transparent;
    border-color: transparent; }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #5E6278;
    background-color: white;
    border-color: #E4E6EF #E4E6EF white; }
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
    border-radius: 0.42rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: white;
    background-color: var(--success); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center; }

.tab-content > .tab-pane {
    display: none; }

.tab-content > .active {
    display: block; }

.navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
    display: inline-block;
    padding-top: 0.44rem;
    padding-bottom: 0.44rem;
    margin-right: 1rem;
    font-size: 1.08rem;
    line-height: inherit;
    white-space: nowrap; }
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.42rem; }

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--success);
    background-color: white;
    border: 1px solid #E4E6EF; }
.page-link:hover {
    z-index: 2;
    color: #12827c;
    text-decoration: none;
    background-color: #EBEDF3;
    border-color: #E4E6EF; }
.page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem; }

.page-item:last-child .page-link {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }

.page-item.active .page-link {
    z-index: 3;
    color: white;
    background-color: var(--success);
    border-color: var(--success); }

.page-item.disabled .page-link {
    color: #7E8299;
    pointer-events: none;
    cursor: auto;
    background-color: white;
    border-color: #EBEDF3; }

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.08rem;
    line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.85rem;
    border-bottom-left-radius: 0.85rem; }

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.85rem;
    border-bottom-right-radius: 0.85rem; }

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.925rem;
    line-height: 1.35; }

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.28rem;
    border-bottom-left-radius: 0.28rem; }

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.28rem;
    border-bottom-right-radius: 0.28rem; }

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
.navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.08rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.42rem; }
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
        display: none; } }

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
        display: none; } }

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
        display: none; } }

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
        display: none; } }

@media (max-width: 1399.98px) {
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
        padding-right: 0;
        padding-left: 0; } }

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
        display: none; } }

.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
.navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
    color: white; }
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: white; }

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-text a {
    color: white; }
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: white; }

.nav .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    padding: 0.75rem 1.5rem;
    color: #7E8299; }
.nav .nav-link .nav-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 1rem;
    color: #7E8299;
    font-weight: 400;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav .nav-link .nav-icon {
    line-height: 0;
    color: #7E8299;
    width: 2rem; }

.dropdown-item .nav-link:not(.active) .nav-icon {
    line-height: 0;
    color: #7E8299;
    width: 2rem; }
.nav .nav-link .nav-icon.active svg g [fill] {
    color: var(--success);
}
.nav .nav-link .nav-icon:before {
    line-height: 0; }
.nav .nav-link .nav-icon i {
    color: #B5B5C3;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    font-size: 1.25rem; }
.nav .nav-link .nav-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3; }
.nav .nav-link .nav-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav .nav-link .label {
    margin-left: 0.25rem; }
.nav .nav-link.disabled {
    opacity: 0.6; }

.nav .show > .nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success); }
.nav .show > .nav-link .nav-text,
.nav .nav-link:hover:not(.disabled) .nav-text,
.nav .nav-link.active .nav-text {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success); }
.nav .show > .nav-link .nav-icon i,
.nav .nav-link:hover:not(.disabled) .nav-icon i,
.nav .nav-link.active .nav-icon i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success); }
.nav .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav .show > .nav-link:after,
.nav .nav-link:hover:not(.disabled):after,
.nav .nav-link.active:after {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: var(--success); }

.nav.nav-bold .nav-item .nav-link {
    font-weight: 500; }
.nav.nav-bold .nav-item .nav-link .nav-text {
    font-weight: 500; }

.nav.nav-bolder .nav-item .nav-link {
    font-weight: 600; }
.nav.nav-bolder .nav-item .nav-link .nav-text {
    font-weight: 600; }

.nav.nav-boldest .nav-item .nav-link {
    font-weight: 700; }
.nav.nav-boldest .nav-item .nav-link .nav-text {
    font-weight: 700; }

.nav.nav-tabs .nav-item {
    margin-right: 0.25rem; }
.nav.nav-tabs .nav-item:last-child {
    margin-right: 0; }

.nav.nav-tabs .nav-link .nav-text {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0; }

.nav.nav-tabs.nav-tabs-line .nav-item {
    margin: 0 0 -1px 0; }

.nav.nav-tabs.nav-tabs-line .nav-link {
    border: 0;
    border-bottom: 1px solid transparent;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    padding: 0.85rem 0;
    margin: 0 1rem; }
.nav-item:first-child .nav.nav-tabs.nav-tabs-line .nav-link {
    margin-left: 0; }
.nav-item:last-child .nav.nav-tabs.nav-tabs-line .nav-link {
    margin-right: 0; }

.nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
    margin-left: 0; }

.nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
    margin-right: 0; }

.nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show > .nav-link {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--success);
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x {
    border-bottom-width: 2px; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x .nav-item {
    margin-bottom: -2px; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x .nav-link {
    border-bottom: 2px solid transparent; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-2x .show > .nav-link {
    border-bottom: 2px solid var(--success); }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x {
    border-bottom-width: 3px; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x .nav-item {
    margin-bottom: -3px; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x .nav-link {
    border-bottom: 3px solid transparent; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-3x .show > .nav-link {
    border-bottom: 3px solid var(--success); }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white {
    border-bottom-color: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link {
    color: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link .nav-text {
    color: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link .nav-icon i {
    color: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link .nav-arrow {
    color: rgba(255, 255, 255, 0.7); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link {
    border-bottom-color: white;
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link .nav-text {
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link .nav-icon i {
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-transparent-white .show > .nav-link .nav-arrow {
    color: white; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary {
    border-bottom-color: #C9F7F5 !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link {
    border-bottom-color: var(--success);
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link .nav-text {
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link .nav-icon i {
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-primary .show > .nav-link .nav-arrow {
    color: var(--success); }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-primary .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-primary .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-primary .show > .nav-link {
    border-bottom-color: var(--success) !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary {
    border-bottom-color: #EBEDF3 !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link {
    border-bottom-color: #E4E6EF;
    color: #E4E6EF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link .nav-text {
    color: #E4E6EF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link .nav-icon i {
    color: #E4E6EF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-secondary .show > .nav-link .nav-arrow {
    color: #E4E6EF; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-secondary .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-secondary .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-secondary .show > .nav-link {
    border-bottom-color: #E4E6EF !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success {
    border-bottom-color: #E1F0FF !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link {
    border-bottom-color: var(--success);
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link .nav-text {
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link .nav-icon i {
    color: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-success .show > .nav-link .nav-arrow {
    color: var(--success); }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .show > .nav-link {
    border-bottom-color: var(--success) !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info {
    border-bottom-color: #E1E9FF !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link {
    border-bottom-color: #6993FF;
    color: #6993FF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link .nav-text {
    color: #6993FF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link .nav-icon i {
    color: #6993FF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-info .show > .nav-link .nav-arrow {
    color: #6993FF; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-info .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-info .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-info .show > .nav-link {
    border-bottom-color: #6993FF !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning {
    border-bottom-color: #FFF4DE !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link {
    border-bottom-color: #FFA800;
    color: #FFA800; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link .nav-text {
    color: #FFA800; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link .nav-icon i {
    color: #FFA800; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-warning .show > .nav-link .nav-arrow {
    color: #FFA800; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-warning .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-warning .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-warning .show > .nav-link {
    border-bottom-color: #FFA800 !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger {
    border-bottom-color: #FFE2E5 !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link {
    border-bottom-color: #F64E60;
    color: #F64E60; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link .nav-text {
    color: #F64E60; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link .nav-icon i {
    color: #F64E60; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-danger .show > .nav-link .nav-arrow {
    color: #F64E60; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-danger .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-danger .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-danger .show > .nav-link {
    border-bottom-color: #F64E60 !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light {
    border-bottom-color: var(--light-primary) !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link {
    border-bottom-color: var(--light-primary);
    color: #F3F6F9; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link .nav-text {
    color: #F3F6F9; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link .nav-icon i {
    color: #F3F6F9; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-light .show > .nav-link .nav-arrow {
    color: #F3F6F9; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-light .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-light .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-light .show > .nav-link {
    border-bottom-color: var(--light-primary) !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark {
    border-bottom-color: #D1D3E0 !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link {
    border-bottom-color: #181C32;
    color: #181C32; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link .nav-text {
    color: #181C32; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link .nav-icon i {
    color: #181C32; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-dark .show > .nav-link .nav-arrow {
    color: #181C32; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-dark .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-dark .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-dark .show > .nav-link {
    border-bottom-color: #181C32 !important; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white {
    border-bottom-color: white !important; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link {
    border-bottom-color: white;
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link .nav-text {
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link .nav-icon i {
    color: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link:hover:not(.disabled) .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .nav-link.active .nav-arrow,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-white .show > .nav-link .nav-arrow {
    color: white; }

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-white .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-white .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-white .show > .nav-link {
    border-bottom-color: white !important; }

.nav.nav-pills.nav-fill .nav-item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

.nav.nav-pills.nav-fill .nav-link {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
.nav.nav-pills.nav-fill .nav-link .nav-text {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0; }
.nav.nav-pills.nav-fill .nav-link .nav-icon {
    margin-right: 0.25rem; }

.nav.nav-pills.nav-group .nav-item {
    padding: 0;
    margin: 0; }
.nav.nav-pills.nav-group .nav-item .nav-link {
    border-radius: 0; }
.nav.nav-pills.nav-group .nav-item:first-child .nav-link {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem; }
.nav.nav-pills.nav-group .nav-item:last-child .nav-link {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem; }

.nav.nav-pills.nav-group.nav-rounded:first-child .nav-link {
    border-top-left-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem; }

.nav.nav-pills.nav-group.nav-rounded:last-child .nav-link {
    border-top-right-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }

.nav.nav-pills .nav-item {
    margin-right: 0.25rem; }

.nav.nav-pills .nav-link {
    color: #B5B5C3;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    position: relative; }
.nav.nav-pills .nav-link .nav-text {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav.nav-pills .nav-link .nav-icon i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav.nav-pills .nav-link:after {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }

.nav.nav-pills .show > .nav-link,
.nav.nav-pills .nav-link.active {
    color: white;
    background-color: var(--success);
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav.nav-pills .show > .nav-link .nav-text,
.nav.nav-pills .nav-link.active .nav-text {
    color: white;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav.nav-pills .show > .nav-link .nav-icon i,
.nav.nav-pills .nav-link.active .nav-icon i {
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease; }
.nav.nav-pills .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: rgba(255, 255, 255, 0.8); }
.nav.nav-pills .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills .show > .nav-link:after,
.nav.nav-pills .nav-link.active:after {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: rgba(255, 255, 255, 0.8); }

.nav.nav-pills.nav-primary .show > .nav-link,
.nav.nav-pills.nav-primary .nav-link:hover:not(.active) {
    color: var(--success);
    background-color: transparent; }
.nav.nav-pills.nav-primary .show > .nav-link .nav-text,
.nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-primary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-primary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-primary .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-primary .show > .nav-link:after,
.nav.nav-pills.nav-primary .nav-link:hover:not(.active):after {
    color: var(--success); }

.nav.nav-pills.nav-primary .nav-link.active {
    color: white;
    background-color: var(--success); }
.nav.nav-pills.nav-primary .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-primary .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-primary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-primary .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-primary .show > .nav-link,
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active) {
    color: var(--success);
    background-color: transparent; }
.nav.nav-pills.nav-light-primary .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active) .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-light-primary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active) .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-light-primary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-light-primary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-primary .show > .nav-link:after,
.nav.nav-pills.nav-light-primary .nav-link:hover:not(.active):after {
    color: var(--success); }

.nav.nav-pills.nav-light-primary .nav-link.active {
    color: var(--success);
    background-color: rgba(27, 197, 189, 0.1); }
.nav.nav-pills.nav-light-primary .nav-link.active .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-light-primary .nav-link.active .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-light-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-light-primary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-primary .nav-link.active:after {
    color: var(--success); }

.nav.nav-pills.nav-group.nav-primary .nav-link {
    color: var(--success);
    background-color: #C9F7F5;
    border: 0; }
.nav.nav-pills.nav-group.nav-primary .nav-link .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-group.nav-primary .nav-link .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-group.nav-primary .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-group.nav-primary .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-primary .nav-link:after {
    color: var(--success); }

.nav.nav-pills.nav-group.nav-primary .show > .nav-link,
.nav.nav-pills.nav-group.nav-primary .nav-link:hover,
.nav.nav-pills.nav-group.nav-primary .nav-link.active {
    color: white;
    background-color: var(--success); }
.nav.nav-pills.nav-group.nav-primary .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-primary .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-primary .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-primary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-primary .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-primary .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-primary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-primary .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-primary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-primary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-primary .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-primary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-primary .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-primary .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-primary .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-primary .nav-link:hover {
    background-color: var(--hover-button); }

.nav.nav-pills.nav-secondary .show > .nav-link,
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) {
    color: #E4E6EF;
    background-color: transparent; }
.nav.nav-pills.nav-secondary .show > .nav-link .nav-text,
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) .nav-text {
    color: #E4E6EF; }
.nav.nav-pills.nav-secondary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) .nav-icon i {
    color: #E4E6EF; }
.nav.nav-pills.nav-secondary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.nav.nav-pills.nav-secondary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-secondary .show > .nav-link:after,
.nav.nav-pills.nav-secondary .nav-link:hover:not(.active):after {
    color: #E4E6EF; }

.nav.nav-pills.nav-secondary .nav-link.active {
    color: #3F4254;
    background-color: #E4E6EF; }
.nav.nav-pills.nav-secondary .nav-link.active .nav-text {
    color: #3F4254; }
.nav.nav-pills.nav-secondary .nav-link.active .nav-icon i {
    color: #3F4254; }
.nav.nav-pills.nav-secondary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.nav.nav-pills.nav-secondary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-secondary .nav-link.active:after {
    color: #3F4254; }

.nav.nav-pills.nav-light-secondary .show > .nav-link,
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active) {
    color: #E4E6EF;
    background-color: transparent; }
.nav.nav-pills.nav-light-secondary .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active) .nav-text {
    color: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active) .nav-icon i {
    color: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-secondary .show > .nav-link:after,
.nav.nav-pills.nav-light-secondary .nav-link:hover:not(.active):after {
    color: #E4E6EF; }

.nav.nav-pills.nav-light-secondary .nav-link.active {
    color: #E4E6EF;
    background-color: rgba(228, 230, 239, 0.1); }
.nav.nav-pills.nav-light-secondary .nav-link.active .nav-text {
    color: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .nav-link.active .nav-icon i {
    color: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.nav.nav-pills.nav-light-secondary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-secondary .nav-link.active:after {
    color: #E4E6EF; }

.nav.nav-pills.nav-group.nav-secondary .nav-link {
    color: #E4E6EF;
    background-color: #EBEDF3;
    border: 0; }
.nav.nav-pills.nav-group.nav-secondary .nav-link .nav-text {
    color: #E4E6EF; }
.nav.nav-pills.nav-group.nav-secondary .nav-link .nav-icon i {
    color: #E4E6EF; }
.nav.nav-pills.nav-group.nav-secondary .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #E4E6EF; }
.nav.nav-pills.nav-group.nav-secondary .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-secondary .nav-link:after {
    color: #E4E6EF; }

.nav.nav-pills.nav-group.nav-secondary .show > .nav-link,
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover,
.nav.nav-pills.nav-group.nav-secondary .nav-link.active {
    color: #3F4254;
    background-color: #E4E6EF; }
.nav.nav-pills.nav-group.nav-secondary .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-secondary .nav-link.active .nav-text {
    color: #3F4254; }
.nav.nav-pills.nav-group.nav-secondary .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-secondary .nav-link.active .nav-icon i {
    color: #3F4254; }
.nav.nav-pills.nav-group.nav-secondary .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-secondary .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.nav.nav-pills.nav-group.nav-secondary .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-secondary .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-secondary .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-secondary .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-secondary .nav-link.active:after {
    color: #3F4254; }

.nav.nav-pills.nav-group.nav-secondary .nav-link:hover {
    background-color: #d7dae7; }

.nav.nav-pills.nav-success .show > .nav-link,
.nav.nav-pills.nav-success .nav-link:hover:not(.active) {
    color: var(--success);
    background-color: transparent; }
.nav.nav-pills.nav-success .show > .nav-link .nav-text,
.nav.nav-pills.nav-success .nav-link:hover:not(.active) .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-success .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-success .nav-link:hover:not(.active) .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-success .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-success .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-success .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-success .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-success .show > .nav-link:after,
.nav.nav-pills.nav-success .nav-link:hover:not(.active):after {
    color: var(--success); }

.nav.nav-pills.nav-success .nav-link.active {
    color: white;
    background-color: var(--success); }
.nav.nav-pills.nav-success .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-success .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-success .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-success .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-success .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-success .show > .nav-link,
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active) {
    color: var(--success);
    background-color: transparent; }
.nav.nav-pills.nav-light-success .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active) .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-light-success .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active) .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-light-success .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-light-success .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-success .show > .nav-link:after,
.nav.nav-pills.nav-light-success .nav-link:hover:not(.active):after {
    color: var(--success); }

.nav.nav-pills.nav-light-success .nav-link.active {
    color: var(--success);
    background-color: rgba(54, 153, 255, 0.1); }
.nav.nav-pills.nav-light-success .nav-link.active .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-light-success .nav-link.active .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-light-success .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-light-success .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-success .nav-link.active:after {
    color: var(--success); }

.nav.nav-pills.nav-group.nav-success .nav-link {
    color: var(--success);
    background-color: #E1F0FF;
    border: 0; }
.nav.nav-pills.nav-group.nav-success .nav-link .nav-text {
    color: var(--success); }
.nav.nav-pills.nav-group.nav-success .nav-link .nav-icon i {
    color: var(--success); }
.nav.nav-pills.nav-group.nav-success .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: var(--success); }
.nav.nav-pills.nav-group.nav-success .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-success .nav-link:after {
    color: var(--success); }

.nav.nav-pills.nav-group.nav-success .show > .nav-link,
.nav.nav-pills.nav-group.nav-success .nav-link:hover,
.nav.nav-pills.nav-group.nav-success .nav-link.active {
    color: white;
    background-color: var(--success); }
.nav.nav-pills.nav-group.nav-success .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-success .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-success .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-success .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-success .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-success .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-success .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-success .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-success .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-success .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-success .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-success .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-success .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-success .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-success .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-success .nav-link:hover {
    background-color: #187DE4; }

.nav.nav-pills.nav-info .show > .nav-link,
.nav.nav-pills.nav-info .nav-link:hover:not(.active) {
    color: #6993FF;
    background-color: transparent; }
.nav.nav-pills.nav-info .show > .nav-link .nav-text,
.nav.nav-pills.nav-info .nav-link:hover:not(.active) .nav-text {
    color: #6993FF; }
.nav.nav-pills.nav-info .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-info .nav-link:hover:not(.active) .nav-icon i {
    color: #6993FF; }
.nav.nav-pills.nav-info .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-info .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.nav.nav-pills.nav-info .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-info .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-info .show > .nav-link:after,
.nav.nav-pills.nav-info .nav-link:hover:not(.active):after {
    color: #6993FF; }

.nav.nav-pills.nav-info .nav-link.active {
    color: white;
    background-color: #6993FF; }
.nav.nav-pills.nav-info .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-info .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-info .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-info .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-info .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-info .show > .nav-link,
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active) {
    color: #6993FF;
    background-color: transparent; }
.nav.nav-pills.nav-light-info .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active) .nav-text {
    color: #6993FF; }
.nav.nav-pills.nav-light-info .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active) .nav-icon i {
    color: #6993FF; }
.nav.nav-pills.nav-light-info .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.nav.nav-pills.nav-light-info .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-info .show > .nav-link:after,
.nav.nav-pills.nav-light-info .nav-link:hover:not(.active):after {
    color: #6993FF; }

.nav.nav-pills.nav-light-info .nav-link.active {
    color: #6993FF;
    background-color: rgba(105, 147, 255, 0.1); }
.nav.nav-pills.nav-light-info .nav-link.active .nav-text {
    color: #6993FF; }
.nav.nav-pills.nav-light-info .nav-link.active .nav-icon i {
    color: #6993FF; }
.nav.nav-pills.nav-light-info .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.nav.nav-pills.nav-light-info .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-info .nav-link.active:after {
    color: #6993FF; }

.nav.nav-pills.nav-group.nav-info .nav-link {
    color: #6993FF;
    background-color: #E1E9FF;
    border: 0; }
.nav.nav-pills.nav-group.nav-info .nav-link .nav-text {
    color: #6993FF; }
.nav.nav-pills.nav-group.nav-info .nav-link .nav-icon i {
    color: #6993FF; }
.nav.nav-pills.nav-group.nav-info .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #6993FF; }
.nav.nav-pills.nav-group.nav-info .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-info .nav-link:after {
    color: #6993FF; }

.nav.nav-pills.nav-group.nav-info .show > .nav-link,
.nav.nav-pills.nav-group.nav-info .nav-link:hover,
.nav.nav-pills.nav-group.nav-info .nav-link.active {
    color: white;
    background-color: #6993FF; }
.nav.nav-pills.nav-group.nav-info .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-info .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-info .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-info .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-info .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-info .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-info .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-info .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-info .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-info .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-info .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-info .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-info .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-info .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-info .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-info .nav-link:hover {
    background-color: #4A7DFF; }

.nav.nav-pills.nav-warning .show > .nav-link,
.nav.nav-pills.nav-warning .nav-link:hover:not(.active) {
    color: #FFA800;
    background-color: transparent; }
.nav.nav-pills.nav-warning .show > .nav-link .nav-text,
.nav.nav-pills.nav-warning .nav-link:hover:not(.active) .nav-text {
    color: #FFA800; }
.nav.nav-pills.nav-warning .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-warning .nav-link:hover:not(.active) .nav-icon i {
    color: #FFA800; }
.nav.nav-pills.nav-warning .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-warning .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.nav.nav-pills.nav-warning .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-warning .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-warning .show > .nav-link:after,
.nav.nav-pills.nav-warning .nav-link:hover:not(.active):after {
    color: #FFA800; }

.nav.nav-pills.nav-warning .nav-link.active {
    color: white;
    background-color: #FFA800; }
.nav.nav-pills.nav-warning .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-warning .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-warning .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-warning .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-warning .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-warning .show > .nav-link,
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active) {
    color: #FFA800;
    background-color: transparent; }
.nav.nav-pills.nav-light-warning .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active) .nav-text {
    color: #FFA800; }
.nav.nav-pills.nav-light-warning .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active) .nav-icon i {
    color: #FFA800; }
.nav.nav-pills.nav-light-warning .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.nav.nav-pills.nav-light-warning .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-warning .show > .nav-link:after,
.nav.nav-pills.nav-light-warning .nav-link:hover:not(.active):after {
    color: #FFA800; }

.nav.nav-pills.nav-light-warning .nav-link.active {
    color: #FFA800;
    background-color: rgba(255, 168, 0, 0.1); }
.nav.nav-pills.nav-light-warning .nav-link.active .nav-text {
    color: #FFA800; }
.nav.nav-pills.nav-light-warning .nav-link.active .nav-icon i {
    color: #FFA800; }
.nav.nav-pills.nav-light-warning .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.nav.nav-pills.nav-light-warning .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-warning .nav-link.active:after {
    color: #FFA800; }

.nav.nav-pills.nav-group.nav-warning .nav-link {
    color: #FFA800;
    background-color: #FFF4DE;
    border: 0; }
.nav.nav-pills.nav-group.nav-warning .nav-link .nav-text {
    color: #FFA800; }
.nav.nav-pills.nav-group.nav-warning .nav-link .nav-icon i {
    color: #FFA800; }
.nav.nav-pills.nav-group.nav-warning .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #FFA800; }
.nav.nav-pills.nav-group.nav-warning .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-warning .nav-link:after {
    color: #FFA800; }

.nav.nav-pills.nav-group.nav-warning .show > .nav-link,
.nav.nav-pills.nav-group.nav-warning .nav-link:hover,
.nav.nav-pills.nav-group.nav-warning .nav-link.active {
    color: white;
    background-color: #FFA800; }
.nav.nav-pills.nav-group.nav-warning .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-warning .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-warning .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-warning .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-warning .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-warning .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-warning .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-warning .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-warning .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-warning .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-warning .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-warning .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-warning .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-warning .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-warning .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-warning .nav-link:hover {
    background-color: #EE9D01; }

.nav.nav-pills.nav-danger .show > .nav-link,
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) {
    color: #F64E60;
    background-color: transparent; }
.nav.nav-pills.nav-danger .show > .nav-link .nav-text,
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) .nav-text {
    color: #F64E60; }
.nav.nav-pills.nav-danger .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) .nav-icon i {
    color: #F64E60; }
.nav.nav-pills.nav-danger .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.nav.nav-pills.nav-danger .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-danger .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-danger .show > .nav-link:after,
.nav.nav-pills.nav-danger .nav-link:hover:not(.active):after {
    color: #F64E60; }

.nav.nav-pills.nav-danger .nav-link.active {
    color: white;
    background-color: #F64E60; }
.nav.nav-pills.nav-danger .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-danger .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-danger .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-danger .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-danger .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-danger .show > .nav-link,
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active) {
    color: #F64E60;
    background-color: transparent; }
.nav.nav-pills.nav-light-danger .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active) .nav-text {
    color: #F64E60; }
.nav.nav-pills.nav-light-danger .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active) .nav-icon i {
    color: #F64E60; }
.nav.nav-pills.nav-light-danger .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.nav.nav-pills.nav-light-danger .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-danger .show > .nav-link:after,
.nav.nav-pills.nav-light-danger .nav-link:hover:not(.active):after {
    color: #F64E60; }

.nav.nav-pills.nav-light-danger .nav-link.active {
    color: #F64E60;
    background-color: rgba(246, 78, 96, 0.1); }
.nav.nav-pills.nav-light-danger .nav-link.active .nav-text {
    color: #F64E60; }
.nav.nav-pills.nav-light-danger .nav-link.active .nav-icon i {
    color: #F64E60; }
.nav.nav-pills.nav-light-danger .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.nav.nav-pills.nav-light-danger .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-danger .nav-link.active:after {
    color: #F64E60; }

.nav.nav-pills.nav-group.nav-danger .nav-link {
    color: #F64E60;
    background-color: #FFE2E5;
    border: 0; }
.nav.nav-pills.nav-group.nav-danger .nav-link .nav-text {
    color: #F64E60; }
.nav.nav-pills.nav-group.nav-danger .nav-link .nav-icon i {
    color: #F64E60; }
.nav.nav-pills.nav-group.nav-danger .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F64E60; }
.nav.nav-pills.nav-group.nav-danger .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-danger .nav-link:after {
    color: #F64E60; }

.nav.nav-pills.nav-group.nav-danger .show > .nav-link,
.nav.nav-pills.nav-group.nav-danger .nav-link:hover,
.nav.nav-pills.nav-group.nav-danger .nav-link.active {
    color: white;
    background-color: #F64E60; }
.nav.nav-pills.nav-group.nav-danger .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-danger .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-danger .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-danger .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-danger .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-danger .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-danger .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-danger .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-danger .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-danger .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-danger .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-danger .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-danger .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-danger .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-danger .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-danger .nav-link:hover {
    background-color: #EE2D41; }

.nav.nav-pills.nav-light .show > .nav-link,
.nav.nav-pills.nav-light .nav-link:hover:not(.active) {
    color: #F3F6F9;
    background-color: transparent; }
.nav.nav-pills.nav-light .show > .nav-link .nav-text,
.nav.nav-pills.nav-light .nav-link:hover:not(.active) .nav-text {
    color: #F3F6F9; }
.nav.nav-pills.nav-light .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light .nav-link:hover:not(.active) .nav-icon i {
    color: #F3F6F9; }
.nav.nav-pills.nav-light .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.nav.nav-pills.nav-light .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light .show > .nav-link:after,
.nav.nav-pills.nav-light .nav-link:hover:not(.active):after {
    color: #F3F6F9; }

.nav.nav-pills.nav-light .nav-link.active {
    color: #7E8299;
    background-color: var(--elem-bg); }
.nav.nav-pills.nav-light .nav-link.active .nav-text {
    color: #7E8299; }
.nav.nav-pills.nav-light .nav-link.active .nav-icon i {
    color: #7E8299; }
.nav.nav-pills.nav-light .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299 !important; }
.nav.nav-pills.nav-light .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light .nav-link.active:after {
    color: #7E8299; }

.nav.nav-pills.nav-light-light .show > .nav-link,
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active) {
    color: #F3F6F9;
    background-color: transparent; }
.nav.nav-pills.nav-light-light .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active) .nav-text {
    color: #F3F6F9; }
.nav.nav-pills.nav-light-light .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active) .nav-icon i {
    color: #F3F6F9; }
.nav.nav-pills.nav-light-light .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.nav.nav-pills.nav-light-light .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-light .show > .nav-link:after,
.nav.nav-pills.nav-light-light .nav-link:hover:not(.active):after {
    color: #F3F6F9; }

.nav.nav-pills.nav-light-light .nav-link.active {
    color: #F3F6F9;
    background-color: rgba(243, 246, 249, 0.1); }
.nav.nav-pills.nav-light-light .nav-link.active .nav-text {
    color: #F3F6F9; }
.nav.nav-pills.nav-light-light .nav-link.active .nav-icon i {
    color: #F3F6F9; }
.nav.nav-pills.nav-light-light .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.nav.nav-pills.nav-light-light .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-light .nav-link.active:after {
    color: #F3F6F9; }

.nav.nav-pills.nav-group.nav-light .nav-link {
    color: #F3F6F9;
    background-color: var(--elem-bg);
    border: 0; }
.nav.nav-pills.nav-group.nav-light .nav-link .nav-text {
    color: #F3F6F9; }
.nav.nav-pills.nav-group.nav-light .nav-link .nav-icon i {
    color: #F3F6F9; }
.nav.nav-pills.nav-group.nav-light .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #F3F6F9; }
.nav.nav-pills.nav-group.nav-light .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-light .nav-link:after {
    color: #F3F6F9; }

.nav.nav-pills.nav-group.nav-light .show > .nav-link,
.nav.nav-pills.nav-group.nav-light .nav-link:hover,
.nav.nav-pills.nav-group.nav-light .nav-link.active {
    color: #7E8299;
    background-color: var(--elem-bg); }
.nav.nav-pills.nav-group.nav-light .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-light .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-light .nav-link.active .nav-text {
    color: #7E8299; }
.nav.nav-pills.nav-group.nav-light .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-light .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-light .nav-link.active .nav-icon i {
    color: #7E8299; }
.nav.nav-pills.nav-group.nav-light .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-light .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-light .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #7E8299; }
.nav.nav-pills.nav-group.nav-light .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-light .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-light .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-light .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-light .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-light .nav-link.active:after {
    color: #7E8299; }

.nav.nav-pills.nav-group.nav-light .nav-link:hover {
    background-color: #E4E6EF; }

.nav.nav-pills.nav-dark .show > .nav-link,
.nav.nav-pills.nav-dark .nav-link:hover:not(.active) {
    color: #181C32;
    background-color: transparent; }
.nav.nav-pills.nav-dark .show > .nav-link .nav-text,
.nav.nav-pills.nav-dark .nav-link:hover:not(.active) .nav-text {
    color: #181C32; }
.nav.nav-pills.nav-dark .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-dark .nav-link:hover:not(.active) .nav-icon i {
    color: #181C32; }
.nav.nav-pills.nav-dark .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-dark .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.nav.nav-pills.nav-dark .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-dark .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-dark .show > .nav-link:after,
.nav.nav-pills.nav-dark .nav-link:hover:not(.active):after {
    color: #181C32; }

.nav.nav-pills.nav-dark .nav-link.active {
    color: white;
    background-color: #181C32; }
.nav.nav-pills.nav-dark .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-dark .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-dark .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white !important; }
.nav.nav-pills.nav-dark .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-dark .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-light-dark .show > .nav-link,
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active) {
    color: #181C32;
    background-color: transparent; }
.nav.nav-pills.nav-light-dark .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active) .nav-text {
    color: #181C32; }
.nav.nav-pills.nav-light-dark .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active) .nav-icon i {
    color: #181C32; }
.nav.nav-pills.nav-light-dark .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.nav.nav-pills.nav-light-dark .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-dark .show > .nav-link:after,
.nav.nav-pills.nav-light-dark .nav-link:hover:not(.active):after {
    color: #181C32; }

.nav.nav-pills.nav-light-dark .nav-link.active {
    color: #181C32;
    background-color: rgba(24, 28, 50, 0.1); }
.nav.nav-pills.nav-light-dark .nav-link.active .nav-text {
    color: #181C32; }
.nav.nav-pills.nav-light-dark .nav-link.active .nav-icon i {
    color: #181C32; }
.nav.nav-pills.nav-light-dark .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.nav.nav-pills.nav-light-dark .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-dark .nav-link.active:after {
    color: #181C32; }

.nav.nav-pills.nav-group.nav-dark .nav-link {
    color: #181C32;
    background-color: #D1D3E0;
    border: 0; }
.nav.nav-pills.nav-group.nav-dark .nav-link .nav-text {
    color: #181C32; }
.nav.nav-pills.nav-group.nav-dark .nav-link .nav-icon i {
    color: #181C32; }
.nav.nav-pills.nav-group.nav-dark .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #181C32; }
.nav.nav-pills.nav-group.nav-dark .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-dark .nav-link:after {
    color: #181C32; }

.nav.nav-pills.nav-group.nav-dark .show > .nav-link,
.nav.nav-pills.nav-group.nav-dark .nav-link:hover,
.nav.nav-pills.nav-group.nav-dark .nav-link.active {
    color: white;
    background-color: #181C32; }
.nav.nav-pills.nav-group.nav-dark .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-dark .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-dark .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-dark .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-dark .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-dark .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-dark .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-dark .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-dark .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-dark .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-dark .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-dark .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-dark .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-dark .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-dark .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-dark .nav-link:hover {
    background-color: #131628; }

.nav.nav-pills.nav-white .show > .nav-link,
.nav.nav-pills.nav-white .nav-link:hover:not(.active) {
    color: white;
    background-color: transparent; }
.nav.nav-pills.nav-white .show > .nav-link .nav-text,
.nav.nav-pills.nav-white .nav-link:hover:not(.active) .nav-text {
    color: white; }
.nav.nav-pills.nav-white .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-white .nav-link:hover:not(.active) .nav-icon i {
    color: white; }
.nav.nav-pills.nav-white .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-white .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-white .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-white .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-white .show > .nav-link:after,
.nav.nav-pills.nav-white .nav-link:hover:not(.active):after {
    color: white; }

.nav.nav-pills.nav-white .nav-link.active {
    color: #3F4254;
    background-color: white; }
.nav.nav-pills.nav-white .nav-link.active .nav-text {
    color: #3F4254; }
.nav.nav-pills.nav-white .nav-link.active .nav-icon i {
    color: #3F4254; }
.nav.nav-pills.nav-white .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254 !important; }
.nav.nav-pills.nav-white .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-white .nav-link.active:after {
    color: #3F4254; }

.nav.nav-pills.nav-light-white .show > .nav-link,
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active) {
    color: white;
    background-color: transparent; }
.nav.nav-pills.nav-light-white .show > .nav-link .nav-text,
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active) .nav-text {
    color: white; }
.nav.nav-pills.nav-light-white .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active) .nav-icon i {
    color: white; }
.nav.nav-pills.nav-light-white .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-light-white .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-white .show > .nav-link:after,
.nav.nav-pills.nav-light-white .nav-link:hover:not(.active):after {
    color: white; }

.nav.nav-pills.nav-light-white .nav-link.active {
    color: white;
    background-color: rgba(255, 255, 255, 0.1); }
.nav.nav-pills.nav-light-white .nav-link.active .nav-text {
    color: white; }
.nav.nav-pills.nav-light-white .nav-link.active .nav-icon i {
    color: white; }
.nav.nav-pills.nav-light-white .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-light-white .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-light-white .nav-link.active:after {
    color: white; }

.nav.nav-pills.nav-group.nav-white .nav-link {
    color: white;
    background-color: white;
    border: 0; }
.nav.nav-pills.nav-group.nav-white .nav-link .nav-text {
    color: white; }
.nav.nav-pills.nav-group.nav-white .nav-link .nav-icon i {
    color: white; }
.nav.nav-pills.nav-group.nav-white .nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: white; }
.nav.nav-pills.nav-group.nav-white .nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-white .nav-link:after {
    color: white; }

.nav.nav-pills.nav-group.nav-white .show > .nav-link,
.nav.nav-pills.nav-group.nav-white .nav-link:hover,
.nav.nav-pills.nav-group.nav-white .nav-link.active {
    color: #3F4254;
    background-color: white; }
.nav.nav-pills.nav-group.nav-white .show > .nav-link .nav-text,
.nav.nav-pills.nav-group.nav-white .nav-link:hover .nav-text,
.nav.nav-pills.nav-group.nav-white .nav-link.active .nav-text {
    color: #3F4254; }
.nav.nav-pills.nav-group.nav-white .show > .nav-link .nav-icon i,
.nav.nav-pills.nav-group.nav-white .nav-link:hover .nav-icon i,
.nav.nav-pills.nav-group.nav-white .nav-link.active .nav-icon i {
    color: #3F4254; }
.nav.nav-pills.nav-group.nav-white .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-white .nav-link:hover .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-group.nav-white .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3F4254; }
.nav.nav-pills.nav-group.nav-white .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-white .nav-link:hover .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-group.nav-white .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.nav.nav-pills.nav-group.nav-white .show > .nav-link:after,
.nav.nav-pills.nav-group.nav-white .nav-link:hover:after,
.nav.nav-pills.nav-group.nav-white .nav-link.active:after {
    color: #3F4254; }

.nav.nav-pills.nav-group.nav-white .nav-link:hover {
    background-color: var(--elem-bg); }
